import classNames from "classnames";
import "./index.scss";
import { formatDateFrom } from "../../../utils";

const AttentionBlock = ({ date }) => {
  const comparisonClass = classNames({
    "comparison-block comparison-block_attention": true,
  });

  if (!date) return null;

  return (
    <article className={comparisonClass}>
      <div className="comparison-block__content">
        <div className="comparison-block__box">
          <div className="comparison-block__title">Успейте купить ЦФА!</div>

          <span className="comparison-block__text">
            ЦФА можно купить до{" "}
            <span className="comparison-block__highlighted">
              {formatDateFrom(date, true)}
            </span>
          </span>
          <div>
            <span className="comparison-block__text">
              Но они могут закончиться раньше
            </span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AttentionBlock;
