import { useQuery } from "react-query";
import SectionTitle from "../../ui/SectionTitle/SectionTitle";
import StatusTable from "../../ui/Table/StatusTable";

import "./index.scss";
import { getBuildingHistory } from "../../../api";
import { Loader } from "../../ui/Loader/Loader";

const JkStatus = ({ buildingId }) => {
  const { data: buildingHistoryData, isLoading } = useQuery({
    queryKey: ["history", buildingId],
    queryFn: () => getBuildingHistory(buildingId),
    enabled: !!buildingId,
  });

  // if (buildingId) return null;

  if (isLoading) return <Loader isBlue isLarge />;
  if (!buildingHistoryData || buildingHistoryData.length === 0) return null;

  return (
    <section className="jk-status">
      <SectionTitle>Статусы ЖК</SectionTitle>
      <StatusTable data={buildingHistoryData} />
      {/* <ShowmoreBtn /> */}
    </section>
  );
};

export default JkStatus;
