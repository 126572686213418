import Input from "../../ui/Input/Input";
import Select from "../../ui/Select/Select";
import { Controller, useWatch } from "react-hook-form";
import Dropzone from "../../ui/Dropzone/Dropzone";
import React, { useEffect } from "react";
import FlatGallery from "../../../admin/components/Flat/FlatGallery";
import Checkbox from "../../ui/Checkbox/Checkbox";
/** @type {{label : string, value : import("../../../api").FlatTokenizationStatus}[]} */
export const tokenizationOptions = [
  { label: "Не токенизирована", value: "NOT_TOKENIZED" },
  { label: "Ожидает токенизации", value: "WAIT_TOKENIZATION" },
  { label: "Идет токенизация", value: "TOKENIZATION" },
  { label: "Токенизирована", value: "TOKENIZED" },
];

/** @type {{label : string, value : import("../../../api").FlatBookingStatus}[]}*/
export const bookingOptions = [
  { label: "Доступна для бронирования", value: "NOT_BOOKED" },
  { label: "Забронирована", value: "BOOKED" },
  { label: "Продана", value: "RELEASED" },
  { label: "Удалена из пула", value: "REMOVED_FROM_POOL" },
];

/**
 * @typedef {{
 *   blockNumber : string | undefined,
 *   entranceNumber : string | undefined,
 *   floor : string | undefined,
 *   number : string | undefined,
 *   footage : string | undefined,
 *   roomsCount : string | undefined,
 *   tokenizationStatus : import("../../../api").FlatTokenizationStatus | undefined,
 *   bookingStatus : import("../../../api").FlatBookingStatus | undefined,
 * }} DefaultFlatFormValues
 */

/** Component is exracted for ease of reusability in other forms
 * FlatDescription component contains a number of input fields
 * @param {{
 *  control : import("react-hook-form").Control<import("react-hook-form").FieldValues, any>
 *  defaultValue : DefaultFlatFormValues | undefined
 *  disabled : boolean | undefined
 * }}
 * @returns {import("react").ReactNode}
 */

export function FlatDescription({
  control,
  defaultValue,
  disabled,
  planImg,
  onButtonClickDeleteImage,
  setValue,
}) {
  const { withoutBookingStatus } = useWatch(control);

  useEffect(() => {
    if (!withoutBookingStatus) {
      setValue("bookingStatus", bookingOptions[0].value);
    }
  }, [withoutBookingStatus, setValue]); // refactoring?

  return (
    <>
      <div className="form__row">
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="№ корпуса"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="blockNumber"
            defaultValue={defaultValue?.blockNumber}
            type="number"
          />
        </div>
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="№ подъезда"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="entranceNumber"
            defaultValue={defaultValue?.entranceNumber}
            type="number"
          />
        </div>
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="Этаж"
            addClass="form__input"
            size="lg"
            labelSize="small"
            name="floor"
            defaultValue={defaultValue?.floor}
            type="number"
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="№ квартиры"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="number"
            defaultValue={defaultValue?.number}
            type="number"
          />
        </div>
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="Площадь м²"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="footage"
            defaultValue={defaultValue?.footage}
            type="number"
            step={0.01}
          />
        </div>
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="Количество комнат"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="roomsCount"
            defaultValue={defaultValue?.roomsCount}
            type="number"
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <Controller
            name="tokenizationStatus"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <Select
                {...field}
                disabled={disabled}
                options={tokenizationOptions}
                withLabel="Статус токенизации"
                whiteType
                onChangeValue={onChange}
                defaultOption={
                  tokenizationOptions.find(
                    (opt) => opt.value === defaultValue?.tokenizationStatus
                  ) ?? tokenizationOptions[0]
                }
              />
            )}
          />
        </div>
      </div>
      {!withoutBookingStatus && (
        <div className="form__row">
          <div className="form__item">
            <Controller
              name="bookingStatus"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Select
                  {...field}
                  disabled={disabled}
                  options={bookingOptions}
                  whiteType
                  withLabel="Статус бронирования"
                  onChangeValue={onChange}
                  defaultOption={
                    bookingOptions.find(
                      (opt) => opt.value === defaultValue?.bookingStatus
                    ) ?? bookingOptions[0]
                  }
                />
              )}
            />
          </div>
        </div>
      )}

      <div className="form__row">
        <div className="form__item">
          <Controller
            name="withoutBookingStatus"
            control={control}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Checkbox
                checkboxValue={value}
                name="withoutBookingStatus"
                disabled={disabled}
                withLabel="Убрать статус бронирования"
              />
            )}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="Внешняя ссылка на квартиру"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="externalLink"
            defaultValue={defaultValue?.externalLink}
            type="text"
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          {planImg && planImg.length > 0 && (
            <FlatGallery
              planImg={planImg}
              disabled={disabled}
              onButtonClickDeleteImage={onButtonClickDeleteImage}
            />
          )}
          <Dropzone
            accept={{
              "image/*": [".jpeg", ".jpg", ".png"],
            }}
            name="photos"
            disabled={disabled}
            label="Загрузите план квартиры"
          />
          {/* <Controller
            name="photos"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <UploadField
                {...field}
                setValue={onChange}
                withLabel="Загрузите план квартиры"
                preview={photo}
                handleSetPreview={setPhoto}
                fullPreview
              />
            )}
          />
           */}
        </div>
      </div>
    </>
  );
}
