import NotificationCard from "../../../ui/NotificationCard/NotificationCard";
import { Loader } from "../../../ui/Loader/Loader";
import SettingsButtons from "../../../ui/NotificationCard/SettingsButtons";
import {useEffect, useRef, useState} from "react";
import useNotifications from "../../../../hooks/notifications/useNotifications";
import {getNewNotificationsFetchInterval} from "../../../../utils";
import classNames from "classnames";

const DropdownMenuNotifications = ({ isOpened, notificationsRef, handleMouseLeaveCloseNotifications, countNewNotifications, setCountNewNotifications }) => {

    const userMenuNotificationClass = classNames({
        "user-menu__notification_items": true,
        "user-menu__notification_items--open": isOpened,

    });
    const arrayNewNotificationsRef = useRef([]);
    const [paramsRequest, setParamsRequest] = useState({
        page: 0,
        size: 10,
        objectType: false
    });
    const { data: notifications, isLoading: isLoading } = useNotifications(paramsRequest, true);
    const totalElementsNotifications = notifications?.totalElements;

    useEffect(() => {
        let isMounted = true;
        if (notifications && notifications?.content?.length > 0) {
            isMounted && getNewNotificationsFetchInterval(notifications, arrayNewNotificationsRef, 'id', countNewNotifications, setCountNewNotifications);
        }

        return () => {
            isMounted = false;
        }
    }, [notifications])

    const onClickGetAllNotifications = () => {
        setParamsRequest({...paramsRequest, size: totalElementsNotifications})
    }

    return (
        <div className={userMenuNotificationClass}
             ref={notificationsRef}
             onMouseLeave={handleMouseLeaveCloseNotifications}
             onClick={(e) => e.stopPropagation()}
        >
            {isLoading ? (
                <Loader isBlue isLarge/>
            ) : (
                <>
                    {notifications && notifications?.content?.length > 0 ? (
                        notifications.content.map((content) => {
                            return (
                                <NotificationCard
                                    key={content.id}
                                    isMiniCard={true}
                                    {...content}
                                />
                            )
                        })
                    ) : (
                        <p style={{textAlign: "center", width: '426px'}}>Уведомления не найдены!</p>
                    )}
                </>
            )}
            {notifications
                && totalElementsNotifications > 10
                && totalElementsNotifications !== paramsRequest.size &&
                <SettingsButtons
                    // nameBtnOne='Настройки'
                    nameBtnTwo='Показать все'
                    onClickBtnTwo={onClickGetAllNotifications}
                    sizeBtn="s"
                    style={{marginTop: '0'}}
                />}
        </div>
    )
}
export default DropdownMenuNotifications;
