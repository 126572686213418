import Popup from "../Popup/Popup";
import classNames from "classnames";
import "./index.scss";

const PercentOfIncomeBlock = ({ percent }) => {
  const comparisonClass = classNames({
    "comparison-block comparison-block_income": true,
  });

  if (!percent) return null;

  return (
    <article className={comparisonClass}>
      <div className="comparison-block__content">
        <div className="comparison-block__box">
          <div className="comparison-block__title">
            Доходность ЦФА{" "}
            <Popup>
              Доходность в действующем выпуске ЦФА на дату начала сбора заявок
            </Popup>
          </div>

          <span className="comparison-block__text">
            <span className="comparison-block__highlighted">{percent}%</span>{" "}
            годовых
          </span>
        </div>
      </div>
    </article>
  );
};

export default PercentOfIncomeBlock;
