import { useQuery } from "react-query";
import heartFilledBlueIcon from "../../../assets/img/icons/heart-filled-blue.svg";
import { getBuildingById } from "../../../api";
import ProgressLabel from "../Label/ProgressLabel";

const LivingComplexesCard = ({ statusReadinessJk }) => {
  const { data } = useQuery("flat", async () =>
    getBuildingById(statusReadinessJk.objectId)
  );

  const templatesDesc = {
    readinessBuilding: "начал своё строительтво.",
  };

  const setDescriptionArray = statusReadinessJk?.description?.split(",");
  const getPercentBuilding = setDescriptionArray
    ? `${parseInt(setDescriptionArray[2].replace(/\D/g, " "))}%`
    : "Уточняется";

  return (
    <>
      <p className="notification-card__desc">
        <img
          src={heartFilledBlueIcon}
          alt="иконка"
          style={{ marginRight: "6px" }}
        />
        ЖК &#171;{data?.name}&#187; {templatesDesc["readinessBuilding"]}{" "}
        Готовность {getPercentBuilding}
      </p>
      <div className="notification-jk-card">
        <div className="notification-jk-card__header">
          <img
            className="notification-jk-card__header_img"
            src={data?.imagesLinks[0]}
            alt="ЖК"
          />
        </div>
        <div className="notification-jk-card__body">
          {data?.percentBuilding && (
            <ProgressLabel status={"TOKENIZATION"}>
              Построено {getPercentBuilding}
            </ProgressLabel>
          )}
          <div className="notification-jk-card-info">
            <div className="notification-jk-card-info__col">
              <span className="notification-jk-card-info__title">
                {data?.name}
              </span>
              <span className="notification-jk-card-info__value">
                {data?.developer?.name}
              </span>
            </div>
            <div>
              <span className="notification-jk-card-info__title">
                {data?.city}
              </span>
              <span className="notification-jk-card-info__value">
                {data?.district}
              </span>
            </div>
            <div>
              <span className="notification-jk-card-info__title">
                100 ЦФА = 185 000
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LivingComplexesCard;
