import { Link } from "react-router-dom";
import "./index.scss";

const Logo = () => {
  return (
    <Link to="/">
      <div className="logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1084"
          height="346"
          viewBox="0 0 1084 346"
          fill="none"
        >
          <g clip-path="url(#clip0_2198_68)">
            <path
              d="M0 187.556L1.17026e-05 69.2339H32.9116V160.683H69.3686V69.2339H102.28V187.556H0ZM87.9341 206.962V187.556L69.3686 160.683H118.231V206.962H87.9341Z"
              fill="currentColor"
            />
            <path
              d="M135.777 187.515V69.1615H168.491V138.411L217.268 69.1615H248.661V187.515H215.749V117.996L167.141 187.515H135.777Z"
              fill="currentColor"
            />
            <path
              d="M339.009 180.086C323.257 180.086 309.923 178.062 299.009 174.013C288.207 169.963 279.994 164.058 274.368 156.297C268.854 148.422 266.098 138.917 266.098 127.781C266.098 116.533 268.854 107.028 274.368 99.267C279.994 91.3931 288.264 85.4314 299.178 81.382C310.092 77.3327 323.426 75.3079 339.178 75.3079C339.403 75.3079 340.135 75.3079 341.372 75.3079C342.723 75.3079 344.073 75.3079 345.423 75.3079C346.773 75.3079 347.505 75.3079 347.617 75.3079C363.257 75.3079 376.366 77.3889 386.942 81.5507C397.631 85.6002 405.733 91.5618 411.246 99.4356C416.76 107.197 419.516 116.645 419.516 127.781C419.516 138.805 416.816 148.254 411.415 156.127C406.127 163.889 398.25 169.851 387.786 174.013C377.322 178.062 364.439 180.086 349.136 180.086C348.911 180.086 348.011 180.086 346.436 180.086C344.86 180.086 343.341 180.086 341.879 180.086C340.416 180.086 339.459 180.086 339.009 180.086ZM342.047 154.441C342.385 154.441 342.891 154.441 343.566 154.441C344.354 154.441 344.917 154.441 345.254 154.441C354.931 154.441 362.863 153.484 369.052 151.572C375.24 149.659 379.797 146.736 382.723 142.798C385.648 138.861 387.111 133.8 387.111 127.613C387.111 121.314 385.592 116.252 382.554 112.427C379.516 108.603 374.959 105.791 368.883 103.992C362.807 102.08 355.043 101.123 345.592 101.123C345.254 101.123 344.692 101.123 343.904 101.123C343.229 101.123 342.61 101.123 342.047 101.123C331.921 101.123 323.651 102.022 317.237 103.823C310.824 105.623 306.098 108.49 303.06 112.427C300.022 116.252 298.503 121.37 298.503 127.781C298.503 134.081 300.134 139.199 303.397 143.135C306.773 147.073 311.668 149.941 318.081 151.741C324.607 153.54 332.596 154.441 342.047 154.441ZM327.533 192.539V61.2593H358.081V192.539H327.533Z"
              fill="currentColor"
            />
            <path
              d="M436.874 187.515V69.1615H490.883C501.685 69.1615 510.967 70.8086 518.731 74.2956C526.607 77.7825 532.683 82.8443 536.959 89.4809C541.235 96.0049 543.373 103.766 543.373 112.765C543.373 121.651 541.235 129.356 536.959 135.88C532.683 142.404 526.607 147.467 518.731 151.066C510.967 154.553 501.685 156.297 490.883 156.297H455.44L470.292 141.786V187.515H436.874ZM470.292 145.329L455.44 129.975H488.858C495.834 129.975 501.01 128.456 504.385 125.419C507.873 122.382 509.617 118.164 509.617 112.765C509.617 107.253 507.873 102.979 504.385 99.9419C501.01 96.9048 495.834 95.3863 488.858 95.3863H455.44L470.292 80.0322V145.329Z"
              fill="currentColor"
            />
            <path
              d="M618.22 189.535C608.769 189.535 600.049 188.017 592.06 184.98C584.071 181.942 577.095 177.668 571.131 172.157C565.28 166.532 560.723 160.008 557.46 152.584C554.197 145.161 552.566 137.005 552.566 128.119C552.566 119.233 554.197 111.078 557.46 103.654C560.723 96.2299 565.28 89.7621 571.131 84.2503C577.095 78.6262 584.071 74.2956 592.06 71.2585C600.049 68.2215 608.769 66.7029 618.22 66.7029C627.784 66.7029 636.505 68.2215 644.381 71.2585C652.37 74.2956 659.289 78.6262 665.14 84.2503C670.991 89.7621 675.548 96.2299 678.812 103.654C682.187 111.078 683.875 119.233 683.875 128.119C683.875 137.005 682.187 145.217 678.812 152.753C675.548 160.177 670.991 166.644 665.14 172.157C659.289 177.668 652.37 181.942 644.381 184.98C636.505 188.017 627.784 189.535 618.22 189.535ZM618.22 161.864C622.721 161.864 626.884 161.077 630.71 159.502C634.648 157.928 638.024 155.678 640.837 152.753C643.762 149.716 646.012 146.116 647.588 141.954C649.275 137.792 650.119 133.18 650.119 128.119C650.119 122.945 649.275 118.333 647.588 114.283C646.012 110.121 643.762 106.578 640.837 103.654C638.024 100.617 634.648 98.3108 630.71 96.7361C626.884 95.1613 622.721 94.3739 618.22 94.3739C613.72 94.3739 609.5 95.1613 605.562 96.7361C601.736 98.3108 598.361 100.617 595.435 103.654C592.622 106.578 590.372 110.121 588.684 114.283C587.109 118.333 586.321 122.945 586.321 128.119C586.321 133.18 587.109 137.792 588.684 141.954C590.372 146.116 592.622 149.716 595.435 152.753C598.361 155.678 601.736 157.928 605.562 159.502C609.5 161.077 613.72 161.864 618.22 161.864Z"
              fill="currentColor"
            />
            <path
              d="M701.743 187.515V69.5027H759.127C773.305 69.5027 784.275 72.1059 792.039 77.505C799.915 82.7917 803.853 90.3282 803.853 100.114C803.853 109.675 800.196 117.212 792.883 122.723C785.569 128.123 775.78 130.822 763.515 130.822L766.891 122.893C780.168 122.893 790.52 125.536 797.946 130.822C805.485 135.997 809.254 143.646 809.254 153.769C809.254 164.118 805.203 172.329 797.102 178.403C789.113 184.477 777.524 187.515 762.334 187.515H701.743ZM734.654 163.555H760.984C765.822 163.555 769.423 162.543 771.785 160.519C774.261 158.381 775.499 155.288 775.499 151.238C775.499 147.189 774.261 144.208 771.785 142.296C769.423 140.271 765.822 139.258 760.984 139.258H734.654V163.555ZM734.654 116.649H755.92C760.646 116.649 764.19 115.693 766.553 113.781C768.916 111.757 770.098 108.832 770.098 105.007C770.098 101.183 768.916 98.3145 766.553 96.4024C764.19 94.3776 760.646 93.3653 755.92 93.3653H734.654V116.649Z"
              fill="currentColor"
            />
            <path
              d="M885.396 189.535C875.945 189.535 867.225 188.017 859.236 184.98C851.247 181.942 844.271 177.668 838.308 172.157C832.457 166.532 827.9 160.008 824.637 152.584C821.374 145.161 819.742 137.005 819.742 128.119C819.742 119.233 821.374 111.078 824.637 103.654C827.9 96.2299 832.457 89.7621 838.308 84.2503C844.271 78.6262 851.247 74.2956 859.236 71.2585C867.225 68.2215 875.945 66.7029 885.396 66.7029C894.961 66.7029 903.681 68.2215 911.557 71.2585C919.546 74.2956 926.466 78.6262 932.317 84.2503C938.168 89.7621 942.725 96.2299 945.988 103.654C949.363 111.078 951.051 119.233 951.051 128.119C951.051 137.005 949.363 145.217 945.988 152.753C942.725 160.177 938.168 166.644 932.317 172.157C926.466 177.668 919.546 181.942 911.557 184.98C903.681 188.017 894.961 189.535 885.396 189.535ZM885.396 161.864C889.897 161.864 894.06 161.077 897.886 159.502C901.824 157.928 905.2 155.678 908.013 152.753C910.938 149.716 913.189 146.116 914.764 141.954C916.452 137.792 917.295 133.18 917.295 128.119C917.295 122.945 916.452 118.333 914.764 114.283C913.189 110.121 910.938 106.578 908.013 103.654C905.2 100.617 901.824 98.3108 897.886 96.7361C894.06 95.1613 889.897 94.3739 885.396 94.3739C880.896 94.3739 876.676 95.1613 872.738 96.7361C868.913 98.3108 865.537 100.617 862.611 103.654C859.798 106.578 857.548 110.121 855.86 114.283C854.285 118.333 853.498 122.945 853.498 128.119C853.498 133.18 854.285 137.792 855.86 141.954C857.548 146.116 859.798 149.716 862.611 152.753C865.537 155.678 868.913 157.928 872.738 159.502C876.676 161.077 880.896 161.864 885.396 161.864Z"
              fill="currentColor"
            />
            <path
              d="M966 187.53L966.008 69.2036L1002 69.1826V138.399L1052 69.1826H1084V187.509H1051.09V118.008L1000.48 187.509L966 187.53Z"
              fill="currentColor"
            />
            <path
              d="M982.883 31.0225H1052V50.1305H1001.94L982.883 31.0225Z"
              fill="currentColor"
            />
            <path
              d="M1040.78 0H1013.82L982.883 31.0225L996.359 44.5349L1040.71 0.0646451C1040.74 0.0431972 1040.76 0.0214977 1040.78 0Z"
              fill="currentColor"
            />
            <path
              d="M276.82 346L243.33 289.39L270.36 273.41L314.73 346H276.82ZM201 346V227H234.49V346H201ZM224.8 300.44V273.24H267.98V300.44H224.8ZM273.59 290.07L242.48 286.67L279.03 227H314.39L273.59 290.07Z"
              fill="currentColor"
            />
            <path
              d="M325.528 346V227H383.328C397.608 227 408.658 229.72 416.478 235.16C424.411 240.487 428.378 248.08 428.378 257.94C428.378 267.573 424.694 275.167 417.328 280.72C409.961 286.16 400.101 288.88 387.748 288.88L391.148 280.89C404.521 280.89 414.948 283.553 422.428 288.88C430.021 294.093 433.818 301.8 433.818 312C433.818 322.427 429.738 330.7 421.578 336.82C413.531 342.94 401.858 346 386.558 346H325.528ZM358.678 321.86H385.198C390.071 321.86 393.698 320.84 396.078 318.8C398.571 316.647 399.818 313.53 399.818 309.45C399.818 305.37 398.571 302.367 396.078 300.44C393.698 298.4 390.071 297.38 385.198 297.38H358.678V321.86ZM358.678 274.6H380.098C384.858 274.6 388.428 273.637 390.808 271.71C393.188 269.67 394.378 266.723 394.378 262.87C394.378 259.017 393.188 256.127 390.808 254.2C388.428 252.16 384.858 251.14 380.098 251.14H358.678V274.6Z"
              fill="currentColor"
            />
            <path
              d="M440.132 346L492.662 227H525.812L578.512 346H543.492L502.352 243.49H515.612L474.472 346H440.132ZM468.862 322.88L477.532 298.06H535.672L544.342 322.88H468.862Z"
              fill="currentColor"
            />
            <path
              d="M737.246 346V227H791.646C802.526 227 811.876 228.757 819.696 232.27C827.63 235.783 833.75 240.883 838.056 247.57C842.363 254.143 844.516 261.963 844.516 271.03C844.516 279.983 842.363 287.747 838.056 294.32C833.75 300.893 827.63 305.993 819.696 309.62C811.876 313.133 802.526 314.89 791.646 314.89H755.946L770.906 300.27V346H737.246ZM770.906 303.84L755.946 288.37H789.606C796.633 288.37 801.846 286.84 805.246 283.78C808.76 280.72 810.516 276.47 810.516 271.03C810.516 265.477 808.76 261.17 805.246 258.11C801.846 255.05 796.633 253.52 789.606 253.52H755.946L770.906 238.05V303.84Z"
              fill="currentColor"
            />
            <path
              d="M841.889 346L894.419 227H927.569L980.269 346H945.249L904.109 243.49H917.369L876.229 346H841.889ZM870.619 322.88L879.289 298.06H937.429L946.099 322.88H870.619Z"
              fill="currentColor"
            />
            <path
              d="M1014.28 346V246.21L1021.76 253.69H977.73V227H1083.81V253.69H1039.95L1047.43 246.21V346H1014.28Z"
              fill="currentColor"
            />
            <path d="M608 226H705V253H635L608 226Z" fill="currentColor" />
            <path d="M705 253L705 319H672V253L705 253Z" fill="currentColor" />
            <path d="M721 346H595L583 319H721V346Z" fill="currentColor" />
            <path
              d="M635 253L617.002 319.001L583 319L608 226L635 253Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_2198_68">
              <rect width="1084" height="346" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </Link>
  );
};

export default Logo;
