import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import {
  getActiveOrdersByPoolId,
  getOrderById,
  getPaymentDocumentByOrderId,
  getOrdersForIssuer,
  getOrdersPoolView,
  getProspectusById,
  getQrCodeOrder,
  getOrdersForInvestor,
  getOrdersPage,
} from "../../api";

import { downloadFile } from "../../utils";
import useIsLoggedIn from "../useIsLoggedIn";

export const useActiveOrdersForUser = (params, onSuccess = () => {}) => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery({
    queryKey: ["orders"],
    queryFn: () => getActiveOrdersByPoolId(params),
    enabled: isLoggedIn,
    // refetchOnMount: true,
    // refetchInterval: 10000,
    onSuccess: (data) => onSuccess(data),
  });
};

export const useOrderById = () => {
  const { id } = useParams();

  const user = useQuery({
    queryKey: ["orders", id],
    queryFn: () => getOrderById(id),
    enabled: !!id,
    refetchInterval: 5000,
  });
  return user;
};

export const useOrderPaymentDocument = (enabled = false) => {
  const { id } = useParams();

  const user = useQuery(
    {
      queryKey: ["payment"],
      queryFn: () => getPaymentDocumentByOrderId(id),
      retry: false,
      enabled: !!id && enabled,
    },
    {
      onSuccess: (file) => {
        downloadFile(file, "payment.pdf");
      },
    }
  );
  return user;
};

export const useOrdersForIssuer = () => {
  return useQuery({
    queryKey: ["ordersIssuer"],
    queryFn: getOrdersForIssuer,
  });
};

export const useOrdersForInvestor = () => {
  return useQuery({
    queryKey: ["ordersInvestor"],
    queryFn: getOrdersForInvestor,
  });
};

export const useOrdersPoolView = (params) => {
  return useQuery({
    enabled: !!params.filter_type,
    queryKey: ["orders", params.filter_type],
    refetchOnMount: true,

    queryFn: () => getOrdersPoolView(params),
  });
};

export const useProspectusById = (prospectusId, onError = () => {}) => {
  return useQuery({
    queryKey: ["fileFormular", prospectusId],
    queryFn: () => getProspectusById(prospectusId),
    enabled: !!prospectusId,
    onError: () => onError(),
  });
};

export const useQrCodeOrder = (orderId) => {
  return useQuery({
    queryKey: ["qrcodeOrder", orderId],
    queryFn: () => getQrCodeOrder(orderId),
    enabled: !!orderId,
  });
};

export const useOrdersPage = () => {
  return useQuery({
    queryKey: ["orders"],
    queryFn: () => getOrdersPage(),
  });
};
