import { useMemo } from "react";
import SearchInput from "../SearchInput/SearchInput";

import useReleases from "../../../hooks/fetchers/releases/useReleases";
import { Loader } from "../../../components/ui/Loader/Loader";
import AddReleaseModal from "../AddModal/AddReleaseModal";
import { formatReleasesInfo } from "../AdminTable/AdminTableFormaters";
import SortByBuildingSelect from "../AdminTable/SortByBuildingSelect";
import AdminTableWithSort from "../AdminTable/AdminTableWithSort";
const Releases = () => {
  const { data, isLoading } = useReleases();

  const releases = useMemo(() => {
    if (data) return data.content;
  }, [data]);

  if (isLoading) {
    return <Loader isLarge isBlue />;
  }

  return (
    <>
      {releases && releases.length > 0 && <SearchInput />}

      <AdminTableWithSort
        data={releases}
        formatInfo={formatReleasesInfo}
        noDataLabel={"Нет релизов"}
        renderModal={(closeModal) => (
          <AddReleaseModal closeModal={closeModal} />
        )}
        addLabel={"Добавить релиз"}
      />
    </>
  );
};

export default Releases;
