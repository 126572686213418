import { getUser, refreshTokens } from "../api";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import useIsLoggedIn from "./useIsLoggedIn";
import { useUserStore } from "../store";
import useLogout from "./useLogout";
import { setHeaderToken } from "../api";
const useUser = () => {
  let [searchParams] = useSearchParams();
  const logout = useLogout();
  const setUser = useUserStore((state) => state.setUser);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setHeaderToken(localStorage.getItem("accessToken"));
    }
  }, []);

  const isLoggedIn = useIsLoggedIn();

  const { data: user, isLoading } = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
    enabled: isLoggedIn,
    retry: false,
    // refetchOnWindowFocus: true,
    // refetchInterval: 10000,
    onError: logout,
    onSuccess: (data) => {
      setUser(data);
    },
  });

  const getNewTokens = useMutation({
    mutationFn: (oldRefreshToken) => {
      return refreshTokens(oldRefreshToken);
    },
    enabled: !!user,
    onSuccess: (newTokens) => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
      localStorage.setItem("accessToken", newTokens.accessToken);
      localStorage.setItem("refreshToken", newTokens.refreshToken);
    },
  });

  useEffect(() => {
    if (searchParams.get("refresh") && isLoggedIn) {
      const refreshToken = localStorage.getItem("refreshToken");

      getNewTokens.mutate(refreshToken);
    }
  }, [isLoggedIn, searchParams]);

  return { user, isLoading };
};

export default useUser;
