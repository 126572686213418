import Container from "../components/layout/Container/Container";
import { config } from "../config";

const ForDevelopersPage = () => {
  return (
    <section className="home">
      <h1 className="title">­Для застройщиков</h1>
      <div className="text-block">
        <p>Приглашаем к сотрудничеству застройщиков.</p>
        <p>
          Наша цель – дать рынку новый канал привлечения заемных средств.
          <br />У нас проработанные схемы выпусков ЦФА для застройщиков и мы
          сами занимаемся привлечением на платформу инвесторов.
        </p>
        <p>
          Для обсуждения сотрудничества напишите нам на почту{" "}
          <a
            className="link link_blue"
            href={`mailto:${config.contacts.forDevelopersEmail}`}
          >
            {config.contacts.forDevelopersEmail}
          </a>
        </p>
      </div>
    </section>
  );
};

export default ForDevelopersPage;
