import classNames from "classnames";
import BottomBar from "./BottomBar/BottomBar";
import "./index.scss";
import TopBar from "./TopBar/TopBar";
import { config } from "../../../config";
import useMobile from "../../../hooks/useMobile";

import MobileMenu from "./MobileMenu/MobileMenu";
import useClickOutside from "../../../hooks/useClickOutside";
const Header = () => {
  const isMobile = useMobile();
  const {
    ref: menuRef,
    isOpened: mobileMenuShown,
    setIsOpened: setMobileMenuShown,
  } = useClickOutside();

  const headerClass = classNames({
    header: true,
  });

  return (
    <header className={headerClass}>
      {isMobile && (
        <MobileMenu
          menuRef={menuRef}
          mobileMenuShown={mobileMenuShown}
          setMobileMenuShown={setMobileMenuShown}
        />
      )}
      {config.topBarEnabled && <TopBar />}

      <BottomBar setMobileMenuShown={setMobileMenuShown} />
    </header>
  );
};

export default Header;
