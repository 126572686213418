import classNames from "classnames";
import Label from "../../../ui/Label/Label";

const TableOperations = ({ withLabels = true, noGap = true }) => {
  const priceClass = classNames({
    "table table_cols-3 table_status": true,
    "table_no-gap": noGap,
  });

  return (
    <article className={priceClass}>
      {withLabels && (
        <div className="table__col">
          <span className="table__key table__key_hidden">Статус</span>
          <span className="table__value">
            <Label size={"xs"}>Куплено</Label>
          </span>
          <span className="table__value">
            <Label size={"xs"}>Куплено</Label>
          </span>
          <span className="table__value">
            <Label size={"xs"} labelType="secondary-gray">
              Продано
            </Label>
          </span>
        </div>
      )}

      <div className="table__col table__col_no-wrap">
        <span className="table__key">Дата</span>
        <span className="table__value">24 июня 2023 года</span>
        <span className="table__value text-gray">12 мая 2023 года</span>
        <span className="table__value text-gray">24 марта 2023 года</span>
      </div>

      <div className="table__col">
        <span className="table__key">Количество ЦФА</span>
        <span className="table__value">100 ЦФА ЖК «Аист»</span>
        <span className="table__value">100 ЦФА ЖК «Аист»</span>
        <span className="table__value">100 ЦФА ЖК «Аист»</span>
      </div>

      <div className="table__col">
        <span className="table__key">За 100 ЦФА</span>
        <span className="table__value">185 273 ₽</span>
        <span className="table__value">182 223 ₽</span>
        <span className="table__value">178 234 ₽</span>
      </div>

      <div className="table__col">
        <span className="table__key">Сумма</span>
        <span className="table__value">185 273 ₽</span>
        <span className="table__value">182 223 ₽</span>
        <span className="table__value">178 234 ₽</span>
      </div>
    </article>
  );
};

export default TableOperations;
