import AdminTableWithSort from "../AdminTable/AdminTableWithSort";
import { formatOrdersInfo } from "../AdminTable/AdminTableFormaters";

import { useOrdersPage } from "../../../hooks/fetchers/orders";
import { Loader } from "../../../components/ui/Loader/Loader";

const Orders = () => {
  // const { data: ordersInvestor, isLoading: isOrdersForInvestorLoading } =
  //   useOrdersForInvestor();

  // const { data: ordersIssuer, isLoading: isOrdersForIssuerLoading } =
  //   useOrdersForIssuer();

  const { data: orders, isLoading } = useOrdersPage();

  if (isLoading) return <Loader isBlue isLarge />;
  return (
    <AdminTableWithSort
      data={orders}
      formatInfo={formatOrdersInfo}
      noDataLabel={"Нет сделок"}
    />

    // <Tabs underlineView size="14" addClass="admin-user__tabs">
    //   <div label="Заявки на покупку">
    //     <div>

    //     </div>
    //   </div>
    //   <div label="Заявки на продажу">
    //     <div>
    //       <Flexbox flexDirection="column" fitContent>
    //         {ordersIssuer && ordersIssuer?.length > 0 ? (
    //           <>
    //             <AdminTable data={formatOrdersInfo(ordersIssuer)} />
    //             {/* <AddBtn>Показать ещё</AddBtn> */}
    //           </>
    //         ) : (
    //           <p style={{ marginTop: 15 }}>Нет сделок</p>
    //         )}
    //       </Flexbox>
    //     </div>
    //   </div>
    // </Tabs>
  );
};
export default Orders;
