import Button from "../Button/Button";
import "./Banner.scss";
import classNames from "classnames";
// import { BannerSvg } from "./Icons";

const Banner = ({ addClass = "", withLink = null }) => {
  const bannerClass = classNames({
    banner: true,
    [`${addClass}`]: addClass,
  });
  return (
    <article className={bannerClass}>
      <div className="banner__content">
        <h4 className="banner__title h4">Копите ЦФА!</h4>

        <p className="banner__text base-24">
          Если у вас хватает ЦФА, вы можете обменять их на квартиру.
        </p>
        {withLink && (
          <Button isLink={withLink} target="_blank">
            Подробнее
          </Button>
        )}
      </div>
      {/* <BannerSvg /> */}
    </article>
  );
};

export default Banner;
