import classNames from "classnames";
import "./index.scss";
import { formatDateFrom } from "../../../utils";

const WaitingReleaseBlock = ({ date }) => {
  const comparisonClass = classNames({
    "comparison-block comparison-block_attention": true,
  });

  if (!date) return null;

  return (
    <article className={comparisonClass}>
      <div className="comparison-block__content">
        <div className="comparison-block__box" style={{ maxWidth: 320 }}>
          <div className="comparison-block__title">Ожидается выпуск</div>

          <span className="comparison-block__text">
            Это анонс выпуска, который еще не начался, указанные здесь данные
            могут поменяться. Купить ЦФА пока нельзя.
          </span>
          <div style={{ marginTop: 20 }}>
            <span className="comparison-block__text">
              Ориентировочная дата начала сбора заявок:{" "}
              <span className="comparison-block__highlighted">
                {formatDateFrom(date, true)}
              </span>
            </span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default WaitingReleaseBlock;
