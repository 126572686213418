import Label from "./Label";

// INIT_PROSPECTUS_CREATED: "Ожидается отправка в ЦФА ХАБ", +

// INIT_PROSPECTUS_SENT: "Отправлено в ЦФА ХАБ", +
// WAITING_FOR_PAYMENT: "Деньги зачисляются", +
// WAITING_FOR_CONFIRMATION: "Проверка оплаты", +
// PAYMENT_CONFIRMATION_SENT: "PAYMENT_CONFIRMATION_SENT", +
// PAYMENT_CONFIRMED: "Оплата подтверждена", +
// CANCELED_BY_OIS: "Отклонено ЦФА ХАБ", +
// WAITING_ISSUE: "Подтверждено  ЦФА ХАБ", +
// CLOSED: "Завершено", +
// CANCELED_BY_INVESTOR: "CANCELED_BY_INVESTOR", +
// CANCEL_CONFIRMED_BY_OIS: "CANCEL_CONFIRMED_BY_OIS", +
// CANCELED: "Выпуск отменен",

const getClassForStatus = (status) => {
  if (
    [
      "NOT_TOKENIZED",
      "INIT_PROSPECTUS_CREATED",
      "INIT_PROSPECTUS_SENT",
      "WAITING_FOR_PAYMENT",
    ].includes(status)
  ) {
    return "status-bg-gray";
  }

  if (
    [
      "WAIT_TOKENIZATION",
      "WAITING_FOR_CONFIRMATION",
      "BOOKED",
      "WAITING_CONFIRMATION",
    ].includes(status)
  ) {
    return "status-bg-yellow";
  }

  if (
    [
      "TOKENIZATION",
      "PAYMENT_CONFIRMATION_SENT",
      "PAYMENT_CONFIRMED",
      "WAITING_ISSUE",
      "RELEASED",
      "CONFIRMATION_SENT",
      "WAITING_PAYMENT",
    ].includes(status)
  ) {
    return "status-bg-blue";
  }

  if (["TOKENIZED", "CLOSED", "NOT_BOOKED", "CONFIRMED"].includes(status)) {
    return "status-bg-green";
  }

  if (
    [
      "REMOVED_FROM_POOL",
      "RELEASE_CANCELED",
      "CANCELED_BY_OIS",
      "CANCELED_BY_INVESTOR",
      "CANCEL_CONFIRMED_BY_OIS",
      "CANCELED",
      "CANCELLED",
      "EXPIRED",
      "FAILED_HANDLE_EVENT_ORDER",
      "REJECT_SENT",
    ].includes(status)
  ) {
    return "status-bg-red";
  }

  return "status-bg-green";
};

const ProgressLabel = ({ status, children }) => {
  return (
    <Label
      buttonType="status"
      addClass={`progress-status ${
        status !== null && getClassForStatus(status)
      }`}
    >
      {status === null ? "Не задан" : children}
    </Label>
  );
};

export default ProgressLabel;
