import Input from "../../ui/Input/Input";
import DateInput from "../../ui/DateInput/DateInput";
import EditIcon from "../../../admin/components/forms/EditForm/EditIcon";
import Button from "../../ui/Button/Button";
import {FormProvider, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import useUserGetBankRequisite from "../../../hooks/fetchers/user/useUserGetBankRequisite";
import {useOrdersPage} from "../../../hooks/fetchers/orders";
import useUserId from "../../../hooks/useUserId";

const RequisitesEditForm = () => {

    const methods = useForm();
    const [bankPaymentRequisiteUid, setBankPaymentRequisiteUid] = useState('');
    const userId = useUserId();
    const { data: orders, isSuccess: isSuccessOrder } = useOrdersPage();

    const { data: bankRequisite, isSuccess: isBankRequisite } =
        useUserGetBankRequisite(bankPaymentRequisiteUid);

    useEffect(() => {
        let isMounted = true;
        if(orders && orders.length > 0 && userId) {
            const findOrderWithBankPaymentRequisiteForUser = orders.find(el => el?.owner?.id === userId);
            if (findOrderWithBankPaymentRequisiteForUser) {
                isMounted && setBankPaymentRequisiteUid(findOrderWithBankPaymentRequisiteForUser.bankPaymentRequisiteUid);
            } else {
                isMounted && setBankPaymentRequisiteUid(null);
            }
        }
        return () => {
            isMounted = false;
        }
    },[orders])

    const onSubmit = (data) => {};

    return (
        <FormProvider {...methods}>
            {bankRequisite ? (
                <form
                    className="form edit-form"
                    style={{maxWidth: '540px'}}
                >
                    <div className="form__row">
                        <div className="form__item">
                            <Input
                                withLabel="Получатель"
                                addClass="form__input form__input_highlighted"
                                size="lg"
                                labelSize="small"
                                disabled
                                defaultValue={bankRequisite?.BankName}
                                name="recipient"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__item">
                            <Input
                                withLabel="Номер счета"
                                addClass="form__input form__input_highlighted"
                                size="lg"
                                labelSize="small"
                                disabled={true}
                                defaultValue={bankRequisite?.SettlementAccount}
                                name={"accountNumber"}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__item">
                            <Input
                                withLabel="Банк получателя"
                                addClass="form__input form__input_highlighted"
                                size="lg"
                                labelSize="small"
                                disabled={true}
                                defaultValue={bankRequisite?.BankName}
                                name={"paybBank"}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__item">
                            <Input
                                withLabel="БИК"
                                addClass="form__input form__input_highlighted"
                                size="lg"
                                labelSize="small"
                                disabled={true}
                                defaultValue={bankRequisite?.BIK}
                                name={"bik"}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__item">
                            <Input
                                withLabel="Корр.счет"
                                addClass="form__input form__input_highlighted"
                                size="lg"
                                labelSize="small"
                                disabled={true}
                                defaultValue={bankRequisite?.CorrespondentAccount}
                                name={"CorrCheck"}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__item">
                            <Input
                                withLabel="ИНН"
                                addClass="form__input form__input_highlighted"
                                size="lg"
                                labelSize="small"
                                disabled={true}
                                defaultValue={bankRequisite?.INN}
                                name={"inn"}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__item">
                            <Input
                                withLabel="КПП"
                                addClass="form__input form__input_highlighted"
                                size="lg"
                                labelSize="small"
                                disabled={true}
                                defaultValue={bankRequisite?.KPP}
                                name={"kkp"}
                            />
                        </div>
                    </div>
                </form>
            ) : (
                <p style={{marginTop: '15px'}}>Реквизиты не найдены!</p>
            )}
        </FormProvider>
    )
}
export default RequisitesEditForm;