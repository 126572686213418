import { useQuery } from "react-query";
import {getNotifications} from "../../api";
import useIsLoggedIn from "../useIsLoggedIn";

const useNotifications = (paramsRequest, refetchActive = false) => {
    const isLoggedIn = useIsLoggedIn();
    return useQuery({
        queryKey: ["notifications", paramsRequest.page, paramsRequest.size, paramsRequest.objectType],
        queryFn: () => getNotifications(paramsRequest),
        enabled: isLoggedIn,
        refetchInterval: refetchActive ? 15000 : null
    });
};

export default useNotifications;