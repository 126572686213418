import { FlatEditForm } from "../forms/EditForm/FlatEditForm";

/**
 * @param {{flat : import("../../../api").Flat}}
 * @returns {import("react").ReactNode}
 */
export default function ChangeFlat({ flat, ...props }) {

  return (
    <div {...props}>
      <FlatEditForm
        id={flat.id}
        flat={flat}
        buildingId={flat.building.id}
        defaultValues={removeUseless(flat)}
        planImg={flat.imagesLinks}
      />
    </div>
  );
}

/** Remove useless info from Flat object
 * @param {import("../../../api").Flat} flat
 * @returns {import("../../../components/forms/PolymorhicForm/FlatDescription").DefaultFlatFormValues}
 */
function removeUseless({
  blockNumber,
  entranceNumber,
  floor,
  number,
  footage,
  roomsCount,
  tokenizationStatus,
  bookingStatus,
}) {
  return {
    blockNumber,
    entranceNumber,
    floor,
    number,
    footage,
    roomsCount,
    tokenizationStatus,
    bookingStatus,
  };
}
