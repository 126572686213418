import axios from "axios";
import qs from "qs";
import { config } from "./config";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshAuth } from "./refetch-auth";
export const apiService = axios.create({
  baseURL: config.api_url,
  headers: {
    "Content-type": "application/json",
  },
});

export const setHeaderToken = (token) => {
  apiService.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeHeaderToken = () => {
  //client.defaults.headers.common.Authorization = null;
  delete apiService.defaults.headers.common.Authorization;
};

createAuthRefreshInterceptor(apiService, refreshAuth, {
  statusCodes: [401, 403], // default: [ 401 ]
  pauseInstanceWhileRefreshing: true,
});

// apiService.interceptors.request.use((axiosConfig) => {
//   // Do something before request is sent

//   // let token = localStorage.getItem("accessToken");
//   // if (token) {
//   //   axiosConfig.headers["Authorization"] = `Bearer ${token}`;
//   // }
//   return axiosConfig;
// });

// apiService.interceptors.response.use(
//   (response) => {
//     // Любой код состояния, находящийся в диапазоне 2xx, вызывает срабатывание этой функции
//     // Здесь можете сделать что-нибудь с ответом
//     return response;
//   }
//   // async (error) => {
//   //   // Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции
//   //   // Здесь можете сделать что-то с ошибкой ответа

//   //   let originalRequest = error.config;

//   //   console.log("error.response: ", error.response);

//   //   if (error.response.status === 403 && !originalRequest._retry) {
//   //     originalRequest._retry = true;

//   //     delete apiService.defaults.headers.common.Authorization;

//   //     console.log("inside if");
//   //     console.log("originalRequest._retry", originalRequest._retry);

//   //     let refreshToken = localStorage.getItem("refreshToken");
//   //     // setTimeout(() => {
//   //     refreshTokens(refreshToken).then((resp) => {
//   //       console.log("originalRequest: ", originalRequest);
//   //       console.warn("success refreshed with tokens: ", resp);
//   //       const { accessToken, refreshToken } = resp;
//   //       localStorage.setItem("accessToken", accessToken);
//   //       localStorage.setItem("refreshToken", refreshToken);
//   //       originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
//   //       axios.defaults.headers.common["Authorization"] =
//   //         "Bearer " + accessToken;

//   //       return apiService(originalRequest);
//   //     });
//   //     // }, 70000);
//   //   }

//   //   return Promise.reject(error);
//   // }
// );

export const registration = async (body) => {
  return await apiService
    .post(`/users/registration`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const login = async (body) => {
  return await apiService
    .post(`/users/login`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const getUser = async () => {
  return await apiService.get("/users/me").then((resp) => {
    return resp.data;
  });
};

export const getUsers = async () => {
  return await apiService.get("/users/all").then((resp) => {
    return resp.data;
  });
};

export const getUserById = async (id) => {
  return await apiService.get(`/users/${id}`).then((resp) => resp.data);
};

export const getUserByPhone = async (phone) => {
  return await apiService
    .get(`/users/phone/${phone}`)
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const editUser = async (body, id) => {
  return await apiService
    .patch(`/users/${id}`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const generateAccessToken = async (body) => {
  return apiService
    .post(`/users/generate_access_token`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const refreshTokens = async (body) => {
  return apiService
    .post(`/users/refresh`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const generatePhoneCode = async (body) => {
  return apiService
    .post(`/users/generate_phone_code`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const getLastCode = async (phone) => {
  return await apiService
    .get(`/users/dev/last_code/${phone}`)
    .then((resp) => resp.data);
};

export const getUserCryptoProfile = async () => {
  return await apiService
    .get("/users/crypto-profiles")
    .then((resp) => resp.data);
};

export const getBankRequisiteUser = async (id) => {
  return await apiService
    .get(`/users/bank-requisite/${id}`)
    .then((resp) => resp.data);
};

export const addDeveloper = async (body) => {
  return apiService
    .post(`/meter/company/developer`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const editDeveloper = async (body, id) => {
  return await apiService
    .put(`/meter/company/developer/${id}`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const getDeveloperById = async (id) => {
  return await apiService
    .get(`/meter/company/developer/${id}`)
    .then((resp) => resp.data);
};

export const getAllDevelopers = async () => {
  return await apiService
    .get(`/meter/company/developer/all`)
    .then((resp) => resp.data);
};

export const addEmitter = async (body) => {
  return apiService
    .post(`/meter/company/emitter`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const editEmitter = async (body, id) => {
  return await apiService
    .patch(`/meter/company/emitter/${id}`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const deleteEmitter = async (id) => {
  return await apiService
    .delete(`/meter/company/emitter/${id}`)
    .then((resp) => resp.data);
};

export const getEmitterById = async (id) => {
  return await apiService
    .get(`/meter/company/emitter/${id}`)
    .then((resp) => resp.data);
};

export const getAllEmitters = async () => {
  return await apiService
    .get(`/meter/company/emitter/all`)
    .then((resp) => resp.data);
};

export const addBuilding = async (body) => {
  return apiService
    .post(`/meter/building/`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const editBuilding = async (body, id) => {
  return await apiService
    .put(`/meter/building/${id}`, JSON.stringify(body))
    .then((resp) => resp.data);
};

/**
 * @param {FormData} body
 * @param {string} id
 */
export const addImagesToBuilding = async ({ body, id }) => {
  return await apiService
    .post(`/meter/building/${id}/images`, body, {
      headers: {
        "Content-type": undefined,
      },
    })
    .then((resp) => resp.data);
};

export const deleteImagesToBuilding = async ({ body, id }) => {
  return await apiService
    .delete(`/meter/building/${id}/images`, body, {
      headers: {
        "Content-type": undefined,
      },
    })
    .then((resp) => resp.data);
};

export const addImagesToBuildingById = async (id, imageId) => {
  return await apiService
    .post(`/meter/building/${id}/images/${imageId}/false`)
    .then((resp) => resp.data);
};

export const deleteImagesToBuildingById = async (body) => {
  return await apiService
    .delete(`/meter/building/${body.id}/images/${body.imageId}/false`)
    .then((resp) => resp.data);
};

/** @type {() => Promise<Building>} */
export const getBuildingById = async (id) => {
  return await apiService
    .get(`/meter/building/${id}`)
    .then((resp) => resp.data);
};

/** @type {() => Promise<Building>} */
export const getBuildingByDeveloperId = async (developerId) => {
  return await apiService
    .get(`/meter/building/by_developer/${developerId}`)
    .then((resp) => resp.data);
};

/** @type {() => Promise<Building[]>} */
export const getAllBuildings = async () => {
  return await apiService.get(`/meter/building/all`).then((resp) => resp.data);
};

export const getBuildingHistory = async (buildingId) => {
  return await apiService
    .get(`/meter/building/${buildingId}/history`)
    .then((resp) => resp.data);
};

// Flat requests

/** @type {() => Promise<Flat[]>} */
export const getAllFlats = async () => {
  return await apiService.get(`/meter/flats/all`).then((resp) => resp.data);
};

/** @type {(id : string) => Promise<Flat>} */
export const getFlatById = async (id) => {
  return await apiService.get(`/meter/flats/${id}`).then((resp) => resp.data);
};

export const addFlat = async (body) => {
  return apiService
    .post(`/meter/flats/`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const editFlat = async ({ id, body }) => {
  return apiService
    .patch(`/meter/flats/${id}`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const addImagesToFlatById = async (body) => {
  return await apiService
    .post(`/meter/flats/${body.id}/images/${body.imageId}`)
    .then((resp) => resp.data);
};

export const deleteImagesToFlatById = async (body) => {
  return await apiService
    .delete(`/meter/flats/${body.id}/images/${body.imageId}`)
    .then((resp) => resp.data);
};

/** @type {(id : string) => Promise<Flat[]>} */
export const getFlatsByBuildingId = async (id) => {
  return await apiService
    .get(`/meter/flats/by_building/${id}`)
    .then((resp) => resp.data);
};

export const addPool = async (body) => {
  return apiService
    .post(`/meter/pool/`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const editPool = async (body, id) => {
  return await apiService
    .patch(`/meter/pool/${id}`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const getPoolById = async (id) => {
  return await apiService.get(`/meter/pool/${id}`).then((resp) => resp.data);
};

export const getPoolByBuildingId = async (id) => {
  return await apiService
    .get(`/meter/pool/by_building/${id}`)
    .then((resp) => resp.data);
};

export const getAllPools = async () => {
  return await apiService.get(`/meter/pool/all`).then((resp) => resp.data);
};

export const authOis = async (body) => {
  return apiService
    .post(`/users/auth/ois`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const getReleases = async (params) => {
  const { available = false, onlyFavorite = false, poolId = "" } = params;
  return await apiService
    .get(
      `/meter/release?available=${available}&onlyFavorite=${onlyFavorite}&poolId=${poolId}`
    )
    .then((resp) => resp.data);
};

export const getReleasesPage = async (params) => {
  const queryString = qs.stringify(params, {
    arrayFormat: "repeat",
  });

  return await apiService
    .get(`/meter/release/page?${queryString}`)
    .then((resp) => resp.data);
};

export const getAllReleases = async () => {
  return await apiService.get(`/meter/release/all`).then((resp) => resp.data);
};

export const getReleaseById = async (id) => {
  return await apiService.get(`/meter/release/${id}`).then((resp) => resp.data);
};

export const getReleaseByOisId = async (id) => {
  return await apiService.get(`/meter/ois/dfa/${id}`).then((resp) => resp.data);
};

export const getReleasesByPoolId = async (id) => {
  return await apiService
    .get(`/meter/release/by_pool/${id}`)
    .then((resp) => resp.data);
};

export const getAvailableReleases = async () => {
  return await apiService
    .get(`/meter/release/available`)
    .then((resp) => resp.data);
};

export const addRelease = async (body) => {
  return apiService
    .post(`/meter/release/`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const editRelease = async (body, id) => {
  return await apiService
    .patch(`/meter/release/${id}`, JSON.stringify(body))
    .then((resp) => resp.data);
};

export const addFiles = async (body) => {
  return await apiService
    .post(`/files/`, body, {
      headers: {
        "Content-type": undefined,
      },
    })
    .then((resp) => resp.data);
};

export const deleteFiles = async (name) => {
  return await apiService
    .delete(`/files/${name}`, {
      headers: {
        "Content-type": undefined,
      },
    })
    .then((resp) => resp.data);
};

export const getMyTokens = async () => {
  return await apiService.get(`/meter/tokens/my`).then((resp) => resp.data);
};

export const makeAdmin = async (userId) => {
  return await apiService
    .post(`/users/role/${userId}/ROLE_ADMIN`, JSON.stringify())
    .then((resp) => resp.data);
};

export const demoteAdmin = async (userId) => {
  return await apiService
    .delete(`/users/role/${userId}/ROLE_ADMIN`, JSON.stringify())
    .then((resp) => resp.data);
};

export const addToFavorites = async ({ userId, buildingId }) => {
  return await apiService
    .post(`/meter/building/favorites/${userId}/${buildingId}`, JSON.stringify())
    .then((resp) => resp.data);
};

export const removeFromFavorites = async ({ userId, buildingId }) => {
  return await apiService
    .delete(
      `/meter/building/favorites/${userId}/${buildingId}`,
      JSON.stringify()
    )
    .then((resp) => resp.data);
};

export const getFavoritesByUserId = async (userId) => {
  return await apiService
    .get(`/meter/building/favorites/${userId}`)
    .then((resp) => resp.data);
};

export const getProspectusById = async (prospectusId) => {
  return await apiService
    .get(`/meter/orders/prospectus/${prospectusId}`)
    .then((resp) => resp.data);
};

export const initOrderMutation = async (body) => {
  return await apiService
    .post("/meter/orders/init", JSON.stringify(body))
    .then((resp) => resp.data);
};

export const signCertMutation = async (thumbprint, file) => {
  return await apiService
    .post(`/certs/sign?thumbprint=${thumbprint}`, file, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
    .then((resp) => resp.data);
};

export const signOrderByIdMutation = async (params) => {
  return await apiService
    .post(`/meter/orders/init/sign/${params.id}`, params.file, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
    .then((resp) => resp.data);
};

export const getQrCodeOrder = async (orderId) => {
  return await apiService
    .get(`/meter/orders/qr/${orderId}`)
    .then((resp) => resp.data);
};

// Сделки для юзера, для покупателя
export const getOrdersForInvestor = async () => {
  return await apiService
    .get(`/meter/orders/init/by_user/INVESTOR`)
    .then((resp) => resp.data);
};

// Сделки для админки, для эмитента, для подтверждения
export const getOrdersForIssuer = async () => {
  return await apiService
    .get(`/meter/orders/init/by_user/ISSUER`)
    .then((resp) => resp.data);
};

export const getOrderById = async (orderId) => {
  return await apiService
    .get(`/meter/orders/init/${orderId}`)
    .then((resp) => resp.data);
};

// Сделки с параметрами
export const getOrdersPage = async (params) => {
  return await apiService
    .get(`/meter/orders/page`, {
      params: {
        page: params?.page || 0,
        size: params?.size || 9999, // до появления сортировки
        issuerExternalId: params?.issuerExternalId,
        investorExternalId: params?.investorExternalId,
        dfaOisKey: params?.dfaOisKey,
        priceGreaterThan: params?.priceGreaterThan,
        priceLessThan: params?.priceLessThan,
        amountGreaterThan: params?.amountGreaterThan,
        amountLessThan: params?.amountLessThan,
        statuses: params?.statuses,
        createdAfter: params?.createdAfter,
        createdBefore: params?.createdBefore,
        updatedAfter: params?.updatedAfter,
        updatedBefore: params?.updatedBefore,
        orderBy: params?.orderBy || "createdAt", // Available values : price, amount, createdAt, updatedAt
        orderDirection: params?.orderDirection || "ASC", // Available values : ASC, DESC
      },
    })
    .then((resp) => resp.data);
};

export const addOrderPaymentDocument = async (body, orderId) => {
  return await apiService
    .post(`/meter/orders/payment_document/${orderId}`, body, {
      headers: {
        "Content-type": undefined,
      },
    })
    .then((resp) => resp.data);
};

export const getPaymentDocumentByOrderId = async (orderId) => {
  return await apiService
    .get(`/meter/orders/payment_document/${orderId}`, {
      responseType: "arraybuffer",
    })
    .then((resp) => resp.data);
};

export const acceptOrderMutation = async (orderId) => {
  return await apiService
    .post(`meter/orders/init/accept/${orderId}`)
    .then((resp) => resp.data);
};

export const rejectOrderPaymentMutation = async (orderId, rejectMessage) => {
  return await apiService
    .post(
      `meter/orders/init/reject_payment/${orderId}`,
      JSON.stringify(rejectMessage)
    )
    .then((resp) => resp.data);
};

export const getOrdersPoolView = async (params) => {
  return await apiService
    .get(`meter/orders/pool_view`, {
      params: {
        user_type: params.user_type || "INVESTOR", // Available values : INVESTOR, ISSUER
        filter_type: params.filter_type, // Available values : PROCESSED, ACTIVE, ARCHIVE
        page: params.page || 0,
        size: params.size || 100,
      },
    })
    .then((resp) => resp.data);
};

export const getActiveOrdersByPoolId = async (params) => {
  return await apiService
    .get(`meter/orders/init/by_pool`, {
      params: {
        pool_id: params.pool_id,
        page: params.page || 0,
        size: params.size || 10,
      },
    })
    .then((resp) => resp.data);
};

export const getOisHealth = async () => {
  return await apiService.get(`meter/ois/health`).then((resp) => resp.data);
};
// Уведомления для юзера

export const getNotifications = async (params) => {
  const queryString = qs.stringify(
    {
      page: params.page,
      size: params.size,
      ...(params.objectType && { object_type: params.objectType }),
    },
    {
      arrayFormat: "repeat",
    }
  );
  return await apiService
    .get(`/meter/notification/?${queryString}`)
    .then((resp) => resp.data);
};

// Type definitions

/**
 * @typedef {{
 *  id : string,
 *  name : string,
 *  description : string,
 *  inn : string,
 *  userId : string
 * }} SmallDeveloper
 */

/** @typedef {"START_BUILDING" | "PERCENT_BUILDING" | "COMMISSIONING" | "FOR_SALE" | "PAYMENTS"} BuildingStatus */

/**
 * @typedef {{
 *  id : string,
 *  name : string,
 *  description : string,
 *  status : BuildingStatus,
 *  city : string,
 *  district: string,
 *  street: string,
 *  houseNumber: string,
 *  plannedDeliveryYear: number,
 *  plannedDeliveryQuarter: string,
 *  startBuildingYear: number,
 *  startBuildingQuarter: string,
 *  percentBuilding: number,
 *  imageIds : string[],
 *  imageLinks : string[],
 *  developer : SmallDeveloper
 * }} Building
 */

/** @typedef {"NOT_TOKENIZED" | "WAIT_TOKENIZATION" | "TOKENIZATION" | "TOKENIZED"} FlatTokenizationStatus */
/** @typedef {"NOT_BOOKED" | "BOOKED" | "RELEASED" | "REMOVED_FROM_POOL"} FlatBookingStatus */

/** @typedef {"INIT_PROSPECTUS_CREATED" | "INIT_PROSPECTUS_SENT" | "WAITING_FOR_PAYMENT" | "WAITING_FOR_CONFIRMATION" | "PAYMENT_CONFIRMATION_SENT" | "CANCELED_BY_OIS" | "WAITING_ISSUE" | "CLOSED" | "CANCELED_BY_INVESTOR" | "CANCEL_CONFIRMED_BY_OIS" | "CANCELED"} OrderStatus */

/**
 * @typedef {{
 *  id : string,
 *  description : string,
 *  footage : number,
 *  bookingStatus : FlatBookingStatus,
 *  tokenizationStatus : FlatTokenizationStatus,
 *  orderStatus: OrderStatus,
 *  building : Building,
 *  blockNumber : number,
 *  entranceNumber : number,
 *  floor : number,
 *  number : number,
 *  roomsCount : number,
 *  flatLists : {id : string, type : string}[],
 *  imageIds : string[]
 * }} Flat
 */
