import SearchInput from "../SearchInput/SearchInput";

import AddFlatModal from "../AddModal/AddFlatModal";
import { useQuery } from "react-query";
import { getAllFlats } from "../../../api";
import { formatFlatsInfo } from "../AdminTable/AdminTableFormaters";
import { Loader } from "../../../components/ui/Loader/Loader";
import AdminTableWithSort from "../AdminTable/AdminTableWithSort";

const Flats = () => {
  const { data: flatsData, isLoading } = useQuery("flats", getAllFlats);

  if (isLoading) return <Loader isBlue isLarge />;
  if (!flatsData) return null;
  return (
    <>
      {flatsData && flatsData.length > 0 && <SearchInput />}
      <AdminTableWithSort
        data={flatsData}
        formatInfo={formatFlatsInfo}
        noDataLabel={"Нет квартир"}
        renderModal={(closeModal) => <AddFlatModal closeModal={closeModal} />}
        addLabel={"Добавить квартиру"}
      />
    </>
  );
};

export default Flats;
