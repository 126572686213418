import classNames from "classnames";
import "./index.scss";
import mocImg from "../../../assets/img/apartment.png";
import Button from "../Button/Button";
import {
    tokenizedStatusLabelMap,
    bookingStatusLabelMap,
} from "../../../admin/components/AdminTable/AdminTableFormaters";
import ProgressLabel from "../Label/ProgressLabel";
const ApartmentCard = ({ data, building }) => {
    const cardClass = classNames({
        "apartment-card": true,
    });

    if (!data) return null;

    return (
        <article className={cardClass}>
            <header className="apartment-card__header">
                <div style={{ marginBottom: 15 }}>
                    <ProgressLabel status={data.tokenizationStatus}>
                        {
                            tokenizedStatusLabelMap[
                            data.tokenizationStatus ?? "NOT_TOKENIZED"
                                ]
                        }
                    </ProgressLabel>
                    {data.bookingStatus && (
                        <ProgressLabel status={data.bookingStatus}>
                            {bookingStatusLabelMap[data.bookingStatus ?? "NOT_BOOKED"]}
                        </ProgressLabel>
                    )}
                </div>

                <span className="apartment-card__name">{building.name}</span>
                <span className="apartment-card__adress text-gray">
          {building.street}, Дом {building.houseNumber}
        </span>
            </header>

            <img
                className="apartment-card__img"
                src={data.imagesLinks[0] || mocImg}
                alt=""
                style={{ height: 200 }}
            />

            <div className="apartment-card__body">
                <span>{`Комнат: ${data.roomsCount}`}</span>
                <span>{data.footage} м²</span>
                <span>{data.floor} этаж</span>
                <span>кв. №{data.number}</span>
            </div>

            {data.externalLink && (
                <Button
                    target="_blank"
                    isAnchor={data.externalLink}
                    buttonType="secondary"
                    size={"xs"}
                >
                    Подробнее
                </Button>
            )}
        </article>
    );
};

export default ApartmentCard;