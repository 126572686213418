import JkInfo from "../../components/content/JkInfo/JkInfo";
import JkStatus from "../../components/content/JkStatus/JkStatus";
import CardMain from "../../components/ui/Card/CardMain";
import ApartmentCard from "../../components/ui/ApartmentCard/ApartmentCard";
import { Loader } from "../../components/ui/Loader/Loader";
import SectionTitle from "../../components/ui/SectionTitle/SectionTitle";
import ComparisonBlock from "../..//components/ui/ComparisonBlock/ComparisonBlock";
import WaitingReleaseBlock from "../../components/ui/ComparisonBlock/WaitingReleaseBlock";
import { useQuery } from "react-query";
import { usePool } from "../../hooks/fetchers/pools";
import ConnectedPools from "./ConnectedPools";
import { getPoolByBuildingId } from "../../api";
import Banner from "../../components/ui/Banner/Banner";
import "./index.scss";
import useReleasesByPoolId from "../../hooks/fetchers/releases/useReleasesByPoolId";
import useReleaseId from "../../hooks/fetchers/releases/useReleaseId";
import TokenPriceHistoryTable from "../../components/ui/Table/TokenPriceHistoryTable";
import { getActiveReleases, getOldestActiveRelease } from "../../utils";
import AttentionBlock from "../../components/ui/ComparisonBlock/AttentionBlock";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import useWaitingRelease from "../../hooks/useWaitingRelease";
import PercentOfIncomeBlock from "../../components/ui/ComparisonBlock/PercentOfIncomeBlock";

const JkPage = () => {
  const { releaseId } = useParams();
  const { data: releaseByUrlId } = useReleaseId(releaseId);

  const { data: pool, isLoading } = usePool();
  const { data: jkPools, isLoading: isPoolsLoading } = useQuery({
    queryFn: () => getPoolByBuildingId(pool.building.id),
    queryKey: "pools",
    enabled: !!pool && !!pool.building,
  });

  const { data: releasesInPool, isLoading: isReleaseLoading } =
    useReleasesByPoolId(pool?.id);

  // e. Выпуск в статусе “Не токенезирован” не отображать
  const filteredReleasesInPool = useMemo(() => {
    if (releasesInPool)
      return releasesInPool.filter(
        (release) => release.status !== "NOT_TOKENIZED"
      );
    return null;
  }, [releasesInPool]);

  const getLastReleaseByStatus = (releases, status) => {
    if (!releases) return null;
    const releasesByStatus = releases.filter(
      (release) => release.status === status
    );

    if (releasesByStatus) return releasesByStatus[releasesByStatus.length - 1];
    return null;
  };

  const releaseForDisplay = useMemo(() => {
    if (releaseByUrlId) return releaseByUrlId; // Прямая ссылка на выпуск, отображать в любом статусе, передавая второй идентификатор.

    // Порядок отображение выпусков на странице пула:
    // a. Старший активный выпуск
    // b. Выпуск в статусе “Ожидает токенизацию”
    // c. Последний состоявшийся выпуск
    // d. Последний выпуск

    if (filteredReleasesInPool) {
      return (
        getOldestActiveRelease(filteredReleasesInPool) ||
        getLastReleaseByStatus(filteredReleasesInPool, "WAIT_TOKENIZATION") ||
        getLastReleaseByStatus(filteredReleasesInPool, "TOKENIZED") ||
        filteredReleasesInPool[filteredReleasesInPool.length - 1]
      );
    }
    return null;
  }, [filteredReleasesInPool, releaseByUrlId]);

  const isWaitingRelease = useWaitingRelease(releaseForDisplay);

  const sortedFlats = useMemo(() => {
    if (!pool) return [];

    const statusOrderForFlats = [
      "TOKENIZATION",
      "WAIT_TOKENIZATION",
      "TOKENIZED",
      "NOT_TOKENIZED",
    ];

    return pool.flatsList.sort((a, b) => {
      return (
        statusOrderForFlats.indexOf(a.tokenizationStatus) -
        statusOrderForFlats.indexOf(b.tokenizationStatus)
      );
    });
  }, [pool]);

  if (isLoading || isPoolsLoading || isReleaseLoading)
    return <Loader isBlue isLarge />;

  // if (!releaseForDisplay) return <Navigate to={"/"} />; // Если нет активного релиза никакого у пула
  if (!pool) return null;
  const FLATS_TO_SHOW_LIMIT = 6;

  return (
    <section className="home jk">
      <div className="jk__title title">
        <span>{pool.building?.name}</span>
        <span className="jk__sub">{pool.building?.developer?.name}</span>
      </div>

      {filteredReleasesInPool && (
        <div className="home__slider">
          <CardMain
            releases={getActiveReleases(filteredReleasesInPool)}
            releaseForDisplay={releaseForDisplay}
            buyTokens
          />
        </div>
      )}
      <div className="jk__info-grid">
        <JkInfo data={pool} />

        <div className="jk__info-tokens">
          {/* <TimerBlock data={pool} isLarge /> */}

          {isWaitingRelease && releaseForDisplay.plannedStartDate && (
            <WaitingReleaseBlock date={releaseForDisplay.plannedStartDate} />
          )}

          {releaseForDisplay &&
            releaseForDisplay?.plannedEndDate &&
            !isWaitingRelease && (
              <AttentionBlock date={releaseForDisplay.plannedEndDate} />
            )}

          {filteredReleasesInPool && (
            <ComparisonBlock
              data={releaseForDisplay}
              repaymentMethod={pool.repaymentMethod}
            />
          )}

          {pool.displayPercentOfIncome && pool.percentOfIncome && (
            <PercentOfIncomeBlock percent={pool.percentOfIncome} />
          )}
        </div>
      </div>
      <SectionTitle>Список токенизированых квартир</SectionTitle>
      {sortedFlats && pool.building && (
        <div className="jk__grid">
          {sortedFlats.map((flat) => (
            <ApartmentCard
              data={flat}
              building={pool.building}
              key={`jk-flat-${flat.id}`}
            />
          ))}
        </div>
      )}

      {/* {release.flatsList.length > FLATS_TO_SHOW_LIMIT && <ShowmoreBtn /> */}
      {pool.exchangeTokensOnFlat && (
        <Banner addClass="jk__banner" withLink={pool.externalPromoLink} />
      )}
      {pool.building && <JkStatus buildingId={pool.building.id} />}
      <section className="history">
        <SectionTitle>Выпуски ЦФА от пула {pool.name}</SectionTitle>
        {filteredReleasesInPool && (
          <TokenPriceHistoryTable data={filteredReleasesInPool} />
        )}
      </section>
      {jkPools && <ConnectedPools pools={jkPools} />}
    </section>
  );
};

export default JkPage;
