import {numWord, sumFootage} from "../../../utils";
import AdminTable from "../../../admin/components/AdminTable/AdminTable";
import Flexbox from "../Flexbox/Flexbox";
import heartFilledBlueIcon from "../../../assets/img/icons/heart-filled-blue.svg";

const ReleaseNewTokens = ({newRelease, releaseByUrlId}) => {
    const buildingName = releaseByUrlId?.building?.name;

    const templatesDesc = {
        readinessBuilding: "добавил новый выпуск.",
    };

    const getReleasesData = () => {
        if (!releaseByUrlId) return null;

        return {
            Пул: [releaseByUrlId]?.map((el) => el.pool.name),
            Выпуск: [releaseByUrlId]?.map((el) => ({
                name: el.name,
            })),
            "Общий метраж": [releaseByUrlId]?.map((el) => sumFootage(el?.flatsList)),
            Количество: [releaseByUrlId]?.map(
                (el) =>
                    `${el.flatsList.length} ${numWord(el.flatsList.length, [
                        "квартира",
                        "квартиры",
                        "квартир",
                    ])}`
            ),
            "Цена за 100 ЦФА": [releaseByUrlId]?.map((el) => el.price),
        };
    };

    return (
        <>
            <p className="notification-card__desc">
                <img
                    src={heartFilledBlueIcon}
                    alt="иконка"
                    style={{marginRight: "6px"}}
                />
                {buildingName?.includes("ЖК")
                    ? `${buildingName} ${templatesDesc["readinessBuilding"]}`
                    : `ЖК ${String.fromCharCode(171)}${buildingName}${String.fromCharCode(187)}
        ${templatesDesc["readinessBuilding"]}`}
            </p>
            <div className="new-releases-table">
                <object>
                    <Flexbox flexDirection="column">
                        {releaseByUrlId && Object.values(releaseByUrlId).length > 0 && (
                            <AdminTable data={getReleasesData([releaseByUrlId])}/>
                        )}
                    </Flexbox>
                </object>
            </div>
        </>
    );
};
export default ReleaseNewTokens;
