import { useEffect, useState } from "react";
import Input from "../../ui/Input/Input";
import CodeField from "../../ui/CodeField/CodeField";
import Countdown from "react-countdown";
import Button from "../../ui/Button/Button";
import { useFormContext } from "react-hook-form";
import BackBtn from "../../ui/BackBtn/BackBtn";
import { useQuery, useMutation } from "react-query";
import { generatePhoneCode, getLastCode, getUserByPhone } from "../../../api";
import useEnterSubmit from "../../../hooks/useEnterSubmit";
import { Link } from "react-router-dom";
const StepLogin = ({
  setCodeFilled,
  changePhoneRequest = false,
  closeModal,
  isLoginAction = false,
}) => {
  const [codeRequest, setCodeRequest] = useState(false);
  const [sendAgain, setSendAgain] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Function to format phone number
  function formatPhoneNumber(value) {
    value = value.trim();
    if (value.startsWith("7" || "8")) {
      value = "+7" + value.slice(1);
    }

    if (!value.startsWith("+7")) {
      value = "+7" + value;
    }

    if (value.startsWith("+7+7")) {
      value = value.slice(2);
    }

    // Добавить для прода
    // if (value.startsWith("+77") || value.startsWith("+78")) {
    //   value = "+7" + value.slice(3);
    // }
    if (value.startsWith("+78")) {
      value = "+7" + value.slice(3);
    }

    if (Number.isNaN(+value.slice(-1))) {
      value = value.slice(0, -1);
    }

    value = value
      .replace(/[\s()-]/g, "")
      .replace(/(\+7)(\d{3})(\d{3})(\d{2})(\d{2})/, "+7 $2 $3-$4-$5");

    if (value.length > 16) {
      value = value.slice(0, 16);
    }

    return value;

    // Phone number regex pattern
    // const phonePattern = /^\+[\d\s]{5,}$/;

    // // Trim any whitespace
    // value = value.trim();

    // // Check if input matches phone number pattern
    // if (!phonePattern.test(value)) {
    //   return value;
    // }

    // // Add + symbol if not already present
    // if (!value.startsWith("+")) {
    //   value = "+" + value;
    // }

    // // Format number groups
    // value = value.replace(/(\d{1,3})(\d{3})(\d{3})(\d{2})/, "$1 $2 $3-$4");

    // return value;

    // value = value.trim();

    // if (!value.startsWith("+7") || !value.startsWith("+")) {
    //   value = "+7" + value;
    // }

    // value = value.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 $2 $3-$4");
    // return value;
  }

  const generatePhoneCodeMutation = useMutation({
    mutationFn: (body) => {
      return generatePhoneCode(body);
    },
  });

  const generateCode = () =>
    generatePhoneCodeMutation.mutate(
      {
        type: "AUTH", // REGISTRATION
        phone: enteredPhoneNumber,
      },
      {
        onSuccess: () => {
          getLastCodeQuery().then(() => setCodeRequest(true));
        },
      }
    );

  const {
    data: userByPhone,
    refetch: getUserByPhoneQuery,
    error: userByPhoneError,
    isLoading: userByPhoneLoading,
  } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserByPhone(enteredPhoneNumber),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    onError: () => {
      if (isLoginAction) {
        setErrorMessage(
          `Пользователь с телефоном ${enteredPhoneNumber} не найден`
        );
        return;
      }
      generateCode();
    },
    onSuccess: () => {
      if (!isLoginAction) {
        setErrorMessage(
          `Пользователь с телефоном ${enteredPhoneNumber} уже существует`
        );
        return;
      }
      generateCode();
    },
  });

  const { data: lastCode, refetch: getLastCodeQuery } = useQuery({
    queryKey: ["lastCode"],
    queryFn: () => getLastCode(enteredPhoneNumber),
    enabled: codeRequest,
    onSuccess: (data) => {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(data)
          .then(() => alert(`Ваш код: ${data}`))
          .catch((err) => {
            console.error("Failed to copy text: ", err);
          });
      }
    },
  });

  const codeRequestHandler = () => {
    getUserByPhoneQuery().then(() => setSendAgain(true));
  };

  useEnterSubmit(codeRequestHandler);

  const { watch, setValue } = useFormContext();

  const enteredPhone = watch("phone");
  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState("");
  useEffect(() => {
    if (enteredPhone) {
      setEnteredPhoneNumber(enteredPhone.replace(/[+\s-]/g, ""));
    }
  }, [enteredPhone, enteredPhoneNumber]);

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      setSendAgain(false);
      return null;
    }

    // Render a countdown
    return (
      <span>
        Отправить код повторно через: <b>{seconds}</b> сек.
      </span>
    );
  };

  return (
    <>
      {codeRequest && (
        <BackBtn
          addClass="modal__back-btn"
          isClean
          onClick={() => setCodeRequest(false)}
        />
      )}

      {!changePhoneRequest && (
        <div className="modal__info text-block">
          {!isLoginAction && (
            <p>
              Зарегистрируйтесь по номеру телефона и получите доступ ко всем
              преимуществам личного кабинета.
            </p>
          )}
          <p>
            У нас нет паролей — вход осуществляется по номеру телефона, на
            который мы присылаем СМС c кодом доступа.
          </p>
        </div>
      )}

      {!codeRequest ? (
        <Input
          type="tel"
          withLabel="Введите номер телефона"
          labelSize={"small"}
          addClass={"input_lg"}
          placeholder="+7 999 999-99-99"
          name="phone"
          withError={errorMessage}
          onChange={(evt) => {
            setValue("phone", formatPhoneNumber(evt.target.value));
          }}
        />
      ) : (
        <CodeField
          lastCode={lastCode}
          setCodeFilled={setCodeFilled}
          closeModal={closeModal}
          isLoginAction={isLoginAction}
        />
      )}

      {!codeRequest ? (
        <Button
          addClass="modal__btn"
          isFull
          disabled={
            !enteredPhoneNumber ||
            enteredPhoneNumber === 0 ||
            userByPhoneLoading
          }
          onClick={codeRequestHandler}
          isLoading={userByPhoneLoading}
        >
          Получить код
        </Button>
      ) : (
        <Button
          addClass="modal__btn"
          isFull
          disabled={sendAgain || generatePhoneCodeMutation.isLoading}
          onClick={generateCode}
          isLoading={generatePhoneCodeMutation.isLoading}
        >
          {!sendAgain ? (
            <>Отправить код повторно</>
          ) : (
            <Countdown date={Date.now() + 10000} renderer={renderer} />
          )}
        </Button>
      )}

      {!isLoginAction && (
        <p className="agreement text-gray">
          Нажимая кнопку «Получить код», вы подтверждаете своё согласие на
          <Link
            onClick={closeModal}
            to="/personal-agreement"
            className="link link_blue"
            target="_blank"
          >
            обработку персональных данных
          </Link>
          , принимаете условия{" "}
          <Link
            onClick={closeModal}
            to="/policy"
            className="link link_blue"
            target="_blank"
          >
            политики конфиденциальности
          </Link>{" "}
          и соглашаетесь с условиями{" "}
          <Link
            onClick={closeModal}
            to="/license"
            className="link link_blue"
            target="_blank"
          >
            лицензионного договора
          </Link>
        </p>
      )}
    </>
  );
};

export default StepLogin;
