import CardHorizontalSells from "./CardHorizontalSells";
import "./index.scss";


const MySells = () => {


  return (
    <section className="my-tokens">
      {/* <CardHorizontalSells />
      <CardHorizontalSells />
      <CardHorizontalSells />
      <CardHorizontalSells />
      <CardHorizontalSells />
      <CardHorizontalSells /> */}
    </section>
  );
};

export default MySells;
