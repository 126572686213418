import { getReleaseById } from "../../../api";
import { useQuery } from "react-query";
const useReleaseId = (id) => {
  return useQuery({
    queryKey: ["releases", id],
    queryFn: () => getReleaseById(id),
    enabled: !!id,
  });
};

export default useReleaseId;
