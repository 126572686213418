import {useEffect, useState} from "react";

function useGetStatusReadinessJk(content) {
    const [statusReadinessJk, setStatusReadinessJk] = useState({});

    useEffect(() => {
        let isMounted = true;

        if (content?.objectType === 'BUILDING' && content?.eventType === 'STATUS_CHANGED') {
            isMounted && setStatusReadinessJk(content);
        } else {
            isMounted && setStatusReadinessJk({});
        }

        return () => {
            isMounted = false;
        }
    },[content.objectType, content.eventType])

    return {statusReadinessJk};
}
export default useGetStatusReadinessJk;