import CollapseToggler from "../Collapse/CollapseToggler";
import Collapse from "../Collapse/Collapse";
import { useState } from "react";
import ReleaseLink from "../ReleaseLink/ReleaseLink";
import { formatId } from "../../../utils";
import { SignIcon } from "./SignIcon";
import Checkbox from "../Checkbox/Checkbox";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import CertInfo from "./CertInfo";
import Select from "../Select/Select";

const SignItem = ({
  release,
  isDefaultOpened = false,
  isSigned,
  onSignOrder,
  currentCertificate,
  onButtonClickGetCertificate,
  certificates,
  activeSelectCertificates,
  signatureError,
}) => {
  const [collapseOpened, setCollapseOpened] = useState(isDefaultOpened);
  const [docsCheckboxChecked, setDocsCheckboxChecked] = useState(false);
  const [agreementCheckboxChecked, setAgreementCheckboxChecked] =
    useState(false);

  const docReleaseLink = release?.docReleaseLink
    ? release?.docReleaseLink
    : "#";
  const riskNotificationLink = release?.riskNotificationLink
    ? release?.riskNotificationLink
    : "#";

  return (
    <div className="sign-card">
      <CollapseToggler
        addClass={"sign-card__toggler"}
        collapseOpened={collapseOpened}
        setCollapseOpened={setCollapseOpened}
      >
        <SignIcon isChecked={isSigned} />
        <ReleaseLink release={release} />
      </CollapseToggler>
      <Collapse isOpened={collapseOpened}>
        <div className="sign-card__content">
          <label style={{ display: "flex", gap: 8, cursor: "pointer" }}>
            <Checkbox
              checkboxValue={docsCheckboxChecked}
              setCheckboxValue={setDocsCheckboxChecked}
            />
            <div>
              Я согласен с{" "}
              <span>
                {docReleaseLink ? (
                  <Link
                    to={docReleaseLink}
                    className="link link_blue"
                    target="_blank"
                  >
                    Решением о выпуске
                  </Link>
                ) : (
                  "Решением о выпуске"
                )}
              </span>{" "}
              и{" "}
              <span>
                {riskNotificationLink ? (
                  <Link
                    to={riskNotificationLink}
                    className="link link_blue"
                    target="_blank"
                  >
                    Уведомлением о рисках
                  </Link>
                ) : (
                  "Уведомлением о рисках"
                )}
              </span>
            </div>
          </label>

          <label style={{ display: "flex", gap: 8, cursor: "pointer" }}>
            <Checkbox
              checkboxValue={agreementCheckboxChecked}
              setCheckboxValue={setAgreementCheckboxChecked}
            />
            <div>Я соглашаюсь и подписываю заявку о покупке</div>
          </label>
          {/*---Раскомментировать, если нужно будет выбирать сертификаты из списка доступых*/}
          {/*{activeSelectCertificates && (*/}
          {/*    <>*/}
          {/*        {certificates && certificates.length > 0 ? (*/}
          {/*            <>*/}
          {/*                <Select*/}
          {/*                    options={certificates.map((cert) => ({*/}
          {/*                        label: cert.name,*/}
          {/*                        value: cert.thumbprint,*/}
          {/*                    }))}*/}
          {/*                    onChangeValue={(value) => onButtonClickGetCertificate(value)}*/}
          {/*                    defaultOption={"Выберите сертификат"}*/}
          {/*                />*/}
          {/*                {signatureError && <p>{signatureError}</p>}*/}
          {/*            </>*/}
          {/*        ) : (*/}
          {/*            <p>Сертификаты не найдены!</p>*/}
          {/*        )}*/}
          {/*    </>*/}
          {/*)}*/}
          {signatureError && <p>{signatureError}</p>}
          <Button
            disabled={
              activeSelectCertificates
                ? !docsCheckboxChecked ||
                  !agreementCheckboxChecked ||
                  !currentCertificate ||
                  signatureError ||
                  isSigned
                : !docsCheckboxChecked ||
                  !agreementCheckboxChecked ||
                  signatureError ||
                  isSigned
            }
            size="m"
            onClick={onSignOrder}
            isFull
          >
            {isSigned ? "Подписано" : "Подписать"}
          </Button>

          <CertInfo currentCertificate={currentCertificate} />
        </div>
      </Collapse>
    </div>
  );
};

export default SignItem;
