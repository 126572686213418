import { useMemo } from "react";
const useWaitingRelease = (release) => {
  const isWaitingRelease = useMemo(() => {
    if (release) {
      return release.status === "WAIT_TOKENIZATION";
    }
    return false;
  }, [release]);
  return isWaitingRelease;
};

export default useWaitingRelease;
