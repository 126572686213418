import AdminTable from "../AdminTable/AdminTable";
import SearchInput from "../SearchInput/SearchInput";
import AddBtn from "../AddButton";
import Flexbox from "../../../components/ui/Flexbox/Flexbox";
import { getAllEmitters } from "../../../api";
import useModal from "../../../hooks/useModal";
import Modal from "react-modal";
import { useQuery } from "react-query";
import AddEmitterModal from "../AddModal/AddEmitterModal";
import { formatId, getFullName } from "../../../utils";
import { Loader } from "../../../components/ui/Loader/Loader";
const Emitters = () => {
  const { modalIsOpen, openModal, closeModal } = useModal();

  const { data: emitters, isLoading } = useQuery({
    queryKey: ["emitters"],
    queryFn: getAllEmitters,
  });

  if (isLoading) {
    return <Loader isLarge isBlue />;
  }

  return (
    <>
      {emitters && emitters.length > 0 && <SearchInput />}
      <Flexbox flexDirection="column" fitContent>
        {emitters && emitters.length > 0 && (
          <AdminTable
            data={{
              Эмитент: emitters?.map((it) => ({
                name: it.name || getFullName(it.user),
                link: `../emitters/${it.id}`,
              })),
              "ID ЦМ эмитента": emitters?.map((it) => formatId(it.id)),
              "ID ОИС эмитента": emitters?.map((it) =>
                it.idOis ? formatId(it.idOis) : ""
              ),

              "ID ЦМ пользователя": emitters?.map((it) => formatId(it.userId)),

              "Номер телефона": emitters?.map((it) => it.user?.phone),
              Email: emitters?.map((it) => it.user?.email || "-"),
            }}
          />
        )}

        {emitters.length === 0 && (
          <p style={{ marginTop: 15 }}>Нет эмитентов</p>
        )}

        <AddBtn onClick={openModal}>Добавить эмитента</AddBtn>
      </Flexbox>
      <Modal
        isOpen={modalIsOpen}
        //Пока закомментировал, закрытие модалки при клике вне её области, по просьбе Андрея
        // onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <AddEmitterModal closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default Emitters;
