import FeedbackForm from "../../../forms/FeedbackForm/FeedbackForm";
import SectionTitle from "../../../ui/SectionTitle/SectionTitle";
import "./Requisites.scss";
import RequisitesEditForm from "../../../forms/RequisitesEditForm/RequisitesEditForm";

const Requisites = () => {

  return (
    <section className="requisites">
      <RequisitesEditForm/>
    </section>
  );
};

export default Requisites;
