import "./index.scss";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import classNames from "classnames";

const Select = ({
  options,
  defaultOption,
  addClass,
  withLabel = "",
  whiteType = false,
  onChangeValue,
  onChange,
  ...arg
}) => {
  const dropdownClasses = classNames({
    "dropdown select": true,
    [`${addClass}`]: addClass,
    select_white: whiteType,
  });
  return (
    <label className="input-wrapper">
      {withLabel && (
        <span className="input-wrapper__label body-smaller">{withLabel}</span>
      )}
      <Dropdown
        className={dropdownClasses}
        options={options}
        onChange={(option) => {
          onChange?.(option);
          onChangeValue?.(option.value);
        }}
        arrowClassName="select__arrow"
        {...arg}
        value={defaultOption}
      />
    </label>
  );
};

export default Select;
