import { getReleaseByOisId } from "../../../api";
import { useQuery } from "react-query";
const useOisRelease = (id, options) => {
  const release = useQuery({
    queryKey: ["releases", id],
    queryFn: () => getReleaseByOisId(id),
    enabled: false,
    ...options,
  });
  return release;
};

export default useOisRelease;
