import { useState } from "react";
import Button from "../../../../components/ui/Button/Button";
import "./EditForm.scss";

import { useForm, FormProvider } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { editPool } from "../../../../api";
import PoolDescription from "../../../../components/forms/PolymorhicForm/PoolDescription";
import { formatId } from "../../../../utils";

import { RepaymentMethods } from "../../../../constants";
import ErrorMessage from "../../../../components/ui/ErrorMessage/ErrorMessage";
const PoolEditForm = ({ currentPool, id, userId, isAdmin = false }) => {
  const [editMode, setEditMode] = useState(false);

  const queryClient = useQueryClient();

  const methods = useForm({
    defaultValues: {
      ...currentPool,
      emitterId: currentPool.emitter
        ? {
            value: currentPool.emitter?.id,
            label:
              currentPool.emitter?.name || formatId(currentPool.emitter?.id),
          }
        : undefined,
      flatsList: currentPool?.flatsList.map((flat) => ({
        value: flat.id,
        label: `кв. ${formatId(flat?.number)}`,
      })),
      // repaymentMethod: {
      //   value: currentPool?.repaymentMethod,
      //   label: RepaymentMethods[currentPool?.repaymentMethod],
      // },
      repaymentMethod: currentPool?.repaymentMethod,
    },
  });

  const editPoolMutation = useMutation({
    mutationFn: (body) => {
      return editPool(body, id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["pools"],
      });
      // afterSubmit();
    },
  });

  const onSubmit = (data) => {
    editPoolMutation.mutate(
      {
        flatsList: data.flatsList.map((flat) => flat.value),
        name: data.name,
        description: null,
        emitterId: data.emitterId.value,
        repaymentMethod: data.repaymentMethod,
        percentOfIncome: data.percentOfIncome,
        exitPrice: data.exitPrice,
        exchangeTokensOnFlat: data.exchangeTokensOnFlat,
        externalPromoLink: data.externalPromoLink,
        displayPercentOfIncome: data.displayPercentOfIncome,
      },
      {}
    );
    setEditMode(false);
  };

  return (
    <FormProvider {...methods}>
      <form
        className="form edit-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <p className="h8 form__title">Описание</p>
        <PoolDescription
          chosenBuilding={currentPool.building?.id}
          methods={methods}
          disabled={!editMode}
        />

        <div className="form__row">
          <div className="form__edit">
            <div className="form__admin-btns">
              <Button
                type="button"
                buttonType="secondary"
                size={"m"}
                addClass="form__edit-btn"
                onClick={() => {}}
              >
                Удалить пул
              </Button>
            </div>
            {!editMode ? (
              <Button
                type="button"
                buttonType="secondary"
                size={"m"}
                addClass="form__edit-btn"
                onClick={(evt) => {
                  evt.preventDefault();
                  setEditMode(true);
                }}
              >
                Редактировать
              </Button>
            ) : (
              <Button
                type="submit"
                isLoading={editPoolMutation.isLoading}
                buttonType="primary"
                size={"m"}
                addClass="form__edit-btn"
              >
                Сохранить
              </Button>
            )}
          </div>
        </div>

        <ErrorMessage forRequest={editPoolMutation} isHidden={editMode} />
      </form>
    </FormProvider>
  );
};

export default PoolEditForm;
