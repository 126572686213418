import classNames from "classnames";
import "./Popup.scss";
import infoSvg from "../../../assets/img/icons/info.svg";
import chatSvg from "../../../assets/img/icons/chat.svg";
import chatGraySvg from "../../../assets/img/icons/chat-gray.svg";
import CloseButton from "../CloseButton/CloseButton";
import useClickOutside from "../../../hooks/useClickOutside";

const Popup = ({ withIcon = false, chatIcon = false, children }) => {
  const { ref, isOpened, setIsOpened } = useClickOutside();

  const popupClass = classNames({
    popup: true,
    popup_black: chatIcon,
  });

  return (
    <div
      className="popup-container"
      ref={ref}
      onMouseLeave={() => setIsOpened(false)}
    >
      <button
        type="button"
        onMouseEnter={() => setIsOpened(true)}
        onClick={() => setIsOpened(!isOpened)}
      >
        {chatIcon ? (
          <div className="popup-container__chat">
            <img src={chatSvg} alt="" />
          </div>
        ) : (
          <img src={infoSvg} alt="" />
        )}
      </button>

      {!isOpened ? null : (
        <article className={popupClass}>
          {withIcon && (
            <img
              src={chatIcon ? chatGraySvg : infoSvg}
              alt=""
              className="popup__info-icon"
            />
          )}
          <p className="popup__text">{children}</p>
          <CloseButton
            addClass="popup__close-btn"
            onClick={() => setIsOpened(false)}
          />
        </article>
      )}
    </div>
  );
};

export default Popup;
