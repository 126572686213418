import { useOrdersPoolView } from "../../../../hooks/fetchers/orders";
import { Loader } from "../../../ui/Loader/Loader";
import Tabs from "../../../ui/Tabs/Tabs";
import "./index.scss";
import CardHorizontalOrder from "./CardHorizontalOrder";
import { useState } from "react";

const MyOrders = () => {
  const [ordersState, setOrdersState] = useState("PROCESSED"); // TODO поменять в табах везде на search params

  const { data: orders, isLoading: isLoadingOrders } = useOrdersPoolView({
    filter_type: ordersState,
  });

  // // TODO - Переделать на параметр с бэка в запросе, когда он появится
  // const orders = useMemo(() => {
  //   if (data) {
  //     return [...data].reverse();
  //   }
  //   return null;
  // }, [data]);

  // if (!orders) return null;

  return (
    <Tabs
      underlineView
      size="14"
      addClass="admin-user__tabs"
      onChange={(label) => {
        label === "Архивные"
          ? setOrdersState("ARCHIVE")
          : setOrdersState("PROCESSED");
      }}
    >
      <div label="В процессе">
        {orders && orders.length > 0 ? (
          <section className="my-tokens">
            {orders.map((order) => {
              return (
                <CardHorizontalOrder
                  key={`my-order-${order.amount}`}
                  tokenData={order}
                />
              );
              //
            })}
          </section>
        ) : isLoadingOrders ? (
          <Loader isBlue isLarge />
        ) : (
          <p style={{ marginTop: 15 }}>У вас нет незаконченных сделок по ЦФА</p>
        )}
      </div>
      <div label="Архивные">
        {orders && orders.length > 0 ? (
          <section className="my-tokens">
            {orders.map((order) => {
              return (
                <CardHorizontalOrder
                  key={`my-order-${order.amount}`}
                  tokenData={order}
                  isArchive
                />
              );
              //
            })}
          </section>
        ) : isLoadingOrders ? (
          <Loader isBlue isLarge />
        ) : (
          <p style={{ marginTop: 15 }}>У вас нет архивных сделок по ЦФА</p>
        )}
      </div>
    </Tabs>
  );
};

export default MyOrders;
