import { useState } from "react";
import "./CopyButton.scss";

const SuccessIcon = () => (
  <svg
    className="copy-button__success-icon"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />{" "}
    <rect x="9" y="3" width="6" height="4" rx="2" /> <path d="M9 14l2 2l4 -4" />
  </svg>
);

const CopyIcon = () => (
  <svg
    className="copy-button__icon"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
    />
  </svg>
);

export default function CopyButton({ value, onClick, ...attr }) {
  const [successState, setSuccessState] = useState(false);
  const makeCopy = () => {
    navigator.clipboard.writeText(value);
    setSuccessState(true);
    setTimeout(() => {
      setSuccessState(false);
    }, 1200);
  };
  return (
    <button
      className="copy-button"
      type="button"
      onClick={() => {
        onClick && onClick();
        makeCopy();
      }}
      disabled={successState}
      {...attr}
    >
      {successState ? <SuccessIcon /> : <CopyIcon />}
    </button>
  );
}
