import classNames from "classnames";
import MetrLogo from "../../ui/Logo/MetrLogo";
import "./index.scss";
import { useMemo } from "react";

const FooterBottom = () => {
  const footerBottomClass = classNames({
    footer__bottom: true,
  });

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <section className={footerBottomClass}>
      <div className="footer__metr">
        <MetrLogo />
        <span className="footer__sign">© Цифровой квадрат, {currentYear}</span>
      </div>

      <span className="footer__cookie">
        Мы используем файлы cookie, для персонализации сервисов и повышения
        удобства пользования сайтом. Если вы не согласны на их использование,
        поменяйте настройки браузера.
      </span>
    </section>
  );
};

export default FooterBottom;
