import { useQuery } from "react-query";
import { getReleasesPage } from "../../../api";
const useReleases = (params) => {
  return useQuery({
    queryKey: ["releases"],
    queryFn: () =>
      getReleasesPage({ size: 100, direction: "DESC", ...params }),
  });
};

export default useReleases;
