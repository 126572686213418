import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "../../ui/Button/Button";
import CtrrLogo from "../../ui/Logo/CtrrLogo";
import Logo from "../../ui/Logo/Logo";
import RedArrowLogo from "../../ui/Logo/RedArrowLogo";
import "./index.scss";
import { aboutLinks } from "../Header/TopBar/aboutLinks";
import { accountLinks } from "../TabsLayout/links";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import { config } from "../../../config";
const FooterTop = () => {
  const footerTopClass = classNames({
    footer__top: true,
  });

  const isLoggedIn = useIsLoggedIn();

  return (
    <section className={footerTopClass}>
      <div className="footer__logos-col">
        <Logo />
        {/* <div className="footer__logos-box">
          <span className="footer__logos-title">Совместный проект</span>
          <RedArrowLogo />
          <CtrrLogo />
        </div> */}
      </div>

      <div className="footer__nav">
        <div className="footer__nav-item">
          {aboutLinks.map((link) => (
            <Link
              key={link.id}
              to={link.link}
              className="footer__nav-link link link_gray"
            >
              {link.title}
            </Link>
          ))}
          <Link
            to={"/agreement"}
            className="footer__nav-link link link_gray"
            target="_blank"
          >
            Пользовательское соглашение
          </Link>
          <Link
            to={
              "https://meter.dltc.spbu.ru/s3/meter-files/ada9b35a-ef49-4724-88ee-8acf6a003a08.pdf"
            }
            className="footer__nav-link link link_gray"
            target="_blank"
          >
            Руководство пользователя
          </Link>
        </div>
      </div>

      <div className="footer__contacts">
        <span className="footer__contacts-title">
          Есть вопросы и предложения?
        </span>
        <Button
          isAnchor={`mailto:${config.contacts.supportEmail}`}
          size={"s"}
          buttonType="secondary"
          isFull
        >
          Напишите нам
        </Button>
        {(config.contacts.contactPhone || config.contacts.sellersPhone) && (
          <div className="footer__contacts-group">
            <div className="footer__contacts-block">
              <a href="tel:+78123636079" className="link">
                +7 (812) 363-60-79
              </a>
              <span className="footer__contacts-sign">Контактный центр</span>
            </div>

            <div className="footer__contacts-block">
              <a href="tel:+78122109435" className="link">
                +7 (812) 210-94-35
              </a>
              <span className="footer__contacts-sign">Отдел продаж</span>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default FooterTop;
