const PersonalAgreementPage = () => {
  return (
    <section className="home doc-page">
      <h1 className="title caps text-center">
        Согласие на обработку персональных данных
      </h1>
      <div className="text-block">
        <p>
          Я, являясь субъектом персональных данных, действуя своей волей и в
          своем интересе, в соответствии с актуальной версией Политики обработки
          персональных данных, доступной на сайте ООО «ЦТРР» (ОГРН
          1157847001850, ИНН 7801204030) (далее – «Оператор») по адресу:{" "}
          <a
            className="link link_blue"
            href="https://1meter.ru"
            target="_blank"
            rel="noreferrer"
          >
            https://1meter.ru
          </a>{" "}
          (далее – «Политика»), даю Оператору Согласие на обработку моих
          персональных данных в целях, определенных Политикой, в составе:
          фамилия, имя, отчество (при наличии); дата рождения; номер мобильного
          телефона; электронная почта.
        </p>
        <p>
          Настоящим предоставляю Оператору согласие на передачу моих
          персональных данных Пользователям Программы, с которыми будут
          заключаться и исполняться договоры, стороной которых либо
          выгодоприобретателем, по которым буду являться или являюсь я, в
          составе: фамилия, имя, отчество (при наличии).
        </p>
        <p>
          Настоящее согласие действует бессрочно и может быть отозвано мной
          путем направления соответствующего письменного уведомления Оператору.
        </p>
      </div>
    </section>
  );
};

export default PersonalAgreementPage;
