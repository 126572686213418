import {useEffect, useState} from "react";
import {orderStatusLabelMap} from "../../../../admin/components/AdminTable/AdminTableFormaters";

function useShowLabelStatus(financialTransactions, user) {
    const [labelStatus, setLabelStatus] = useState("");

    const parseDescription = financialTransactions?.description?.includes(
        "{" || "}",
    )
        ? JSON.parse(financialTransactions?.description)
        : financialTransactions?.description;
    const statusDescription = parseDescription?.status
        ? parseDescription?.status
        : financialTransactions?.description;
    const statusesObjectTypeBurning = {
        CREATED: "BURNING_CREATED",
        CLOSED: "BURNING_CLOSED",
    };

    const statusesObjectTypeTransferOutOrder = {
        CREATED: "TRANSFER_CREATED",
        CLOSED: "TRANSFER_CLOSED",
    };

    const statusesObjectTypeChangeOrder = {
        CREATED: "EXCHANGE_CREATED",
        CLOSED: "EXCHANGE_CLOSED",
    };

    useEffect(() => {
        let isMounted = true;

        if (
            financialTransactions &&
            Object.keys(financialTransactions).length > 0
        ) {
            if (
                financialTransactions.objectType === "INIT_ORDER" &&
                financialTransactions.eventType === "STATUS_CHANGED"
            ) {
                if (orderStatusLabelMap[statusDescription]) {
                    isMounted && setLabelStatus(statusDescription);
                } else {
                    isMounted && setLabelStatus("");
                }
            } else if (
                financialTransactions.objectType === "INIT_ORDER" &&
                financialTransactions.eventType === "PROCESSING_ERROR"
            ) {
                if (user?.roles[0] === "ROLE_ADMIN") {
                    if (statusDescription?.includes("Failed to handle event for order")) {
                        isMounted && setLabelStatus("FAILED_HANDLE_EVENT_ORDER");
                    } else {
                        isMounted && setLabelStatus("");
                    }
                }
            } else if (financialTransactions.objectType === "BUY_ORDER") {
                isMounted && setLabelStatus("BUY_ORDER");
            } else if (financialTransactions.objectType === "SELL_ORDER") {
                isMounted && setLabelStatus("SELL_ORDER");
            } else if (financialTransactions.objectType === "BURNING") {
                if (statusesObjectTypeBurning[statusDescription]) {
                    isMounted &&
                    setLabelStatus(statusesObjectTypeBurning[statusDescription] ?? "");
                }
            } else if (
                financialTransactions.objectType === "TRANSFER_IN_ORDER" ||
                financialTransactions.objectType === "TRANSFER_OUT_ORDER"
            ) {
                if (statusesObjectTypeTransferOutOrder[statusDescription]) {
                    isMounted &&
                    setLabelStatus(
                        statusesObjectTypeTransferOutOrder[statusDescription] ?? "",
                    );
                }
            } else if (financialTransactions.objectType === "EXCHANGE_IN_ORDER" ||
                financialTransactions.objectType === "EXCHANGE_OUT_ORDER") {
                if (statusesObjectTypeChangeOrder[statusDescription]) {
                    isMounted &&
                    setLabelStatus(
                        statusesObjectTypeChangeOrder[statusDescription] ?? "",
                    );
                }
            }
        } else {
            isMounted && setLabelStatus("");
        }

        return () => {
            isMounted = false;
        };
    }, [financialTransactions]);

    return {labelStatus};
}

export default useShowLabelStatus;
