import { useEffect } from "react";

const useEscButton = (onEscButtonPressed) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // Handle Esc key press here
        onEscButtonPressed();
        // You can add your desired functionality here
      }
    };

    // Add event listener for keydown event
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};

export default useEscButton;
