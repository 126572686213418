import classNames from "classnames";
import CloseButton from "../../../ui/CloseButton/CloseButton";
import Nav from "../TopBar/Nav";
import "./MobileMenu.scss";
import BottomNav from "../BottomBar/BottomNav";

const MobileMenu = ({
  menuRef,
  mobileMenuShown = false,
  setMobileMenuShown,
}) => {
  const mobileMenuClasses = classNames({
    "mobile-menu": true,
    shown: mobileMenuShown,
  });
  return (
    <section className={mobileMenuClasses} ref={menuRef}>
      <BottomNav withIcons onClick={() => setMobileMenuShown(false)} />
      <Nav withIcons onClick={() => setMobileMenuShown(false)} />
      {/* <CloseButton
        addClass="mobile-menu__close-btn"
        onClick={() => setMobileMenuShown(false)}
      /> */}
    </section>
  );
};

export default MobileMenu;
