import classNames from "classnames";
import { useState } from "react";
import "./index.scss";

const Tabs = ({
  addClass = null,
  size = "xs",
  underlineView = false,
  onChange = () => {},
  children,
}) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const tabsClasses = classNames({
    "tab-bar": true,
    [`${addClass}`]: addClass,
  });
  const childs = children.filter((v) => !!v);
  return (
    <div className="tabs">
      <div className={tabsClasses}>
        <div className="tab-list">
          {childs.map((child) => {
            const label = child.props.label;
            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                setActiveTab={setActiveTab}
                size={size}
                underlineView={underlineView}
                onChange={onChange}
              />
            );
          })}
        </div>
      </div>

      <div className="tab-content">
        {childs.map((child) => {
          const label = child.props.label;
          return label === activeTab ? child : null;
        })}
      </div>
    </div>
  );
};

const Tab = ({
  label,
  activeTab,
  setActiveTab,
  size,
  underlineView,
  onChange = () => {},
}) => {
  const buttonClasses = classNames({
    "btn tab-btn": true,
    [`btn_size_${size}`]: true,
    "tab-btn_active btn_primary": activeTab === label,
    "btn_secondary-gray": activeTab !== label,
  });

  const underlineClasses = classNames({
    "tab-btn tab-btn_underline medium": true,
    "tab-btn_active": activeTab === label,
  });

  const tabChangeHandler = () => {
    onChange(label);
    setActiveTab(label);
  };

  return !underlineView ? (
    <button type="button" className={buttonClasses} onClick={tabChangeHandler}>
      {label}
    </button>
  ) : (
    <button
      type="button"
      className={underlineClasses}
      onClick={tabChangeHandler}
    >
      {label}
    </button>
  );
};

export default Tabs;
