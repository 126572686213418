import Button from "../../ui/Button/Button";
import DateInput from "../../ui/DateInput/DateInput";
import Input from "../../ui/Input/Input";
import "./RegisterForm.scss";

const RegisterForm = ({errorMessage}) => {
  return (
    <>
      <div className="modal__info text-block">
        <p>
          Заполните короткую анкету ниже — она необходима для корректной работы
          личного кабинета, бронирования и оформления договора и заказа услуг.
        </p>
      </div>
      <div className="form">
        <div className="form__row">
          <div className="form__item">
            <Input
              name={"lastName"}
              withLabel="Фамилия"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              required
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            <Input
              name={"firstName"}
              withLabel="Имя"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              required
            />
          </div>
          <div className="form__item">
            <Input
              name={"middleName"}
              withLabel="Отчество"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              required
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            <DateInput
              name={"birthDay"}
              withLabel="Дата рождения"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder="25.06.1992"
            />
          </div>
          <div className="form__item">
            <Input
              name="email"
              withLabel="Email"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              required
            />
          </div>
        </div>
        <div className="form__row">
          <div className="form__submit">
            <Button type="submit">Войти в личный кабинет</Button>
          </div>
        </div>
        <div className="form__row" style={{marginTop: '10px'}}>
          {errorMessage && <span className="input-error" style={{textAlign: "center"}}>{errorMessage}</span>}
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
