import CloseButton from "../../../components/ui/CloseButton/CloseButton";
import React from "react";

const FlatGallery = (props) => {

    const {
        planImg,
        disabled,
        onButtonClickDeleteImage
    } = props;

    return (
        <>
            <p className="input-wrapper__label body-smaller">
                Загруженный план
            </p>
            <div style={{display: "flex", flexWrap: 'wrap', gap: '20px', justifyContent: 'stretch'}}>
                {planImg.map((el, i) => {
                    return (
                        <div className="upload-field__preview-box" key={i}>
                            <img
                                src={el}
                                width={200}
                                alt=""
                                style={{marginBottom: 20}}
                            />
                            {!disabled &&<CloseButton
                                isclean
                                addClass="upload-field__remove-btn"
                                onClick={() => onButtonClickDeleteImage(i)}
                            />}
                        </div>
                    )
                })}
            </div>
        </>
    )
}
export default FlatGallery;