import { useEffect, useState } from "react";
import Button from "../../../../components/ui/Button/Button";
import "./EditForm.scss";
import { FlatDescription } from "../../../../components/forms/PolymorhicForm/FlatDescription";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  addFiles,
  addImagesToFlatById,
  deleteImagesToFlatById,
  editFlat,
} from "../../../../api";
import { getFilesFormData } from "../../../../utils";

/**
 * @param {{
 *  id : string,
 *  buildingId : string,
 *  defaultValues : import("../../../../components/forms/PolymorhicForm/FlatDescription").DefaultFlatFormValues | undefined
 * }}
 * @returns {import("react").ReactNode}
 */
export function FlatEditForm({ id, flat, buildingId, defaultValues, planImg }) {
  const [editMode, setEditMode] = useState(false);

  const formHook = useForm({ defaultValues });

  const queryClient = useQueryClient();
  const addImageMutation = useMutation({ mutationFn: addImagesToFlatById });
  const deleteImageMutation = useMutation(deleteImagesToFlatById, {
    onSuccess: () => {
      queryClient.invalidateQueries(["building", "buildings"]);
      queryClient.invalidateQueries(["flat"]);
      setEditMode(!editMode);
    },
  });
  const addFilesMutation = useMutation({ mutationFn: addFiles });
  const editFlatMutation = useMutation(editFlat, {
    onSuccess: () => {
      queryClient.invalidateQueries(["building", "buildings"]);
      queryClient.invalidateQueries(["flat"]);
    },
  });

  const onButtonClickDeleteImage = (image) => {
    deleteImageMutation.mutate({ id: id, imageId: flat.imagesIds[image] });
  };

  useEffect(() => {
    formHook.reset((defaultValues) => ({
      ...defaultValues,
      blockNumber: flat.blockNumber,
      entranceNumber: flat.entranceNumber,
      floor: flat.floor,
      number: flat.number,
      footage: flat.footage,
      roomsCount: flat.roomsCount,
      externalLink: flat.externalLink,
      withoutBookingStatus: flat.bookingStatus === null,
      bookingStatus: flat.bookingStatus,
    }));
  }, [flat, formHook]);

  const onSubmit = (data) => {
    if (data.photos && data.photos.length > 0) {
      const promisesFilesGroup = data.photos.map(async (file) => {
        return await addFilesMutation.mutateAsync(getFilesFormData([file]));
      });
      Promise.all(promisesFilesGroup)
        .then((fileResp) => {
          const promisesImageGroup = fileResp.map(async (image) => {
            return await addImageMutation.mutateAsync({
              id: id,
              imageId: image,
            });
          });
          Promise.all(promisesImageGroup).then((fileResp) => {
            editFlatMutation.mutate({
              id,
              body: {
                ...data,
                buildingId,
                bookingStatus: data.withoutBookingStatus
                  ? null
                  : data.bookingStatus,
              },
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      editFlatMutation.mutate({
        id,
        body: {
          ...data,
          buildingId,
          bookingStatus: data.withoutBookingStatus ? null : data.bookingStatus,
        },
      });
    }
  };

  return (
    <>
      <FormProvider {...formHook}>
        <form
          className="form edit-form"
          onSubmit={formHook.handleSubmit((data) => {
            if (!editMode) {
              onSubmit(data);
            }
          })}
        >
          <p className="h8 form__title">Описание</p>
          <FlatDescription
            defaultValue={defaultValues}
            control={formHook.control}
            disabled={!editMode}
            onButtonClickDeleteImage={onButtonClickDeleteImage}
            planImg={planImg}
            setValue={formHook.setValue}
          />
          <div className="form__row">
            <div className="form__edit">
              <Button
                type="submit"
                buttonType={editMode ? "primary" : "secondary"}
                size={"m"}
                onClick={() => setEditMode((prev) => !prev)}
                addClass="form__edit-btn"
              >
                {editMode ? "Сохранить" : "Редактировать"}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
