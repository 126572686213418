const AgreementPage = () => {
  return (
    <section className="home doc-page">
      <h1 className="title text-center">Пользовательское соглашение</h1>
      <div className="text-block">
        <h2 className="h5 text-center">1. Общие положения</h2>
        <div className="list-item">
          <span className="list-item__counter">1.1.</span>
          <div className="list-item__content">
            <p>
              Настоящее Пользовательское соглашение (далее – <b>Соглашение</b>)
              относится к сайту «1meter.ru», расположенному на доменном имени{" "}
              <a
                href="https://1meter.ru/"
                className="link link_blue"
                target="_blank"
                rel="noreferrer"
              >
                https://1meter.ru/
              </a>{" "}
              (далее – <b>Сайт</b>), является публичной офертой и определяет
              условия использования пользователями Сайта материалов и сервисов,
              размещенных на Сайте.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.2.</span>
          <div className="list-item__content">
            <p>
              Сайт является собственностью ООО «ЦТРР» (ОГРН 1157847001850, ИНН
              7801204030) (далее – <b>Администрация сайта</b>).
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.3.</span>
          <div className="list-item__content">
            <p>
              Настоящее Соглашение регулирует отношения между Администрацией
              сайта и лицом, имеющим доступ к Сайту посредством сети Интернет и
              использующим Сайт (далее – <b>Пользователь</b>).
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.4.</span>
          <div className="list-item__content">
            <p>
              Использование Сайта Пользователем означает, что Пользователь
              принимает условия Соглашения и Политики обработки персональных
              данных, являющейся неотъемлемой частью настоящего Соглашения и
              размещенной на странице по адресу:{" "}
              <a
                href="https://1meter.ru/"
                className="link link_blue"
                target="_blank"
                rel="noreferrer"
              >
                https://1meter.ru/
              </a>{" "}
              .
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.5.</span>
          <div className="list-item__content">
            <p>
              Пользователь несет персональную ответственность за проверку
              настоящего Соглашения в том числе на предмет соответствия своим
              интересам и наличия изменений в Соглашении.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">1.6.</span>
          <div className="list-item__content">
            <p>
              Условия Соглашения регулируются нормами действующего
              законодательства Российской Федерации.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">2. Предмет соглашения</h2>
        <div className="list-item">
          <span className="list-item__counter">2.1.</span>
          <div className="list-item__content">
            <p>
              Предметом настоящего Соглашения является предоставление
              Пользователю на безвозмездной основе простой (неисключительной)
              лицензии на использование Сайта и размещенного на Сайте содержания
              на условиях, изложенных в настоящем Соглашении, а также
              предоставление доступа к сервисам Сайта.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">2.2.</span>
          <div className="list-item__content">
            <p>
              Содержание Сайта (далее – <b>Содержание Сайта</b>) – все
              материалы, размещенные на Сайте, включая охраняемые результаты
              интеллектуальной деятельности: тексты литературных произведений,
              их названия, предисловия, аннотации, статьи, иллюстрации, обложки,
              музыкальные произведения с текстом или без текста, графические,
              текстовые, фотографические, производные, составные и иные
              произведения, пользовательские интерфейсы, визуальные интерфейсы,
              названия товарных знаков, логотипы, а также дизайн, структура,
              выбор, координация, внешний вид, общий стиль и расположение
              данного Содержания, входящего в состав Сайта и другие объекты
              интеллектуальной собственности все вместе и/или по отдельности,
              содержащиеся на Сайте.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">2.3.</span>
          <div className="list-item__content">
            <p>
              Сервисы, предоставляемые Пользователю посредством Сайта, включают:
            </p>
            <p>- поиск и навигация по Cайту;</p>
            <p>- заполнение регистрационных форм.</p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">2.4.</span>
          <div className="list-item__content">
            <p>
              Под действие настоящего Соглашения подпадают все материалы,
              размещенные на Сайте, а также существующие (реально
              функционирующие) на данный момент сервисы Сайта, любые их
              последующие модификации и появляющиеся в дальнейшем дополнительные
              услуги и сервисы.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">3. Права и обязанности сторон</h2>
        <div className="list-item">
          <span className="list-item__counter">3.1.</span>
          <div className="list-item__content">
            <p>
              <b>Администрация сайта вправе:</b>
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.1.1.</span>
          <div className="list-item__content">
            <p>
              обновлять Соглашение в одностороннем порядке и без уведомления
              Пользователя. Изменения Соглашения вступают в силу с момента их
              опубликования на Сайте.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.1.2.</span>
          <div className="list-item__content">
            <p>
              в любой момент и без уведомления Пользователя изменять Содержание
              Сайта, в том числе вносить изменения в перечень и (или) описание
              товаров и услуг, цифровых финансовых активов, информация о которых
              может быть размещена на Сайте, и (или) их стоимости (при наличии);
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.1.3.</span>
          <div className="list-item__content">
            <p>
              не проверять содержание любой информации, передаваемой
              Пользователем через Сайт.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.2.</span>
          <div className="list-item__content">
            <p>
              <b>Администрация сайта обязуется:</b>
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.2.1.</span>
          <div className="list-item__content">
            <p>
              круглосуточно обеспечивать доступность Сайта за исключением
              времени проведения профилактических работ;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.2.2.</span>
          <div className="list-item__content">
            <p>
              не осуществлять любые действия, которые могут привести к
              невозможности использования Сайта Пользователем, за исключением
              проведения профилактических работ.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.3.</span>
          <div className="list-item__content">
            <p>
              <b>Пользователь вправе:</b>
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.3.1.</span>
          <div className="list-item__content">
            <p>
              использовать Сайт, Содержание Сайта в определенных Соглашением
              пределах;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.3.2.</span>
          <div className="list-item__content">
            <p>
              круглосуточно получать доступ к Сайту за исключением времени
              проведения профилактических работ;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.3.3.</span>
          <div className="list-item__content">
            <p>пользоваться имеющимися на Сайте сервисами;</p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.3.4.</span>
          <div className="list-item__content">
            <p>
              пользоваться Сайтом исключительно в целях и порядке,
              предусмотренных Соглашением и не запрещенных законодательством
              Российской Федерации.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.4.</span>
          <div className="list-item__content">
            <p>
              <b>Пользователь обязуется:</b>
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.4.1.</span>
          <div className="list-item__content">
            <p>
              соблюдать имущественные и неимущественные права авторов и иных
              правообладателей при использовании Сайта;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.4.2.</span>
          <div className="list-item__content">
            <p>
              не копировать, не воспроизводить, повторно не публиковать, не
              совершать сделок с Содержанием Сайта, если иное не предусмотрено
              настоящим Соглашением, а также не использовать Сайт для участия в
              деятельности, аналогичной деятельности Администрации сайта.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.4.3.</span>
          <div className="list-item__content">
            <p>
              не предпринимать действий, которые могут рассматриваться как
              нарушающие нормальную работу Сайта;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.4.4.</span>
          <div className="list-item__content">
            <p>
              не распространять с использованием Сайта любую конфиденциальную и
              охраняемую законодательством Российской Федерации информацию, а
              также избегать любых действий, в результате которых такая
              конфиденциальность может быть нарушена;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.4.5.</span>
          <div className="list-item__content">
            <p>
              не использовать Сайт с целью:
              <br />- нарушения прав третьих лиц и (или) причинения им вреда в
              любой форме;
              <br />- представления себя за другое лицо или его представителя
              без достаточных на то полномочий;
              <br />- загрузки контента, который является незаконным, нарушает
              любые права третьих лиц, пропагандирует насилие, жестокость,
              ненависть и (или) дискриминацию по расовому, национальному,
              половому, религиозному, социальному признакам, содержит
              недостоверные сведения и (или) оскорбления в адрес конкретных лиц,
              организаций, органов власти;
              <br />- побуждения к совершению противоправных действий, а также
              содействия лицам, действия которых направлены на нарушение
              ограничений и запретов, действующих на территории Российской
              Федерации.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.4.6.</span>
          <div className="list-item__content">
            <p>
              обеспечить достоверность предоставляемой Пользователем информации.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.5.</span>
          <div className="list-item__content">
            <p>
              <b>Пользователю запрещается:</b>
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.5.1.</span>
          <div className="list-item__content">
            <p>
              использовать любые устройства, программы, процедуры, алгоритмы и
              методы, автоматические устройства или эквивалентные ручные
              процессы для приобретения, копирования или отслеживания Содержания
              Сайта;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.5.2.</span>
          <div className="list-item__content">
            <p>нарушать надлежащее функционирование Сайта;</p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.5.3.</span>
          <div className="list-item__content">
            <p>
              любым способом обходить навигационную структуру Сайта для
              получения или попытки получения любой информации, документов или
              материалов любыми средствами, которые специально не представлены
              сервисами Сайта;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.5.4.</span>
          <div className="list-item__content">
            <p>
              несанкционированный доступ к функциям Сайта, любым другим системам
              или сетям, относящимся к данному Сайту, а также к любым услугам,
              предлагаемым на Сайте;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.5.5.</span>
          <div className="list-item__content">
            <p>
              выполнять обратный поиск, отслеживать или пытаться отслеживать
              любую информацию о любом другом Пользователе Сайта;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.5.6.</span>
          <div className="list-item__content">
            <p>
              использовать Сайт и его Содержание в любых целях, запрещенных
              законодательством Российской Федерации, а также подстрекать к
              любой незаконной деятельности или другой деятельности, нарушающей
              права третьих лиц.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">4. Использование Сайта</h2>
        <div className="list-item">
          <span className="list-item__counter">4.1.</span>
          <div className="list-item__content">
            <p>
              Сайт и Содержание сайта принадлежат Администрации сайта и
              управляются ею.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.2.</span>
          <div className="list-item__content">
            <p>
              Содержание Сайта допускается использовать, копировать, цитировать
              исключительно в информационных, научных, учебных или культурных
              целях с соблюдением соответствующих положений действующего
              законодательства с обязательным указанием имени автора и/или
              правообладателя, а также источника заимствования. На копиях
              материалов, заимствованных на Сайте, должен быть сохранен знак
              авторского права ©, зарегистрированных товарных знаков ®,
              которые запрещается удалять. При цитировании материалов Сайта,
              включая охраняемые авторские произведения, ссылка на Сайт
              обязательна.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.3.</span>
          <div className="list-item__content">
            <p>
              Использование Содержания Сайта в иных целях, чем указанные в
              пункте 4.2. Соглашения, без согласия правообладателей не
              допускается.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.4.</span>
          <div className="list-item__content">
            <p>
              Содержание Сайта не может прямо или косвенно использоваться для
              значительного по масштабам или систематического копирования
              (копирования программами-роботами), воспроизведения,
              систематического снабжения или распространения в любой форме
              любому лицу без предварительного письменного разрешения
              Администрации сайта. Пользователям не разрешается изменять,
              распространять, публиковать Содержание Сайта для коммерческих
              целей. Ответственность за нарушение авторских и исключительных
              прав правообладателей несет Пользователь.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">4.5.</span>
          <div className="list-item__content">
            <p>
              Товарные знаки, коммерческие обозначения, дизайн, отображение и
              форматирование, а также иные элементы Сайта, являются
              интеллектуальной собственностью Администрации сайта и их полное
              или частичное копирование либо имитация на других сетевых ресурсах
              запрещаются.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">4.6.</span>
          <div className="list-item__content">
            <p>
              4.6. При использовании Сайта может потребоваться заполнение
              регистрационной формы Пользователя на Сайте. Для регистрации
              Пользователю необходимо выполнить следующие действия:
              <br /> -заполнить регистрационную форму в разделе «регистрация» /
              на странице{" "}
              <a
                href="https://1meter.ru/"
                className="link link_blue"
                target="_blank"
                rel="noreferrer"
              >
                https://1meter.ru/
              </a>
              ;
              <br /> -[Перейти в раздел «регистрация», далее ввести номер
              мобильного телефона, ввести полученный СМС-код, после чего
              заполнить Фамилию Имя Отчество, дату рождения, email, нажать
              «войти в личный кабинет»]
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">4.7.</span>
          <div className="list-item__content">
            <p>
              Пользователь несет персональную ответственность за сохранение
              конфиденциальности информации, предоставленной при заполнении
              регистрационной формы на Сайте, а также за всю без исключения
              деятельность, которая ведётся от имени Пользователя на Сайте.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">4.8.</span>
          <div className="list-item__content">
            <p>
              Пользователь должен незамедлительно уведомить Администрацию сайта
              о несанкционированном использовании его данных, предоставленных
              при заполнении регистрационной формы на Сайте.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">5. Ответственность</h2>
        <div className="list-item">
          <span className="list-item__counter">5.1.</span>
          <div className="list-item__content">
            <p>
              Любые убытки, которые Пользователь может понести в случае
              нарушения любого положения настоящего Соглашения, а также
              вследствие несанкционированного доступа к любым данным другого
              Пользователя, Администрацией сайта не возмещаются.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">5.2.</span>
          <div className="list-item__content">
            <p>
              <b>Администрация сайта не несет ответственности за:</b>
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">5.2.1.</span>
          <div className="list-item__content">
            <p>
              задержки или сбои в процессе совершения операции, возникшие
              вследствие непреодолимой силы, а также любого случая неполадок в
              телекоммуникационных, компьютерных, электрических и иных смежных
              системах;
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">5.2.2.</span>
          <div className="list-item__content">
            <p>
              надлежащее функционирование Сайта в случае, если Пользователь не
              имеет необходимых технических средств для его использования;
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">5.2.3.</span>
          <div className="list-item__content">
            <p>
              посещение и использование Пользователем внешних ресурсов, ссылки
              на которые могут содержаться на Сайте;
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">5.2.4.</span>
          <div className="list-item__content">
            <p>
              последствия в связи с любыми возможными или возникшими потерями
              или убытками, связанными с любым содержанием Сайта, регистрацией
              авторских прав и сведениями о такой регистрации, товарами или
              услугами, доступными на или полученными через внешние сайты или
              ресурсы либо иные контакты Пользователя, в которые он вступил,
              используя размещенную на Сайте информацию или ссылки на внешние
              ресурсы.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">
          6. Нарушение условий пользовательского соглашения
        </h2>
        <div className="list-item">
          <span className="list-item__counter">6.1.</span>
          <div className="list-item__content">
            <p>
              Администрация сайта вправе без предварительного уведомления
              Пользователя прекратить и (или) заблокировать доступ к Сайту, если
              Пользователь нарушил настоящее Соглашение или содержащиеся в иных
              документах условия пользования Сайтом, а также в случае
              прекращения действия Сайта либо по причине технической неполадки
              или проблемы.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">6.2.</span>
          <div className="list-item__content">
            <p>
              Администрация сайта не несет ответственности перед Пользователем
              или третьими лицами за прекращение доступа к Сайту в случае
              нарушения Пользователем любого положения настоящего Соглашения или
              иного документа, содержащего условия пользования Сайтом.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">6.3.</span>
          <div className="list-item__content">
            <p>
              Администрация сайта вправе раскрыть информацию о Пользователе
              третьим лицам по основаниям, предусмотренным действующим
              законодательством Российской Федерации.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">7. Разрешение споров</h2>
        <div className="list-item">
          <span className="list-item__counter">7.1.</span>
          <div className="list-item__content">
            <p>
              В случае возникновения любых разногласий или споров между
              сторонами настоящего Соглашения обязательным условием до обращения
              в суд является предъявление претензии (письменного предложения о
              добровольном урегулировании спора).
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">7.2.</span>
          <div className="list-item__content">
            <p>
              Получатель претензии в течение 30 (тридцати) календарных дней со
              дня ее получения письменно уведомляет заявителя претензии о
              результатах рассмотрения претензии.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">7.3.</span>
          <div className="list-item__content">
            <p>
              Споры и разногласия между сторонами, которые не были урегулированы
              в досудебном порядке, подлежат разрешению в судебном порядке.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgreementPage;
