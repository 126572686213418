import {
  formatDateFrom,
  formatId,
  getFullAdress,
  getFullName,
} from "../../../utils";
import { orderStatusLabelMap } from "../AdminTable/AdminTableFormaters";
import RoundedBlock from "../../../components/ui/RoundedBlock/RoundedBlock";
import { Link } from "react-router-dom";
import {
  InfoKey,
  InfoPair,
  InfoValue,
} from "../../../components/ui/InfoPair/InfoPair";
import Button from "../../../components/ui/Button/Button";
import ProgressLabel from "../../../components/ui/Label/ProgressLabel";
const OrderInfo = ({ order }) => {
  if (!order) return null;
  const { status, owner, issuer, id, idOis, release } = order;

  const { emitter, building, pool } = release;

  return (
    <div className="flexbox flexbox_direction-column edit-form">
      <p className="h8 form__title">Информация</p>
      <RoundedBlock>
        {[
          [
            "Статус сделки",
            <ProgressLabel status={status}>
              {orderStatusLabelMap[status]}
            </ProgressLabel>,
          ],
          [
            "Покупатель",
            <Link
              className="link link_blue"
              target="_blank"
              to={`../users/${owner.id}`}
            >
              {getFullName(owner)}
            </Link>,
          ],
          ["ID покупателя", formatId(issuer.id)],
          ["ID заявки", formatId(id)],
          ["ID заявки в ОИС", formatId(idOis)],
        ].map(([key, value], ind) => (
          <InfoPair key={`${value}-${ind}`}>
            <InfoKey>{key}</InfoKey>
            <InfoValue>{value}</InfoValue>
          </InfoPair>
        ))}
      </RoundedBlock>

      <RoundedBlock>
        {[
          [
            "Эмитент",
            <Link
              className="link link_blue"
              target="_blank"
              to={`../emitters/${emitter.id}`}
            >
              {emitter.name || formatId(emitter.id)}
            </Link>,
          ],
          ["ID эмитента", formatId(emitter.id)],
          [
            "Застройщик",
            <Link
              className="link link_blue"
              target="_blank"
              to={`../developers/${building.developer.id}`}
            >
              {building.developer.name}
            </Link>,
          ],
          [
            "Жилой комплекс",
            <Link
              className="link link_blue"
              target="_blank"
              to={`../buildings/${building.id}`}
            >
              {building.name}
            </Link>,
          ],
          ["ID ЖК", formatId(building.id)],
          ["Адрес ЖК", getFullAdress(building)],
          ["Общий метраж пула", release.totalFootage],
          [
            "Дата начала выпуска",
            formatDateFrom(release.plannedStartDate, true),
          ],
          [
            "Дата завершения выпуска",
            formatDateFrom(release.plannedEndDate, true),
          ],
          [
            "Наименование выпуска",
            <Link
              className="link link_blue"
              target="_blank"
              to={`../releases/${release.id}`}
            >
              {pool.name}
            </Link>,
          ],
          ["ID выпуска", formatId(release.id)],
          ["ID пула", formatId(pool.id)],
        ].map(([key, value], ind) => (
          <InfoPair key={`${value}-${ind}`}>
            <InfoKey>{key}</InfoKey>
            <InfoValue>{value}</InfoValue>
          </InfoPair>
        ))}
      </RoundedBlock>

      <Button isLink={`../../${pool.id}`} target="_blank">
        К странице пула на сайте
      </Button>
    </div>
  );
};
export default OrderInfo;
