import AdminTable from "../AdminTable/AdminTable";
import SearchInput from "../SearchInput/SearchInput";
import AddBtn from "../AddButton";
import Flexbox from "../../../components/ui/Flexbox/Flexbox";
import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import AddDeveloperModal from "../AddModal/AddDeveloperModal";
import { useQuery } from "react-query";
import { getAllDevelopers } from "../../../api";
import { Loader } from "../../../components/ui/Loader/Loader";
import { formatId, getFullName } from "../../../utils";
const Developers = () => {
  const { modalIsOpen, openModal, closeModal } = useModal();

  const { data: developers, isLoading } = useQuery({
    queryKey: ["developers"],
    queryFn: getAllDevelopers,
  });

  const getDevelopersData = () => {
    if (!developers) return null;

    return {
      Застройщик: developers.map((it) => ({
        name: it.name,
        link: `../developers/${it.id}`,
      })),
      "ID Застройщика": developers.map((it) => formatId(it.id)),

      Пользователь: developers.map((it) => ({
        name: getFullName(it.user),
        link: `../users/${it.user.id}`,
      })),

      "ID ЦМ пользователя": developers.map((it) => formatId(it.user?.id)),
    };
  };

  if (isLoading) {
    return <Loader isLarge isBlue />;
  }

  return (
    <>
      {developers && developers.length > 0 && <SearchInput />}

      <Flexbox flexDirection="column" fitContent>
        {developers && developers.length > 0 ? (
          <AdminTable data={getDevelopersData()} />
        ) : (
          <p style={{ marginTop: 15 }}>Нет застройщиков</p>
        )}

        <AddBtn onClick={openModal}>Добавить застройщика</AddBtn>
      </Flexbox>

      <Modal
        isOpen={modalIsOpen}
        //Пока закомментировал, закрытие модалки при клике вне её области, по просьбе Андрея
        // onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <AddDeveloperModal closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default Developers;
