import Switcher from "../../../ui/Switcher/Switcher";
import "./Notifications.scss";
import NotificationCard from "../../../ui/NotificationCard/NotificationCard";
import useNotifications from "../../../../hooks/notifications/useNotifications";
import Tabs from "../../../ui/Tabs/Tabs";
import {useEffect, useState} from "react";
import {Loader} from "../../../ui/Loader/Loader";
import SettingsButtons from "../../../ui/NotificationCard/SettingsButtons";
import {useCountNewNotifications} from "../../../../store";

const Notifications = () => {
    const [paramsRequest, setParamsRequest] = useState({
        page: 0,
        size: 10,
        objectType: false,
    });
    const setCountNewNotifications = useCountNewNotifications(
        (state) => state.setCountNewNotifications,
    );
    const {data: notifications, isLoading: isLoading} = useNotifications(
        paramsRequest,
        false,
    );
    const [notificationsForRender, setNotificationsForRender] = useState([]);
    const totalElementsNotifications = notifications?.totalElements;

    const labelTabs = [
        {
            label: "Все",
        },
        {
            label: "Операции",
        },
        {
            label: "Выпуск новых токенов",
        },
        {
            label: "Жилые комплексы",
        },
    ];

    const onClickGetAllNotifications = () => {
        setParamsRequest({...paramsRequest, size: totalElementsNotifications});
    };

    const onClickGetActiveTab = (value) => {
        if (notifications) {
            switch (value) {
                case "Все":
                    setParamsRequest({
                        page: 0,
                        size: 10,
                        objectType: false,
                    });
                    break;
                case "Операции":
                    setParamsRequest({
                        page: 0,
                        size: 10,
                        objectType: [
                            "INIT_ORDER",
                            "SELL_ORDER",
                            "BUY_ORDER",
                            "BURNING",
                            "TRANSFER_IN_ORDER",
                            "TRANSFER_OUT_ORDER",
                            "EXCHANGE_IN_ORDER",
                            "EXCHANGE_OUT_ORDER"
                        ],
                    });
                    break;
                case "Выпуск новых токенов":
                    setParamsRequest({
                        page: 0,
                        size: 10,
                        objectType: ["RELEASE"],
                    });
                    break;
                case "Жилые комплексы":
                    setParamsRequest({
                        page: 0,
                        size: 10,
                        objectType: ["BUILDING"],
                    });
                    break;
                default:
                    setParamsRequest({
                        page: 0,
                        size: 10,
                        objectType: false,
                    });
            }
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (notifications && notifications?.content?.length > 0) {
            isMounted && setNotificationsForRender(notifications?.content);
        } else {
            isMounted && setNotificationsForRender([]);
        }
        isMounted && setCountNewNotifications(null);
        return () => {
            isMounted = false;
        };
    }, [notifications]);

    return (
        <section className="notifications__group">
            <Tabs
                underlineView
                size="14"
                addClass="admin-user__tabs"
                onChange={(label) => {
                    onClickGetActiveTab(label);
                }}
            >
                {labelTabs.map((label, index) => (
                    <div className="notifications__group" key={index} label={label.label}>
                        {isLoading ? (
                            <Loader isBlue isLarge/>
                        ) : (
                            <>
                                {notificationsForRender && notificationsForRender.length > 0 ? (
                                    notificationsForRender.map((content) => {
                                        return (
                                            <NotificationCard
                                                key={content.id}
                                                isMiniCard={false}
                                                {...content}
                                            />
                                        );
                                    })
                                ) : (
                                    <p>Уведомления не найдены!</p>
                                )}
                            </>
                        )}
                        {notificationsForRender &&
                            totalElementsNotifications > 10 &&
                            totalElementsNotifications !== paramsRequest.size && (
                                <SettingsButtons
                                    // nameBtnOne='Настройки'
                                    nameBtnTwo="Показать все"
                                    onClickBtnTwo={onClickGetAllNotifications}
                                    sizeBtn="m"
                                    style={{marginTop: "0", maxWidth: "660px"}}
                                />
                            )}
                    </div>
                ))}
            </Tabs>
            {/*<div className="notifications__group">*/}
            {/*  <div className="notifications__block notifications__block_master">*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать все уведомления</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="notifications__block">*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать уведомления о выпуске новых токенов от ЖК</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать уведомления об изменении цены токена от ЖК</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать уведомления о статусах ЖК</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className="notifications__group">*/}
            {/*  <div className="notifications__block notifications__block_master">*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать все уведомления</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="notifications__block">*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать уведомления о выпуске новых токенов от ЖК</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать уведомления об изменении цены токена от ЖК</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать уведомления о статусах ЖК</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="notifications__group">*/}
            {/*  <div className="notifications__block notifications__block_master">*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать все уведомления</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="notifications__block">*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать уведомления о выпуске новых токенов от ЖК</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать уведомления об изменении цены токена от ЖК</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*    <div className="notifications__row">*/}
            {/*      <span>Получать уведомления о статусах ЖК</span>*/}
            {/*      <Switcher />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
        </section>
    );
};

export default Notifications;
