import "../../components/Order/Order.scss";

const OrderPrice = ({ order }) => {
  const { priceForOne, price, amount } = order;

  return (
    <div className="order-price">
      <div className="order-price__block">
        <p>Цена ЦФА, ₽</p>
        <p>{priceForOne}</p>
      </div>
      <div className="order-price__block">
        <p>Количество ЦФА</p>
        <p>{amount}</p>
      </div>
      <div className="order-price__block">
        <p>Стоимость сделки, ₽ </p>
        <p>{price}</p>
      </div>
    </div>
  );
};
export default OrderPrice;
