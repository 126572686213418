export const config = {
  api_url: "https://meter.dltc.spbu.ru/api",
  dev_mode: false,
  certificatesCloudStorage: true,
  topBarEnabled: false,
  contacts: {
    contactPhone: null,
    sellersPhone: null,
    supportEmail: "support@1meter.ru",
    forDevelopersEmail: "partner@1meter.ru",
    forProposalsEmail: "info@1meter.ru ",
  },
  purchaseMinPrice: 50000,
  purchaseMaxPrice: 600000000000000, // не задано
};
