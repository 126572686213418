import TableNewTokens from "./TableNewTokens";
import StatusTable from "../../../ui/Table/StatusTable";
import Tabs from "../../../ui/Tabs/Tabs";
import ShowmoreBtn from "../../ShowmoreBtn/ShowmoreBtn";
import "./Notifications.scss";
import TableHistory from "./TableHistory";
import Box from "../../../ui/Box/Box";
import TableOperations from "./TableOperations";

const Notifications = () => {
  return (
    <section className="notifications">
      <Tabs underlineView size={18} addClass="notifications__tabs">
        <div
          label="Уведомления об операциях"
          className="notifications__operations"
        >
          <TableOperations />
          <ShowmoreBtn />
        </div>
        <div label="Уведомления о ЖК" className="notifications__jk">
          <Tabs addClass="notifications__jk-tabs" size={"s"}>
            <Box label="История цены ЦФА от ЖК" width={544}>
              <TableHistory noGap />
              <ShowmoreBtn />
            </Box>
            <Box label="Выпуск новых ЦФА от ЖК" width={720}>
              <TableNewTokens noGap />
              <ShowmoreBtn />
            </Box>
            <Box label="Статусы ЖК" width={931}>
              <StatusTable withLabels />
              <ShowmoreBtn />
            </Box>
          </Tabs>
        </div>
      </Tabs>
    </section>
  );
};

export default Notifications;
