import "./Welcome.scss";
import WelcomeImg from "../../../assets/img/welcome.png";
import WelcomeImg2 from "../../../assets/img/welcome-2.png";
import AdvIcon1 from "../../../assets/img/icons/adv-1.svg";
import AdvIcon2 from "../../../assets/img/icons/adv-2.svg";
import AdvIcon3 from "../../../assets/img/icons/adv-3.svg";
import AdvIcon4 from "../../../assets/img/icons/adv-4.svg";
import AdvIcon5 from "../../../assets/img/icons/adv-5.svg";
import Advantage from "./Advantage";
import { useMemo } from "react";

import Tabs from "../../ui/Tabs/Tabs";
import { useState } from "react";
const Welcome = () => {
  const [state, setState] = useState(0);

  const bgImg = useMemo(() => {
    if (state === 0) {
      return WelcomeImg;
    }
    if (state === 1) {
      return WelcomeImg2;
    }
    if (state === 2) {
      return null;
    }
  }, [state]);

  const advantages = [
    {
      img: AdvIcon5,
      text: "Приобретение цифровых квадратных метров, осуществляется путем приобретения цифровых финансовых активов (ЦФА)",
    },
    {
      img: AdvIcon4,
      text: "100 ЦФА = стоимости одного квадратного метра",
    },
    {
      img: AdvIcon1,
      text: "Выпуск ЦФА производится через зарегистрированного в ЦБ РФ Оператора информационной системы",
      isSided: true,
    },
    {
      img: AdvIcon2,
      text: "Доходность от приобретенных ЦФА гарантирована в установленные сроки",
      isSided: true,
    },
    {
      img: AdvIcon3,
      text: "Доступен вторичный рынок. Купленные ЦФА у застройщиков можно перепродать другим инвесторам",
      isSided: true,
    },
  ];

  return (
    <section className="welcome">
      <div
        className={`welcome__inner-block${
          state === 2 ? " welcome__inner-block_with-side-content" : ""
        }`}
      >
        {bgImg && <img className="welcome__img" src={bgImg} alt="" />}
        <div className="welcome__container">
          <Tabs
            underlineView
            size="14"
            addClass="welcome__tabs"
            onChange={(label) => {
              label === "О проекте" && setState(0);
              label === "Доход" && setState(1);
              label === "Особенности" && setState(2);
            }}
          >
            <div label="О проекте" className="text-block">
              <p>
                <span className="highlighted">Маркетплейс цифровых квадратных метров от застройщиков </span>
              </p>
              <p>
                Наша цель – дать возможность инвестировать в строящиеся новостройки каждому.
              </p>
              <p>
                Приобретение цифровых квадратных метров, осуществляется путем приобретения цифровых финансовых активов (ЦФА).
                ЦФА – это новый финансовый инструмент на базе современных технологий, признаваемый российским законодательством и регулируемый Центральным банком.
              </p>
            </div>
            <div label="Доход">
              Доходность квадратного метра и срок выплаты фиксируется заранее.
              В выпуске ЦФА каждого жилого комплекса устанавливается своя доходность.
            </div>
            <div label="Особенности">
              {advantages.map(
                (adv) => !adv.isSided && <Advantage key={adv.text} {...adv} />
              )}
            </div>
          </Tabs>
        </div>
        {state === 2 && (
          <div className="welcome__side-content">
            {advantages.map(
              (adv) => adv.isSided && <Advantage key={adv.text} {...adv} />
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Welcome;
