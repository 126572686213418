import "./CodeField.scss";
import { useRef } from "react";
import { useFormContext } from "react-hook-form";
import useLogin from "../../../hooks/useLogin";

const CodeField = ({ lastCode, setCodeFilled, closeModal, isLoginAction }) => {
  const inputRef = useRef(null);
  const inputRefTwo = useRef(null);
  const inputRefThree = useRef(null);
  const inputRefFour = useRef(null);

  const { watch } = useFormContext();
  const numberValue = watch("phone");
  const login = useLogin();

  const checkAllFilled = () => {
    const successLogin = () => {
      login.mutate(
        {
          login: numberValue.replace(/[+\s-]/g, ""),
          code: `${inputRef.current.value}${inputRefTwo.current.value}${inputRefThree.current.value}${inputRefFour.current.value}`,
          type: "PHONE_CODE",
        },
        {
          onSuccess: closeModal,
        }
      );
    };
    if (
      inputRef.current.value >= 0 &&
      inputRefTwo.current.value >= 0 &&
      inputRefThree.current.value >= 0 &&
      inputRefFour.current.value >= 0
    ) {
      isLoginAction ? successLogin() : setCodeFilled(true);

      localStorage.setItem(
        "code",
        `${inputRef.current.value}${inputRefTwo.current.value}${inputRefThree.current.value}${inputRefFour.current.value}`
      );
    }
  };

  const fillFields = (string) => {
    if (string) {
      inputRef.current.value = string[0] || "";
      inputRefTwo.current.value = string[1] || "";
      inputRefThree.current.value = string[2] || "";
      inputRefFour.current.value = string[3] || "";
    }

    checkAllFilled();
  };

  return (
    <div className="code-field">
      <p className="code-field__code-mobile h4">
        Ваш код: <span>{lastCode}</span>
      </p>
      <span className={"code-field__label"}>Введите код из СМС</span>
      <div className="code-field__wrapper">
        <input
          type="text"
          className="input bold input_lg"
          ref={inputRef}
          placeholder={0}
          autoFocus
          onChange={() => {
            if (inputRef.current.value.length > 1) {
              fillFields(inputRef.current.value);
            }
            inputRef.current.value = inputRef.current.value.replace(
              /[^0-9]/g,
              ""
            );
            inputRefTwo.current.focus();
          }}
          //onChange={() => replacer(inputRef.current)}
        />
        <input
          type="text"
          className="input bold input_lg"
          ref={inputRefTwo}
          placeholder={0}
          maxLength="1"
          onChange={() => {
            if (inputRefTwo.current.value.length > 1) {
              fillFields(inputRefTwo.current.value);
            }
            inputRefTwo.current.value = inputRefTwo.current.value.replace(
              /[^0-9]/g,
              ""
            );
            inputRefThree.current.focus();
          }}
          onFocus={() => {
            if (inputRef.current.value.length === 0) inputRef.current.focus();
          }}
          //onChange={() => replacer(inputRef.current)}
        />
        <input
          type="text"
          className="input bold input_lg"
          ref={inputRefThree}
          placeholder={0}
          maxLength="1"
          onChange={() => {
            if (inputRefThree.current.value.length > 1) {
              fillFields(inputRefThree.current.value);
            }

            inputRefThree.current.value = inputRefThree.current.value.replace(
              /[^0-9]/g,
              ""
            );
            inputRefFour.current.focus();
          }}
          onFocus={() => {
            if (inputRefTwo.current.value.length === 0)
              inputRefTwo.current.focus();
          }}
          //onChange={() => replacer(inputRef.current)}
        />
        <input
          type="text"
          className="input bold input_lg"
          ref={inputRefFour}
          placeholder={0}
          maxLength="1"
          onChange={() => {
            if (inputRefFour.current.value.length > 1) {
              fillFields(inputRefFour.current.value);
            }
            inputRefFour.current.value = inputRefFour.current.value.replace(
              /[^0-9]/g,
              ""
            );

            checkAllFilled();
            // рефакторинг
          }}
          onFocus={() => {
            if (inputRefThree.current.value.length === 0)
              inputRefThree.current.focus();
          }}
          //onChange={() => replacer(inputRef.current)}
        />
      </div>
    </div>
  );
};

export default CodeField;
