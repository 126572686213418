import { HistoryItem } from "../../../../components/ui/History/History";
import { formatDateFrom } from "../../../../utils";
export const WaitingOis = ({ order }) => {
  const { status } = order;

  return (
    <>
      {status === "EXPIRED" && (
        <HistoryItem
          title={"Истек срок"}
          descr={formatDateFrom(order.history.EXPIRED)}
          status={"canceled"}
        />
      )}

      {status === "CANCELED_BY_OIS" && (
        <HistoryItem
          title={"Отклонено ОИС"}
          descr={formatDateFrom(order.history.CANCELED_BY_OIS)}
          status={"canceled"}
        />
      )}

      {!["EXPIRED", "CANCELED_BY_OIS"].includes(status) && (
        <>
          <HistoryItem
            title={"Заявка подтверждена ОИС"}
            descr={formatDateFrom(order.history.WAITING_ISSUE)}
            status={
              status === "WAITING_ISSUE" || status === "CLOSED"
                ? "checked"
                : "nextpoint"
            }
          />

          <HistoryItem
            title={"Выпуск состоялся, ЦФА начислены"}
            descr={formatDateFrom(order.history.CLOSED)}
            status={status === "CLOSED" ? "checked" : "nextpoint"}
          />
        </>
      )}
    </>
  );
};
