import FeedbackForm from "../../../forms/FeedbackForm/FeedbackForm";
import SectionTitle from "../../../ui/SectionTitle/SectionTitle";
import "./Support.scss";

const Support = () => {
  return (
    <section className="support">
      <SectionTitle addClass="support__title">
        Возник вопрос или проблема? Напишите нам на почту!
      </SectionTitle>

      <div className="support__links">
        {/*<a href="tel:+7 900 00-00-00" className="support__link">*/}
        {/*  +7 900 00-00-00*/}
        {/*</a>*/}
        <a href="mailto:support@1meter.ru" className="support__link">
          support@1meter.ru
        </a>
      </div>

      {/*<SectionTitle addClass="support__title">Обратная связь</SectionTitle>*/}

      {/*<FeedbackForm />*/}
    </section>
  );
};

export default Support;
