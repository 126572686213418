import { useState, useEffect } from "react";
import SignItem from "./SignItem";
import Button from "../Button/Button";
import ComissionBlock from "./ComissionBlock";
import { useMutation } from "react-query";
import { useProspectusById } from "../../../hooks/fetchers/orders";
import useUserCryptoProfile from "../../../hooks/fetchers/user/useUserCryptoProfile";
import { signOrderByIdMutation, signCertMutation } from "../../../api";
import { Loader } from "../Loader/Loader";
import { config } from "../../../config";
import { getUserCertificates } from "crypto-pro";
import { createSignatureCrypto } from "../../../utils";
import BackBtn from "../BackBtn/BackBtn";

const SignForm = ({
  // releases,
  releaseForDisplay,
  afterSubmit,
  onProspectusError,
  order,
  onBackButton,
}) => {
  // ToDo - переделать под несколько релизов - order-ов должно быть много, логику перенести в SignItem
  const [signedArray, setSignedArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [certificatesError, setCertificatesError] = useState([]);
  const [currentCertificate, setCurrentCertificate] = useState(null);
  const [activeSelectCertificates, setActiveSelectCertificates] =
    useState(false);
  const [signatureError, setSignatureError] = useState("");
  const [hashError, setHashError] = useState(null);

  const { data: cryptoProfile, isSuccess: isCryptoProfileSuccess } =
    useUserCryptoProfile();

  // Пункт 2 - получаем проспект файл
  const {
    data: prospectusFile,
    isSuccess: isProspectusSuccess,
    isLoading: isProspectusLoading,
  } = useProspectusById(order.prospectusId, onProspectusError);

  // Пункт 3 - Подписываем /cert, получаем файл подписи
  const [signFile, setSignFile] = useState(null);

  const { mutate: signCert, isLoading: isSignCertLoading } = useMutation({
    mutationFn: (body) => {
      return signCertMutation(body.thumbprint, body.file);
    },
    onSuccess: (data) => {
      setErrorMessage("");

      setSignFile(data);
    },
    onError: (data) => {
      setErrorMessage(data.message);
    },
  });

  const onButtonClickGetCertificate = (certThumbprint) => {
    setCurrentCertificate(certThumbprint);
    createSignatureCrypto(
      prospectusFile,
      setSignatureError,
      setHashError,
      setSignFile,
      certThumbprint
    );
  };

  const findCertificatesForCloudStorageFromCertificatesListPlugin = (
    certificates
  ) => {
    if (certificates && cryptoProfile) {
      certificates.forEach((certificate) => {
        cryptoProfile?.forEach((el) => {
          if (el.Thumbprint === certificate.thumbprint) {
            setCurrentCertificate(certificate.thumbprint);
          }
        });
      });
    }
  };

  useEffect(() => {
    setActiveSelectCertificates(false);
    setCurrentCertificate(null);
    setSignatureError("");
    setSignFile(null);

    findCertificatesForCloudStorageFromCertificatesListPlugin(certificates);
    if (config.certificatesCloudStorage) {
      if (isProspectusSuccess && isCryptoProfileSuccess && currentCertificate) {
        createSignatureCrypto(
          prospectusFile,
          setSignatureError,
          setHashError,
          setSignFile,
          currentCertificate
        );
      } else {
        setActiveSelectCertificates(true);
      }
    } else {
      if (isProspectusSuccess && isCryptoProfileSuccess && signCert) {
        const formData = new FormData();
        const file = new File(
          [JSON.stringify(prospectusFile)],
          `prospectus-${order.prospectusId}.json`,
          {
            encoding: "UTF-8",
          }
        );
        formData.append("file", file);

        signCert({
          thumbprint: cryptoProfile
            ? cryptoProfile[cryptoProfile.length - 1]?.Thumbprint
            : null, // В августе добавить проверку даты notAfter
          file: formData,
        });
        setCurrentCertificate(cryptoProfile[cryptoProfile.length - 1]);
      }
    }
  }, [isProspectusSuccess, isCryptoProfileSuccess, certificates]);

  // Пункт 4 - Отправка файла подписи
  const { mutate: onSignOrder, isSuccess: isSignSuccess } = useMutation({
    mutationFn: () => {
      const file = new File(
        [signFile],
        `prospectus-${order.prospectusId}.json.p7s`,
        {
          encoding: "UTF-8",
        }
      );
      const formularData = new FormData();
      formularData.append("file", file);

      return signOrderByIdMutation({
        id: order.internalId,
        file: formularData,
      });
    },
    onSuccess: () => {
      setSignedArray((prev) => [...prev, order?.release?.id]);
    },
    onError: (data) => {
      setErrorMessage(data.message);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setCertificates(await getUserCertificates());
      } catch (error) {
        setCertificatesError(error.message);
      }
    })();
  });

  const isLoading = isProspectusLoading || isSignCertLoading;

  if (isLoading) return <Loader isBlue isLarge />;

  return (
    <div className="sign">
      <BackBtn addClass="sign__back-btn" onClick={onBackButton} />
      <span className="sign__title">Подписание</span>
      <div className="sign__grid">
        {releaseForDisplay && (
          <SignItem
            onSignOrder={onSignOrder}
            release={releaseForDisplay}
            isSigned={isSignSuccess}
            isDefaultOpened
            currentCertificate={currentCertificate}
            onButtonClickGetCertificate={onButtonClickGetCertificate}
            certificates={certificates}
            activeSelectCertificates={activeSelectCertificates}
            signatureError={signatureError}
          />
        )}
        {
          // -- Логика для нескольких релизов
        }
        {/* {releases.map((release, i) =>
          release ? (
            <SignItem
              onSignOrder={onSignOrder}
              release={release}
              isSigned={isSignSuccess}
              isDefaultOpened={i === 0}
              key={`sign-item-${release.id}`}
              currentCertificate={currentCertificate}
              onButtonClickGetCertificate={onButtonClickGetCertificate}
              certificates={certificates}
              activeSelectCertificates={activeSelectCertificates}
              signatureError={signatureError}
            />
          ) : null
        )} */}
      </div>

      <Button
        addClass="sign__buy-btn"
        isFull
        size="m"
        disabled={!signedArray.length}
        // disabled={!(signedArray.length === releases.length)}
        onClick={afterSubmit}
      >
        Купить
      </Button>

      {
        // prospectusFile.dealAmount - приходит в копейках
      }
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default SignForm;
