import { Link } from "react-router-dom";
import { ArrowIcon } from "./ArrowIcon";
import { aboutLinks } from "./aboutLinks";
const Nav = ({ withIcons = false, onClick = () => {} }) => {
  return (
    <nav className="nav">
      <ul>
        {aboutLinks.map((link) => (
          <li key={link.id}>
            <Link to={link.link} className="link" onClick={onClick}>
              {link.title}

              {withIcons && (
                <span className="link__icon link__icon_blue">
                  <ArrowIcon />
                </span>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
