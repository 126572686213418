const PolicyPage = () => {
  return (
    <section className="home doc-page">
      <h1 className="title text-center">
        Политика обработки персональных данных
      </h1>
      <div className="text-block">
        <h2 className="h5 text-center">1. Общие положения</h2>
        <div className="list-item">
          <span className="list-item__counter">1.1.</span>
          <div className="list-item__content">
            <p>
              Настоящая Политика обработки персональных данных (далее –
              <b>Политика</b>) составлена в соответствии с требованиями
              Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»
              (далее — <b>Закон о персональных данных</b>) и определяет принципы
              и порядок обработки персональных данных, собранных обществом с
              ограниченной ответственностью «ЦТРР» (далее – <b>Оператор</b>) с
              использованием принадлежащего Оператору сайта в
              информационно-телекоммуникационной сети "Интернет"
              <a
                href="https://1meter.ru/"
                className="link link_blue"
                target="_blank"
                rel="noreferrer"
              >
                https://1meter.ru/
              </a>{" "}
              (далее – <b>Сайт</b>), а также принадлежащей Оператору на условиях
              неисключительной (простой) лицензии программы для ЭВМ
              "Веб-приложение пользователя информационной системы для выпуска и
              оборота цифровых финансовых активов на базе технологий
              распределенных реестров" (свидетельство о государственной
              регистрации № №2023682535 от 26.10.2023) (далее – <b>Программа</b>
              ), доступ к которой осуществляется через личный кабинет
              Пользователя на Сайте.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.2.</span>
          <div className="list-item__content">
            <p>
              Политика находится в свободном доступе в
              информационно-телекоммуникационной сети "Интернет" по адресу
              <a
                href="https://1meter.ru/"
                className="link link_blue"
                target="_blank"
                rel="noreferrer"
              >
                https://1meter.ru/
              </a>{" "}
              и обязательна к ознакомлению для всех Пользователей Сайта и (или)
              Программы.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.3.</span>
          <div className="list-item__content">
            <p>
              Настоящая Политика применяется ко всей информации, которую
              Оператор может получить о Пользователях Сайта и (или) Программы.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.4.</span>
          <div className="list-item__content">
            <p>
              В целях настоящей Политики используются следующие основные
              понятия:
              <br />
              а) автоматизированная обработка персональных данных — обработка
              персональных данных с помощью средств вычислительной техники;
              <br />
              б) блокирование персональных данных — временное прекращение
              обработки персональных данных (за исключением случаев, если
              обработка необходима для уточнения персональных данных);
              <br />
              в) информационная система персональных данных — совокупность
              содержащихся в базах данных персональных данных и обеспечивающих
              их обработку информационных технологий и технических средств;
              <br />
              г) конфиденциальность персональных данных – установление
              ограниченного круга должностных лиц Оператора, имеющих доступ к
              персональных данным, обязанных не раскрывать третьим лицам и не
              распространять персональные данные без согласия субъекта
              персональных данных, если иное не предусмотрено федеральным
              законом;
              <br />
              д) обезличивание персональных данных — действия, в результате
              которых невозможно определить без использования дополнительной
              информации принадлежность персональных данных конкретному субъекту
              персональных данных;
              <br />
              е) обработка персональных данных — любое действие (операция) или
              совокупность действий (операций), совершаемых Оператором с
              использованием средств автоматизации или без использования таких
              средств с персональными данными, включая сбор, запись,
              систематизацию, накопление, хранение, уточнение (обновление,
              изменение), извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных;
              <br />
              ж) Оператор — общество с ограниченной ответственностью «ЦТРР»
              (ОГРН 1157847001850, ИНН 7801204030), организующее и (или)
              осуществляющее в лице уполномоченных работников обработку
              персональных данных, а также определяющее цели и содержание
              обработки персональных данных, состав персональных данных,
              подлежащих обработке, действия (операции), совершаемые с
              персональными данными;
              <br />
              з) персональные данные — любая информация, относящаяся к прямо или
              косвенно определенному или определяемому физическому лицу
              (субъекту персональных данных) - Пользователю Сайта и (или)
              Программы; и) персональные данные, разрешенные субъектом
              персональных данных для распространения, — персональные данные,
              доступ неограниченного круга лиц к которым предоставлен субъектом
              персональных данных путем дачи согласия на обработку персональных
              данных, разрешенных субъектом персональных данных для
              распространения в порядке, предусмотренном Законом о персональных
              данных (далее — персональные данные, разрешенные для
              распространения);
              <br />
              к) субъект персональных данных — физическое лицо, являющееся
              пользователем Сайта и (или) Программы (далее – <b>Пользователь</b>
              );
              <br />
              л) предоставление персональных данных — действия, направленные
              на раскрытие персональных данных определенному лицу или
              определенному кругу лиц;
              <br />
              м) распространение персональных данных — действия, направленные на
              раскрытие персональных данных неопределенному кругу лиц;
              <br />
              н) трансграничная передача персональных данных — передача
              персональных данных на территорию иностранного государства органу
              власти иностранного государства, иностранному физическому или
              иностранному юридическому лицу;
              <br />
              о) уничтожение персональных данных — действия, в результате
              которых становится невозможным восстановить содержание
              персональных данных в информационной системе персональных данных и
              (или) в результате которых уничтожаются материальные носители
              персональных данных.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.5.</span>
          <div className="list-item__content">
            <p>
              Термины, не определенные в Политике, имеют значение, которое
              придается им законодательством Российской Федерации и, в первую
              очередь, Законом о персональных данных.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">
          2. Принципы обработки персональных данных
        </h2>
        <div className="list-item">
          <span className="list-item__counter">2.1.</span>
          <div className="list-item__content">
            <p>
              Оператор осуществляет обработку персональных данных, собранных с
              использованием Сайта и (или) Программы, руководствуясь следующими
              принципами:
              <br />— цели и способы обработки персональных данных должны быть
              законными;
              <br />— цели обработки персональных данных должны соответствовать
              целям, которые заранее определены и заявлены при сборе
              персональных данных;
              <br />— объем и характер обрабатываемых персональных данных, а
              также способы их обработки должны соответствовать целям обработки;
              <br />— персональные данные должны быть точными, актуальными,
              достаточными для целей обработки, а объем обработки не должен быть
              избыточным по отношению к целям, заявленным при сборе персональных
              данных;
              <br />— объединение баз персональных данных, созданных для
              несовместимых между собой целей, не допускается.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">2.2.</span>
          <div className="list-item__content">
            <p>
              Персональные данные, собранные Оператором с использованием Сайта и
              (или) Программы, являются конфиденциальными. Не допускается
              обработка таких персональных данных лицами, не допущенными
              Оператором к их обработке в установленном порядке.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">
          3. Основания обработки персональных данных и их состав
        </h2>
        <div className="list-item">
          <span className="list-item__counter">3.1.</span>
          <div className="list-item__content">
            <p>
              Оператор обрабатывает персональные данные, собранные с
              использованием Сайта и (или) Программы, на основании согласий
              Пользователей, а также без таковых в случаях, предусмотренных
              законодательством Российской Федерации. Заполняя соответствующие
              формы и/или отправляя свои персональные данные Оператору с
              использованием Сайта и (или) Программы, Пользователь выражает свое
              согласие с данной Политикой.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.2.</span>
          <div className="list-item__content">
            <p>
              Если Пользователь, действуя самостоятельно или от имени другого
              лица, передает посредством Сайта и (или) Программы Оператору
              персональные данные третьих лиц, соответствующий Пользователь
              подтверждает и гарантирует, что им (представляемым им лицом) было
              получено соответствующее согласие каждого из третьих лиц на
              обработку их персональных данных в соответствии с настоящей
              Политикой. В случае нарушения данного требования Пользователь или
              лицо, от имени которого действует Пользователь, обязаны возместить
              Оператору все убытки и потери, понесенные Оператором в связи с
              таким нарушением.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.3.</span>
          <div className="list-item__content">
            <p>
              3.3 При помощи Сайта и (или) Программы Оператор собирает и
              обрабатывает следующие персональные данные Пользователя:
              <br />
              (1) фамилия, имя, отчество;
              <br />
              (2) дата рождения;
              <br />
              (3) номер телефона;
              <br />
              (4) адрес электронной почты;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.4.</span>
          <div className="list-item__content">
            <p>
              Оператор не собирает с использованием Сайта и (или) Программы
              биометрические персональные данные, а также персональные данные,
              относящиеся к специальным категориям персональных данных.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.5.</span>
          <div className="list-item__content">
            <p>
              Оператор вправе самостоятельно определять состав и перечень мер,
              необходимых и достаточных для обеспечения выполнения обязанностей,
              предусмотренных Законом о персональных данных и принятыми в
              соответствии с ним нормативными правовыми актами, если иное не
              предусмотрено законодательством РФ.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.6.</span>
          <div className="list-item__content">
            <p>
              Помимо персональных данных Пользователя Оператор может получать
              данные, которые автоматически передаются в процессе их
              использования с помощью установленного на устройстве Пользователя
              программного обеспечения, в том числе IP-адрес, данные о
              веб-браузере, данные о посещаемых страницах (в т.ч. посредством
              cookies), данные вводимые в формы поиска и другие формы ввода
              информации на Сайте, время доступа, адрес запрашиваемой страницы.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">
          4. Цели и способы обработки персональных данных
        </h2>
        <div className="list-item">
          <span className="list-item__counter">4.1.</span>
          <div className="list-item__content">
            <p>
              Оператор обрабатывает персональные данные, собранные с
              использованием Сайта и (или) Программы, для достижения следующих
              целей:
              <br /> - предоставить доступ Пользователям к сервисам, информации
              и/или материалам, содержащимся на Сайте и (или) в Программе; ▪
              <br /> - предоставить доступ Пользователям к Программе,
              использование которой осуществляется через личный кабинет
              Пользователя на Сайте;
              <br /> - обеспечить Пользователям возможность использовать Сайт и
              (или) Программу по его (их) функциональному назначению;
              <br /> - связь Оператора с Пользователем, в том числе направление
              уведомлений, запросов и информации, касающихся использования Сайта
              и (или) Программы, оказания услуг (если применимо), а также
              обработка запросов и заявок от Пользователя, получение сводной
              информации о пользователях Сайта в маркетинговых целях.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.2.</span>
          <div className="list-item__content">
            <p>
              Персональные данные могут обрабатываться как с использованием
              средств автоматизации, так и без их применения посредством их
              сбора, записи, систематизации, накопления, хранения, уточнения
              (обновления, изменения), извлечения, использования, передачи
              (распространения, предоставления, доступа), обезличивания,
              блокирования, удаления и уничтожения.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.3.</span>
          <div className="list-item__content">
            <p>
              При обработке персональных данных в электронном виде Оператором
              реализуются организационные, правовые и технические меры защиты,
              исключающие возможность несанкционированного доступа к
              персональным данным лиц, не допущенных к их обработке. Среди
              прочего, указанные меры включают:
              <br /> - обеспечение сохранности персональных данных и принятие
              всех возможных мер, исключающих доступ к персональным данным
              неуполномоченных лиц;
              <br /> - управление доступом к персональным данным (включая
              применение мер парольной защиты);
              <br /> - контроль защищенности персональных данных;
              <br /> - обеспечение доступности персональных данных (в том числе,
              посредством резервного копирования персональных данных);
              <br /> - антивирусную защиту информационных систем персональных
              данных.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.4.</span>
          <div className="list-item__content">
            <p>
              Обработка персональных данных (в том числе, их хранение)
              Оператором подлежит прекращению по достижении целей обработки
              соответствующих данных, а также в случае отпадения оснований для
              обработки персональных данных.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">
          5. Правовые основания обработки персональных данных
        </h2>
        <div className="list-item">
          <span className="list-item__counter">5.1.</span>
          <div className="list-item__content">
            <p>
              Правовыми основаниями обработки персональных данных Оператором
              являются:
              <br /> - Конституция РФ;
              <br /> - Гражданский кодекс РФ;
              <br /> - Федеральный закон от 27 июля 2006 г. N 149-ФЗ "Об
              информации, информационных технологиях и о защите информации";
              <br /> - Постановление Правительства Российской Федерации от 15
              сентября 2008 г. N 687 "Об утверждении Положения об особенностях
              обработки персональных данных, осуществляемой без использования
              средств автоматизации";
              <br /> - Постановление Правительства Российской Федерации от 1
              ноября 2012 г. N 1119 "Об утверждении требований к защите
              персональных данных при их обработке в информационных системах
              персональных данных";
              <br /> - Приказ Роскомнадзора от 5 сентября 2013 г. N 996 "Об
              утверждении требований и методов по обезличиванию персональных
              данных";
              <br /> - Приказ Роскомнадзора от 24.02.2021 N 18 "Об утверждении
              требований к содержанию согласия на обработку персональных данных,
              разрешенных субъектом персональных данных для распространения"
              (действует до 01.09.2027);
              <br /> - иные нормативные правовые акты Российской Федерации и
              нормативные документы уполномоченных органов государственной
              власти;
              <br /> - договоры, заключаемые между Оператором и субъектами
              персональных данных;
              <br /> - согласия субъектов персональных данных на обработку
              персональных данных;
              <br /> - иные основания, когда согласие на обработку персональных
              данных не требуется в силу закона.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">6. Передача персональных данных</h2>
        <div className="list-item">
          <span className="list-item__counter">6.1.</span>
          <div className="list-item__content">
            <p>
              Оператор обеспечивает сохранность персональных данных и принимает
              все возможные меры, исключающие доступ к персональным данным
              неуполномоченных лиц.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">6.2.</span>
          <div className="list-item__content">
            <p>
              Персональные данные Пользователя не могут быть переданы третьим
              лицам без согласия субъекта персональных данных за исключением
              случаев, предусмотренных действующим законодательством.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">6.3.</span>
          <div className="list-item__content">
            <p>
              В случае передачи персональных данных на территории иностранных
              государств, данные передаются только в страны, в которых
              обеспечивается адекватная защита прав субъектов персональных
              данных.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">6.4.</span>
          <div className="list-item__content">
            <p>
              Оператор до начала осуществления деятельности по трансграничной
              передаче персональных данных обязан уведомить уполномоченный орган
              по защите прав субъектов персональных данных о своем намерении
              осуществлять трансграничную передачу персональных данных (такое
              уведомление направляется отдельно от уведомления о намерении
              осуществлять обработку персональных данных).
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">6.5.</span>
          <div className="list-item__content">
            <p>
              Оператор до подачи указанного в п. 6.4. Политики уведомления
              обязан получить от органов власти иностранного государства,
              иностранных физических лиц, иностранных юридических лиц, которым
              планируется трансграничная передача персональных данных, сведения,
              предусмотренные частью 5 статьи 12 Закона о персональных данных.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">
          7. Права субъектов персональных данных
        </h2>
        <div className="list-item">
          <span className="list-item__counter">7.1.</span>
          <div className="list-item__content">
            <p>
              Субъекты персональных данных вправе:
              <br /> - получать доступ к информации, касающейся обработки их
              персональных данных, за исключением случаев, предусмотренных
              федеральными законами. Сведения предоставляются субъекту
              персональных данных Оператором в доступной форме, и в них не
              должны содержаться персональные данные, относящиеся к другим
              субъектам персональных данных, за исключением случаев, когда
              имеются законные основания для раскрытия таких персональных
              данных. Перечень информации и порядок ее получения установлен
              Федеральным законом от 27.07.2006 №152-ФЗ «О персональных данных»;
              <br /> - требовать от Оператора уточнения персональных данных,
              прекращения их обработки, их блокирования или уничтожения в
              случае, если персональные данные являются неполными, устаревшими,
              неточными, незаконно полученными или не являются необходимыми для
              заявленной цели обработки;
              <br /> - обжаловать в суд любые неправомерные действия или
              бездействия Оператора при обработке и защите персональных данных,
              а также принимать иные предусмотренные законом меры по защите
              своих прав.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">7.2.</span>
          <div className="list-item__content">
            <p>
              Субъект персональных данных имеет право на получение информации,
              касающейся обработки его персональных данных, содержащей:
              <br /> - подтверждение факта обработки персональных данных
              Оператором;
              <br /> - правовые основания и цели обработки персональных данных;
              <br /> - применяемые Оператором способы обработки персональных
              данных;
              <br /> - наименование и место нахождения Оператора, сведения о
              лицах, которые имеют доступ к персональным данным, или которым
              могут быть раскрыты персональные данные на основании договора с
              Оператором или на основании федерального закона;
              <br /> - обрабатываемые персональные данные, относящиеся к
              соответствующему субъекту персональных данных, источник их
              получения, если иной порядок представления таких данных не
              предусмотрен федеральным законом;
              <br /> - сроки обработки персональных данных, в том числе сроки их
              хранения;
              <br /> - порядок осуществления субъектом персональных данных прав,
              предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О
              персональных данных»;
              <br /> - информацию о трансграничной передаче данных;
              <br /> - сведения о месте нахождения базы данных информации,
              содержащей персональные данные;
              <br /> - наименование или фамилию, имя, отчество и адрес лица,
              осуществляющего обработку персональных данных по поручению
              Оператора, если обработка поручена или будет поручена такому лицу;
              <br /> - иные сведения, предусмотренные Федеральным законом от
              27.07.2006 № 152-ФЗ «О персональных данных» или другими
              федеральными законами.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">7.3.</span>
          <div className="list-item__content">
            <p>
              Сведения, указанные в пункте 7.2 Политики, предоставляются
              субъекту персональных данных или его представителю в течение 10
              (десяти) рабочих дней с момента обращения либо получения
              Оператором запроса субъекта персональных данных или его
              представителя. Указанный срок может быть продлен, но не более чем
              на 5 (пять) рабочих дней в случае направления Оператором в адрес
              субъекта персональных данных мотивированного уведомления с
              указанием причин продления срока предоставления запрашиваемой
              информации. Запрос должен содержать номер основного документа,
              удостоверяющего личность субъекта персональных данных или его
              представителя, сведения о дате выдачи указанного документа и
              выдавшем его органе, сведения, подтверждающие факт обработки
              персональных данных Оператором. Запрос должен быть подписан
              субъектом персональных данных или его представителем.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">7.4.</span>
          <div className="list-item__content">
            <p>
              Если лицо, обратившееся к Оператору с запросом, не уполномочено на
              получение информации, относящейся к персональным данным,
              соответствующему лицу отказывается в выдаче такой информации и
              выдается соответствующее уведомление об отказе в выдаче информации
              в срок, не превышающий 10 (десяти) рабочих дней со дня обращения
              субъекта персональных данных или его представителя либо с даты
              получения запроса субъекта персональных данных или его
              представителя. Указанный срок может быть продлен, но не более чем
              на 5 (пять) рабочих дней в случае направления Оператором в адрес
              субъекта персональных данных мотивированного уведомления с
              указанием причин продления срока предоставления запрашиваемой
              информации.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">7.5.</span>
          <div className="list-item__content">
            <p>
              В случае выявления неточности в персональных данных Пользователь
              может актуализировать их путем направления Оператору уведомления
              на адрес электронной почты Оператора{" "}
              <a href="mailto:support@dltc.tech" className="link link_blue">
                support@dltc.tech
              </a>{" "}
              с пометкой «Актуализация персональных данных». В случае
              подтверждения факта неточности обрабатываемых персональных данных
              на основании сведений, представленных субъектом персональных
              данных, его представителем или уполномоченным органом по защите
              прав субъектов персональных данных, обеспечивается уточнение
              персональных данных в течение 7 (семи) рабочих дней со дня
              представления таких сведений. Если уточнение данных невозможно
              осуществить в указанный срок, уточнение осуществляется в
              кратчайшие возможные сроки.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">7.6.</span>
          <div className="list-item__content">
            <p>
              В случае выявления по итогам проверки неправомерной обработки
              персональных данных Оператором, производится устранение нарушения
              в срок, не превышающий 3 (три) рабочих дня с момента подтверждения
              факта неправомерной обработки. Если обеспечить правомерность
              обработки персональных данных невозможно, в срок, не превышающий
              10 (десять) рабочих дней со дня выявления неправомерной обработки
              персональных данных, производится уничтожение данных. Об
              устранении допущенных нарушений или об уничтожении персональных
              данных немедленно уведомляется субъект персональных данных или его
              представитель, а если обращение либо запрос были направлены
              уполномоченным органом по защите прав субъектов персональных
              данных – также указанный орган.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">7.7.</span>
          <div className="list-item__content">
            <p>
              Пользователь может в любой момент отозвать свое согласие на
              обработку персональных данных, направив Оператору уведомление
              посредством электронной почты на электронный адрес Оператора{" "}
              <a href="mailto:support@dltc.tech" className="link link_blue">
                support@dltc.tech
              </a>{" "}
              с пометкой «Отзыв согласия на обработку персональных данных». В
              случае отзыва субъектом персональных данных согласия на обработку
              его персональных данных Оператор в срок, не превышающий 10
              (десяти) рабочих дней с даты получения Оператором соответствующего
              требования, обязан прекратить их обработку или обеспечить
              прекращение такой обработки (за исключением случаев,
              предусмотренных законодательством РФ) и в течение 30 (тридцати)
              дней уничтожить их. Требования настоящего пункта не подлежат
              применению, если иное предусмотрено действующим законодательством
              или договором с Оператором, стороной которого,
              выгодоприобретателем или поручителем по которому является субъект
              персональных данных.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">8. Заключительные положения</h2>
        <div className="list-item">
          <span className="list-item__counter">8.1.</span>
          <div className="list-item__content">
            <p>
              Пользователь вправе получить любые разъяснения по интересующим
              вопросам, касающимся обработки Оператором его персональных данных,
              получаемых посредством Сайта и (или) Программы, обратившись к
              Оператору по адресу электронной почты{" "}
              <a href="mailto:support@dltc.tech" className="link link_blue">
                support@dltc.tech
              </a>{" "}
              .
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">8.2.</span>
          <div className="list-item__content">
            <p>
              Оператор имеет право вносить изменения в настоящую Политику.
              Положения Политики подлежат актуализации в случае изменения
              законодательства Российской Федерации о персональных данных. Новая
              редакция Политики вступает в силу с момента ее размещения на
              Сайте, если иное не предусмотрено новой редакцией Политики.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PolicyPage;
