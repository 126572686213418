import { useForm } from "react-hook-form";
import { useQueryClient, useMutation } from "react-query";
import { HistoryItem } from "../../../../components/ui/History/History";
import Button from "../../../../components/ui/Button/Button";

import { useOrderPaymentDocument } from "../../../../hooks/fetchers/orders";
import {
  acceptOrderMutation,
  rejectOrderPaymentMutation,
} from "../../../../api";
import { useParams } from "react-router-dom";
import { downloadFile, formatDateFrom } from "../../../../utils";
import Input from "../../../../components/ui/Input/Input";
import { FormProvider } from "react-hook-form";
import { useState } from "react";
const DownloadTicketButton = ({ ticketLink = null, isTicketAvailable }) => {
  const { refetch, isLoading } = useOrderPaymentDocument();
  const [errorMessage, setErrorMessage] = useState(null);
  return (
    <>
      <Button
        buttonType={isTicketAvailable ? "primary" : "secondary"}
        disabled={!isTicketAvailable || isLoading}
        isLoading={isLoading}
        size="s"
        style={{ marginTop: 10 }}
        onClick={() => {
          setErrorMessage(null);
          refetch().then((resp) => {
            if (resp.status === "error") {
              setErrorMessage("Ошибка: Временный файл не найден");
            }
            if (resp && resp.data) {
              downloadFile(new Blob([resp.data]), "payment-document.pdf");
            }
          });
        }}
      >
        {isTicketAvailable ? "Квитанция" : "Ожидание квитанции"}
      </Button>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </>
  );
};

const ConfirmTicketButtons = ({ status, isTicketAvailable = false }) => {
  const queryClient = useQueryClient();
  const [showRejectForm, setShowRejectForm] = useState(false);
  const { id } = useParams();
  const methods = useForm({
    defaultValues: {
      rejectCommentary: "",
    },
  });

  const {
    mutate: acceptOrder,
    isLoading: acceptOrderLoading,
    isSuccess: acceptOrderSuccess,
  } = useMutation({
    mutationFn: () => acceptOrderMutation(id),
    enabled: !!id,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
    },
  });

  const {
    mutate: rejectOrderPayment,
    isLoading: rejectOrderLoading,
    isSuccess: rejectOrderSuccess,
  } = useMutation({
    mutationFn: (message) => rejectOrderPaymentMutation(id, message),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders", id] });
    },
  });

  const isSuccess = acceptOrderSuccess || rejectOrderSuccess;

  return isTicketAvailable &&
    status === "WAITING_FOR_CONFIRMATION" &&
    !isSuccess ? (
    <>
      <div style={{ display: "flex", marginTop: 10, gap: 10 }}>
        <Button size="s" onClick={acceptOrder} isLoading={acceptOrderLoading}>
          Подтвердить
        </Button>

        <Button
          size="s"
          onClick={() => setShowRejectForm(true)}
          buttonType="secondary-red"
          isLoading={rejectOrderLoading}
        >
          Отклонить
        </Button>
      </div>
      {showRejectForm && (
        <FormProvider {...methods}>
          <form
            style={{ marginTop: 15 }}
            onSubmit={methods.handleSubmit((data) =>
              rejectOrderPayment(data.rejectCommentary)
            )}
          >
            <Input
              as="textarea"
              placeholder="Введите причину отклонения"
              name="rejectCommentary"
              size="lg"
            />
            <div style={{ display: "flex" }}>
              <Button
                type="submit"
                style={{ marginTop: 15, marginLeft: "auto" }}
                size="s"
                buttonType="primary"
                isLoading={rejectOrderLoading}
              >
                Отправить
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  ) : null;
};

export const WaitingTicket = ({ order }) => {
  const { status } = order;

  if (!order) return null;

  return (
    <>
      <HistoryItem
        title={"Заявка создана"}
        descr={formatDateFrom(order.history.INIT_PROSPECTUS_CREATED)}
        status={"checked"}
      />
      <HistoryItem
        title={"Ожидание оплаты"}
        descr={formatDateFrom(order.history.WAITING_FOR_PAYMENT)}
        status={
          status === "PAYMENT_CONFIRMED" ||
          status === "WAITING_ISSUE" ||
          status === "CLOSED"
            ? "checked"
            : "checkpoint"
        }
      />
      <HistoryItem
        title={"Проверка оплаты"}
        status={
          status === "PAYMENT_CONFIRMED" ||
          status === "WAITING_ISSUE" ||
          status === "CLOSED"
            ? "checked"
            : "checkpoint"
        }
        renderElement={() => (
          <DownloadTicketButton
            isTicketAvailable={
              order.status !== "WAITING_FOR_PAYMENT" &&
              order.paymentDocumentId !== null
            }
          />
        )}
      />
      <HistoryItem
        title={
          status === "WAITING_FOR_CONFIRMATION"
            ? "Ожидается подтверждение эмитентом"
            : status === "CANCELLED" || status === "CANCELED"
            ? "Оплата отклонена эмитентом"
            : "Оплата подтверждена эмитентом"
        }
        descr={formatDateFrom(order.history.PAYMENT_CONFIRMED)}
        isBeforeNextpoint={status === "WAITING_FOR_PAYMENT"}
        status={
          status === "WAITING_FOR_PAYMENT"
            ? "nextpoint"
            : status === "PAYMENT_CONFIRMED" ||
              status === "WAITING_ISSUE" ||
              status === "CLOSED"
            ? "checked"
            : "checkpoint"
        }
        renderElement={() => (
          <ConfirmTicketButtons
            status={order.status}
            isTicketAvailable={order.paymentDocumentId}
          />
        )}
      />
    </>
  );
};
