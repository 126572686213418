import { useEffect, useState } from "react";

function useGetNewRelease(content) {
  const [newRelease, setNewRelease] = useState({});

  useEffect(() => {
    let isMounted = true;

    if (
      content?.objectType === "RELEASE" &&
      content?.eventType === "NEW_TOKENS"
    ) {
      isMounted && setNewRelease(content);
    } else {
      isMounted && setNewRelease({});
    }

    return () => {
      isMounted = false;
    };
  }, [content.objectType, content.eventType]);

  return { newRelease };
}

export default useGetNewRelease;
