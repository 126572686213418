import { getReleaseById } from "../../../api";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
const useRelease = () => {
  const { id } = useParams();

  const release = useQuery({
    queryKey: ["releases", id],
    queryFn: () => getReleaseById(id),
    enabled: !!id,
  });
  return release;
};

export default useRelease;
