import AdminTableWithSort from "../AdminTable/AdminTableWithSort";
import SearchInput from "../SearchInput/SearchInput";

import AddPoolModal from "../AddModal/AddPoolModal";
import { useQuery } from "react-query";
import { getAllPools } from "../../../api";
import { Loader } from "../../../components/ui/Loader/Loader";
import { formatPoolsInfo } from "../AdminTable/AdminTableFormaters";
const Pools = () => {
  const { data: pools, isLoading } = useQuery({
    queryKey: ["pools"],
    queryFn: getAllPools,
  });

  if (isLoading) {
    return <Loader isLarge isBlue />;
  }

  return (
    <>
      {pools && pools.length > 0 && <SearchInput />}

      <AdminTableWithSort
        data={pools}
        formatInfo={formatPoolsInfo}
        noDataLabel={"Нет пулов"}
        renderModal={(closeModal) => <AddPoolModal closeModal={closeModal} />}
        addLabel={"Добавить пул"}
      />
    </>
  );
};

export default Pools;
