import { apiService, setHeaderToken, removeHeaderToken } from "./api";

export const fetchNewTokens = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) return null;

  removeHeaderToken();

  try {
    const tokens = await apiService
      .post(`/users/refresh`, refreshToken, {
        headers: { "Content-Type": "text/plain;charset=UTF-8", Accept: "*/*" },
      })
      .then((res) => res.data);
    return tokens;
  } catch (error) {
    return null;
  }
};

export const refreshAuth = async (failedRequest) => {
  const newTokens = await fetchNewTokens();

  if (newTokens) {
    failedRequest.response.config.headers.Authorization =
      "Bearer " + newTokens.accessToken;
    setHeaderToken(newTokens.accessToken);
    // you can set your token in storage too
    localStorage.setItem("accessToken", newTokens.accessToken);
    localStorage.setItem("refreshToken", newTokens.refreshToken);

    return Promise.resolve(newTokens.accessToken);
  } else {
    // you can redirect to login page here
    window.location.href = "/logout";
    return Promise.reject();
  }
};
