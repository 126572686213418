import { getEmitterById } from "../../api";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
const useEmitter = (options) => {
  const { id } = useParams();

  const emitter = useQuery({
    queryKey: ["emitters", id],
    queryFn: () => getEmitterById(id),
    enabled: !!id,
    ...options,
  });
  return emitter;
};

export default useEmitter;
