import classNames from "classnames";
import Label from "../Label/Label";
import Button from "../Button/Button";
import Slider from "../Slider/Slider";
import PurchaseCard from "../PurchaseCard/PurchaseCard";
import StatusLabel from "./StatusLabel";
import LikeButton from "../LikeButton/LikeButton";
import PoolLabel from "../PoolLabel/PoolLabel";
import { formatId, formatTokensForDisplay } from "../../../utils";
import { tokenizedReleaseStatusLabelMap } from "../../../admin/components/AdminTable/AdminTableFormaters";
import { RepaymentMethods } from "../../../constants";
import useClickOutside from "../../../hooks/useClickOutside";
import { releaseAvailableForOptions } from "../../forms/PolymorhicForm/ReleaseDescription";
import { useMemo } from "react";
import useWaitingRelease from "../../../hooks/useWaitingRelease";
import { formatDateFrom } from "../../../utils";
const CardMain = ({ releaseForDisplay, releases, buyTokens }) => {
  const isWaitingRelease = useWaitingRelease(releaseForDisplay);

  const { isOpened, setIsOpened } = useClickOutside();

  const releaseAvailableFor = useMemo(() => {
    if (!releaseForDisplay) return null;
    return releaseAvailableForOptions.find(
      (option) => option.value === releaseForDisplay.availableFor
    )?.label;
  }, [releaseForDisplay]);

  const cardClass = classNames({
    "card card_main": true,
  });

  const tokenCardClass = classNames({
    "card__token-card": true,
    "card__token-card_shown": isOpened,
  });

  if (!releaseForDisplay) return null;

  const availableTokens = formatTokensForDisplay(
    releaseForDisplay?.availableTokens
  );

  return (
    <article className={cardClass}>
      <div className="card__wrapper">
        <div className="card__labels-list">
          <StatusLabel data={releaseForDisplay} />
          <Label labelType="secondary">100 ЦФА = стоимости 1 м²</Label>
          <Label labelType="secondary">
            {`100 ЦФА = ${releaseForDisplay.price.toLocaleString()} ₽`}
          </Label>
          {releaseAvailableFor && (
            <Label labelType="secondary">
              Выпуск для {releaseAvailableFor}
            </Label>
          )}
          {/* <Label labelType="secondary">
            Лимит неквалифицированного инвестора: 600 000 руб
          </Label> */}
          <Label labelType="secondary">
            {tokenizedReleaseStatusLabelMap[releaseForDisplay.status]}
          </Label>
          <Label labelType="secondary">
            {RepaymentMethods[releaseForDisplay.pool.repaymentMethod]}
          </Label>
          {availableTokens > 0 ? (
            <Label labelType="primary">
              ЦФА осталось: {availableTokens.toLocaleString()}
            </Label>
          ) : (
            <Label labelType="secondary-gray">Нет доступных ЦФА</Label>
          )}
          {availableTokens > 0 && (
            <Label labelType="primary">
              Осталось: {(availableTokens / 100).toLocaleString()}{" "}
              токенизированных м²
            </Label>
          )}
        </div>

        <LikeButton buildingId={releaseForDisplay.building.id} />

        {/* <img className="card__img" src={mocImg} alt="" /> */}

        <PoolLabel addClass="card__pool-label">
          {releaseForDisplay.pool.name || formatId(releaseForDisplay.pool.id)}
        </PoolLabel>

        {!!releaseForDisplay.building?.imagesLinks && (
          <Slider photos={releaseForDisplay.building.imagesLinks} />
        )}

        {/* <div className="card__timer-block">
          {!buyTokens && <TimerBlock isSmall />}
        </div> */}
      </div>

      {buyTokens && (
        <div className={tokenCardClass}>
          <PurchaseCard
            releaseForDisplay={releaseForDisplay}
            activeReleases={releases}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            availableTokens={availableTokens}
          />
        </div>
      )}

      <div className="card__body">
        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__key">
              {releaseForDisplay.emitter.name ||
                formatId(releaseForDisplay.emitter.id)}
            </span>
            <p className="sell-info__value base-14 text-gray">Эмитент</p>
          </div>
          {(releaseForDisplay.building.city ||
            releaseForDisplay.building.district) && (
            <div className="sell-info__col">
              <span className="sell-info__key">
                {releaseForDisplay.building.city}
              </span>
              <p className="sell-info__value base-14 text-gray">
                {releaseForDisplay.building.district}
              </p>
            </div>
          )}
          <div className="sell-info__col">
            <span className="sell-info__key">
              {releaseForDisplay.building.name}
            </span>
            <p className="sell-info__value base-14 text-gray">
              {releaseForDisplay.building.developer.name}
            </p>
          </div>

          {releaseForDisplay.burningDate && (
            <div className="sell-info__col">
              <span className="sell-info__key">
                {formatDateFrom(releaseForDisplay.burningDate, true)}
              </span>
              <p className="sell-info__value base-14 text-gray">Дата выплаты</p>
            </div>
          )}

          <div className="sell-info__col">
            <span className="sell-info__key">{`100 ЦФА = ${releaseForDisplay.price.toLocaleString()} ₽`}</span>
          </div>

          <div className="sell-info__col">
            {!buyTokens ? (
              <Button isLink={`/${releaseForDisplay.pool.id}`}>
                Подробнее
              </Button>
            ) : (
              <Button
                title={
                  isWaitingRelease || !releaseForDisplay.idOis
                    ? "Выпуск ожидает токенизации"
                    : ""
                }
                onClick={() => setIsOpened(true)}
                disabled={
                  isOpened || isWaitingRelease || !releaseForDisplay.idOis
                }
              >
                Купить
              </Button>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

export default CardMain;
