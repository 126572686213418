const LicensePage = () => {
  return (
    <section className="home doc-page">
      <h1 className="title caps text-center">СУБЛИЦЕНЗИОННЫЙ ДОГОВОР</h1>
      <div className="text-block">
        <h2 className="h5 text-center">1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
        <div className="list-item">
          <span className="list-item__counter">1.1.</span>
          <div className="list-item__content">
            <p>
              В настоящем сублицензионном договоре, если из текста прямо не
              вытекает иное, следующие термины будут иметь указанные ниже
              значения:
            </p>
            <p>
              <b>«Договор»</b> - настоящий сублицензионный договор о
              предоставлении права использования Программы на условиях
              неисключительной (простой) лицензии.
            </p>
            <p>
              <b>«Информационная система» / «Система»</b> - информационная
              система, в которой осуществляется выпуск ЦФА и оператором которой
              является Общество с ограниченной ответственностью «Блокчейн Хаб»
              (ИНН 9704089611, ОГРН 1217700447755).
            </p>
            <p>
              <b>«Конфиденциальная информация»</b> - сведения:
            </p>
            <p>
              - о сделках, заключенных или заключаемых Лицензиатом в
              Информационной системе;
            </p>
            <p>- о ЦФА, принадлежавших или принадлежащих Лицензиату;</p>
            <p>
              передаваемые Лицензиатом Лицензиару в рамках использования
              Программы изменения (обновления) регистрационных и
              идентификационных данных Лицензиата и (или) признания Лицензиата
              квалифицированным инвестором.
            </p>
            <p>
              <b>«Лицензиар»</b> - ООО «ЦТРР», ОГРН: 1157847001850, ИНН:
              7801204030.
            </p>
            <p>
              <b>«Лицензиат»</b> - лицо, акцептовавшее публичную оферту на
              заключение Договора в порядке, предусмотренном разделом 2.
            </p>
            <p>
              <b>«Оператор информационной системы» / «ОИС»</b> - Общество с
              ограниченной ответственностью «Блокчейн Хаб» (ИНН 9704089611, ОГРН
              1217700447755), включенное Банком России в реестр операторов
              информационных систем, осуществляющее деятельность по эксплуатации
              информационной системы, являющейся совокупностью технических
              средств и информационных технологий, которая позволяет
              осуществлять выпуск и учет ЦФА, а также обеспечивает сделки с ЦФА
              в порядке, указанном в Правилах, опубликованных в открытом доступе
              на официальном сайте ОИС{" "}
              <a
                href="https://cfahub.ru/"
                className="link link_blue"
                target="_blank"
                rel="noreferrer"
              >
                https://cfahub.ru/
              </a>
              .
            </p>
            <p>
              <b>«Правила»</b> - правила информационной системы ООО «Блокчейн
              Хаб» (включая все изменения и дополнения, которые могут быть
              внесены в будущем), согласованные Банком России в порядке,
              предусмотренном законодательством Российской Федерации.
            </p>
            <p>
              <b>Программа</b> - программа для ЭВМ "Веб-приложение пользователя
              информационной системы для выпуска и оборота цифровых финансовых
              активов на базе технологий распределенных реестров" (свидетельство
              о государственной регистрации № №2023682535 от 26.10.2023), право
              использования которой на условиях неисключительной (простой)
              лицензии принадлежит Лицензиару на основании Лицензионного
              договора № УК/МИП-2/24 от «13» августа 2024 года между Федеральным
              государственным бюджетным образовательным учреждением высшего
              образования «Санкт-Петербургский государственный университет» (ИНН
              7801002274) и ООО «ЦТРР» (ИНН: 7801204030), используемая
              Лицензиатом через личный кабинет в Программе на принадлежащем
              Лицензиару Сайте в информационно-телекоммуникационной сети
              «Интернет», доступ к которому осуществляется по доменному имени «
              <a
                href="https://1meter.ru/"
                className="link link_blue"
                target="_blank"
                rel="noreferrer"
              >
                https://1meter.ru/
              </a>
              ».
            </p>
            <p>
              <b>«Сайт»</b> - сайт Лицензиара в
              информационно-телекоммуникационной сети «Интернет», доступ к
              которому осуществляется по доменному имени «
              <a
                href="https://1meter.ru/"
                className="link link_blue"
                target="_blank"
                rel="noreferrer"
              >
                https://1meter.ru/
              </a>
              ».
            </p>
            <p>
              <b>«Стороны»</b> - совместно Лицензиар и Лицензиат. Термин
              «Сторона» означает любую из Сторон.
            </p>
            <p>
              <b>«Уведомление»</b> - любое юридически значимое сообщение,
              направляемое одной из Сторон другой Стороне в случаях, прямо
              предусмотренных Договором.
            </p>
            <p>
              <b>«ЦФА»</b> - цифровые финансовые активы в значении,
              предусмотренном Федеральным законом от 31.07.2020 N 259-ФЗ «О
              цифровых финансовых активах, цифровой валюте и о внесении
              изменений в отдельные законодательные акты Российской Федерации».
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.2.</span>
          <div className="list-item__content">
            <p>В Договоре, если из контекста прямо не следует иное:</p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.2.1.</span>
          <div className="list-item__content">
            <p>
              ссылки на пункты и разделы являются ссылками на соответствующие
              пункты и разделы Договора;
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.2.2.</span>
          <div className="list-item__content">
            <p>
              названия заголовков приведены исключительно для удобства
              пользования и не влияют на толкование Договора;
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">1.2.3.</span>
          <div className="list-item__content">
            <p>
              использование понятий «в частности» и «включая» (или любого
              подобного термина) не должно толковаться как подразумевающее
              какое-либо ограничение. Фразы, оканчивающиеся словами «и другие»
              или «иным образом», или другими аналогичными словами и
              выражениями, не подлежат ограничительному толкованию по причине
              отношения предшествующих слов и выражений к какому-либо
              конкретному виду или классу, если возможно более широкое
              толкование.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">2. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА</h2>
        <div className="list-item">
          <span className="list-item__counter">2.1.</span>
          <div className="list-item__content">
            <p>
              Текст Договора, доступный на Сайте, содержит все существенные
              условия Договора и является предложением Лицензиара заключить
              Договор с любым лицом на указанных в тексте Договора условиях.
              Таким образом, текст Договора является публичной офертой в
              значении, предусмотренном пунктом 2 статьи 437 Гражданского
              кодекса Российской Федерации.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">2.2.</span>
          <div className="list-item__content">
            <p>
              Настоящая публичная оферта может быть в любой момент времени
              изменена Лицензиаром посредством размещения на Сайте измененной
              версии оферты.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">2.3.</span>
          <div className="list-item__content">
            <p>
              Настоящая публичная оферта может быть в любой момент времени
              отозвана Лицензиаром.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">2.4.</span>
          <div className="list-item__content">
            <p>
              Надлежащим акцептом настоящей публичной оферты в соответствии со
              статьей 438 Гражданского кодекса Российской Федерации считается
              последовательное осуществление лицом, которому она адресована,
              следующих действий:
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">2.4.1.</span>
          <div className="list-item__content">
            <p>ознакомление с условиями Договора;</p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">2.4.2.</span>
          <div className="list-item__content">
            <p>
              нажатие Пользователем кнопки «Получить код» при регистрации
              личного кабинета на Сайте.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">2.5.</span>
          <div className="list-item__content">
            <p>
              Договор считается заключенным и вступает в силу с момента акцепта
              Пользователем условий Договора путем нажатия кнопки «Получить код»
              при регистрации личного кабинета на Сайте, что является
              безусловным принятием (акцептом) оферты и считается равносильным
              заключением Договора в письменной форме на условиях, изложенных в
              настоящем Договоре.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">3. ПРЕДМЕТ ДОГОВОРА</h2>
        <div className="list-item">
          <span className="list-item__counter">3.1.</span>
          <div className="list-item__content">
            <p>
              Лицензиар предоставляет Лицензиату в предусмотренных Договором
              пределах право использования Программы для совершения Лицензиатом
              посредством использования Программы следующих действий в Системе:
              действия, направленные на выпуск, приобретение, распоряжение или
              погашение ЦФА, получение данных из Системы, передача данных в
              Систему.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.2.</span>
          <div className="list-item__content">
            <p>
              Право использования Программы предоставляется Лицензиату
              безвозмездно.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.3.</span>
          <div className="list-item__content">
            <p>
              Во избежание сомнений Лицензиар не является лицом, привлеченным
              Оператором информационной системы в целях{" "}
              <u>обеспечения доступа</u> Лицензиата к Системе. Лицензиат имеет
              возможность доступа к Системе с использованием Личного кабинета (в
              значении, предусмотренном Правилами). Все юридически значимые
              действия по выпуску, приобретению, распоряжению или погашению ЦФА
              совершаются в Системе.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">3.4.</span>
          <div className="list-item__content">
            <p>
              Сайт и Программа являются инструментом для стимулирования
              использования Системы Лицензиатом. Интерфейс Программы адаптирован
              для участников сферы недвижимости и может отличаться от
              пользовательского интерфейса Личного кабинета в Системе.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">4. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ПРОГРАММЫ</h2>
        <div className="list-item">
          <span className="list-item__counter">4.1.</span>
          <div className="list-item__content">
            <p>
              Лицензиат вправе использовать Программу посредством ее
              эксплуатации по функциональному назначению в объеме,
              предусмотренном пунктом 4.2, на условиях простой
              (неисключительной) лицензии на всей территории Российской
              Федерации.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.2.</span>
          <div className="list-item__content">
            <p>
              Использование Лицензиатом Программы допускается в следующем
              объеме:
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.2.1.</span>
          <div className="list-item__content">
            <p>
              получение и (или) передача информации при взаимодействии с
              Лицензиаром и другими пользователями Программы;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.2.2.</span>
          <div className="list-item__content">
            <p>
              действия, направленные на выпуск, приобретение, распоряжение или
              погашение ЦФА в Системе.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">4.3.</span>
          <div className="list-item__content">
            <p>
              Порядок и условия доступа Лицензиата к Программе: [Прохождение
              регистрации на сайте].
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">4.4.</span>
          <div className="list-item__content">
            <p>
              Использование Программы осуществляется Лицензиатом через личный
              кабинет в Программе посредством удаленного обращения по
              информационно-телекоммуникационной сети «Интернет» к экземпляру
              Программы, установленному и функционирующему на технической
              инфраструктуре Лицензиара.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">4.5.</span>
          <div className="list-item__content">
            <p>
              Во избежание сомнений Лицензиар не передает Лицензиату экземпляр
              Программы, а равно не предоставляет физический доступ к
              оборудованию, на которое установлена Программа.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">4.6.</span>
          <div className="list-item__content">
            <p>Лицензиату запрещается:</p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.6.1.</span>
          <div className="list-item__content">
            <p>
              заключать с третьими лицами сублицензионные договоры в отношении
              Программы;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.6.2.</span>
          <div className="list-item__content">
            <p>
              предоставлять третьим лицам возможность использования Программы, а
              равно создавать условия для использования Программы третьими
              лицами;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.6.3.</span>
          <div className="list-item__content">
            <p>
              обходить технические ограничения, установленные в отношении
              Программы;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.6.4.</span>
          <div className="list-item__content">
            <p>
              совершать действия, направленные на изменение функционирования и
              работоспособности Программы;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.6.5.</span>
          <div className="list-item__content">
            <p>
              осуществлять указанные выше действия в отношении любой части
              Программы.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.7.</span>
          <div className="list-item__content">
            <p>
              Право использования Программы предоставляется Лицензиату с даты
              вступления Договора в силу до «13» августа 2029 года включительно.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">4.8.</span>
          <div className="list-item__content">
            <p>
              Право использования Программы досрочно прекращается в дату
              прекращения Договора, определяемую в соответствии с пунктом 7.1.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">5. ИЗМЕНЕНИЕ ДОГОВОРА</h2>
        <div className="list-item">
          <span className="list-item__counter">5.1.</span>
          <div className="list-item__content">
            <p>
              Лицензиар вправе в одностороннем порядке вносить изменения в
              Договор посредством размещения на Сайте новой редакции Договора, о
              чем Лицензиар уведомляет Лицензиата любым из способов,
              предусмотренных разделом 11. Новая редакция Договора вступает в
              силу на 10 (десятый) рабочий день с даты направления Лицензиаром
              указанного уведомления (а в случае направления нескольких
              уведомлений – первого из них).
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">6. ИЗМЕНЕНИЕ ПРОГРАММЫ</h2>
        <div className="list-item">
          <span className="list-item__counter">6.1.</span>
          <div className="list-item__content">
            <p>
              Лицензиар вправе без согласия Лицензиата вносить любые изменения в
              Программу, в том числе осуществлять ее модификацию. При этом
              Лицензиар не обязан уведомлять Лицензиата о таких изменениях.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">6.2.</span>
          <div className="list-item__content">
            <p>
              В случае внесения изменений в Программу, такая измененная версия
              Программы в целях применения Договора рассматривается в качестве
              Программы и может использоваться Лицензиатом на тех же условиях,
              что предусмотрены Договором в отношении использования Программы.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">7. ПРЕКРАЩЕНИЕ ДОГОВОРА</h2>
        <div className="list-item">
          <span className="list-item__counter">7.1.</span>
          <div className="list-item__content">
            <p>
              Договор прекращается (без необходимости направления Сторонами друг
              другу соответствующего уведомления или подписания Сторонами
              какого-либо дополнительного документа) в наиболее раннюю из
              следующих дат:
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">7.1.1.</span>
          <div className="list-item__content">
            <p>
              дату прекращения статуса «пользователь Информационной системы»
              (согласно терминологии Правил) у Лицензиата по любой причине.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">7.1.2.</span>
          <div className="list-item__content">
            <p>
              дату исключения Лицензиара из реестра операторов информационных
              систем, в которых осуществляется выпуск цифровых финансовых
              активов.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">8. ОТВЕТСТВЕННОСТЬ</h2>
        <div className="list-item">
          <span className="list-item__counter">8.1.</span>
          <div className="list-item__content">
            <p>
              За неисполнение или ненадлежащее исполнение обязательств по
              настоящему Договору Стороны несут ответственность, предусмотренную
              действующим законодательством Российской Федерации.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">8.2.</span>
          <div className="list-item__content">
            <p>
              Стороны несут ответственность по Договору за реальный ущерб,
              причиненный по вине Стороны.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">8.3.</span>
          <div className="list-item__content">
            <p>
              Лицензиар не несет ответственность за невозможность использования
              Программы по причинам, не зависящим от Лицензиара.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">8.4.</span>
          <div className="list-item__content">
            <p>
              Оператор информационной системы не несет перед Лицензиатом
              ответственности за действия Лицензиара, связанные с
              предоставлением доступа к Программе.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">8.5.</span>
          <div className="list-item__content">
            <p>
              Оператор информационной системы не отвечает за возможные сбои в
              работе Программы, а также не обеспечивает защиту информации,
              передаваемой или получаемой Лицензиатом с использованием
              Программы.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">9. КОНФИДЕНЦИАЛЬНОСТЬ</h2>
        <div className="list-item">
          <span className="list-item__counter">9.1.</span>
          <div className="list-item__content">
            <p>
              Лицензиар обязуется обеспечить конфиденциальность сведений о
              действиях, совершенных Лицензиатом в Системе с использованием
              Программы, а также данных, полученных Лицензиатом из Системы с
              использованием Программы.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">9.2.</span>
          <div className="list-item__content">
            <p>
              В течение 3 (трех) лет с даты получения Конфиденциальной
              информации (но в любом случае не ранее заключения Договора)
              Лицензиар обязан не передавать Конфиденциальную информацию третьим
              лицам и не предоставлять третьим лицам доступ к Конфиденциальной
              информации без предварительного письменного согласия Лицензиата за
              исключением следующих случаев, когда указанное выше согласие не
              требуется, и при которых Конфиденциальная информация может
              предоставляться в необходимом и достаточном объеме третьим лицам:
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">9.2.1.</span>
          <div className="list-item__content">
            <p>
              когда предоставление Конфиденциальной информации является
              обязательным для Лицензиара в соответствии с применимым
              законодательством (в том числе на основании соответствующего акта
              уполномоченного органа);
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">9.2.2.</span>
          <div className="list-item__content">
            <p>
              когда раскрытие Конфиденциальной информации требуется для защиты
              Лицензиара при его участии в судебном, административном,
              арбитражном, третейском разбирательстве при условии, что
              Лицензиаром предприняты максимально возможные меры для ограничения
              доступа к Конфиденциальной информации третьим лицам;
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">9.2.3.</span>
          <div className="list-item__content">
            <p>
              когда раскрытие Конфиденциальной информации другим пользователям
              Программы или предоставление им доступа к Конфиденциальной
              информации осуществляются в соответствии с Правилами или
              необходимо для исполнения обязательств, удостоверенных ЦФА,
              обязательств по сделкам с ЦФА.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">9.3.</span>
          <div className="list-item__content">
            <p>
              Порядок обращения с Конфиденциальной информацией определяется
              исключительно Договором.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">10. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h2>
        <div className="list-item">
          <span className="list-item__counter">10.1.</span>
          <div className="list-item__content">
            <p>
              Все споры и разногласия между Сторонами подлежат разрешению путем
              переговоров. Срок досудебного урегулирования споров – 20
              (двадцать) рабочих дней с даты получения мотивированной письменной
              претензии.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">10.2.</span>
          <div className="list-item__content">
            <p>
              Споры и разногласия между Сторонами, которые не были урегулированы
              путем переговоров, подлежат разрешению в судебном порядке по месту
              нахождения Лицензиара.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">11. ПОРЯДОК НАПРАВЛЕНИЯ УВЕДОМЛЕНИЙ</h2>
        <div className="list-item">
          <span className="list-item__counter">11.1.</span>
          <div className="list-item__content">
            <p>
              Уведомление имеет юридическую силу только в случае, если оно
              направлено только способами, установленными настоящим разделом.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">11.2.</span>
          <div className="list-item__content">
            <p>
              Уведомление может быть вручено лично или направлено заказным
              письмом с описью вложения, или по электронной почте (с
              уполномоченного адреса электронной почты одной Стороны на
              уполномоченный адрес электронной почты другой Стороны) и будет
              считаться полученным:
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">11.2.1.</span>
          <div className="list-item__content">
            <p>
              при вручении лично – в дату вручения, указанную на копии
              Уведомления получившей его Стороной, а в случае отсутствия
              Стороны, которой адресовано Уведомление, по адресу, указанному в
              пункте 11.3 (или по иному адресу, сообщенному Стороной другой
              Стороне в соответствии с пунктом 11.4), или в случае уклонения
              Стороны от принятия Уведомления – в дату, когда Уведомление было
              доставлено по адресу такой Стороны;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">11.2.2.</span>
          <div className="list-item__content">
            <p>
              при направлении заказным письмом – на 3 (третий) рабочий день
              после даты направления соответствующего почтового извещения при
              условии, что Сторона, направившая Уведомление, не позднее 2 (двух)
              рабочих дней с даты отправки заказного письма сообщила другой
              Стороне в порядке, предусмотренном пунктом 11.2.3, почтовый
              идентификатор такого заказного письма (или иной уникальный
              буквенно-цифровой или цифровой код, присвоенный соответствующему
              почтовому отправлению). В случае отсутствия Стороны, которой
              адресовано Уведомление, по адресу, указанному в пункте 11.3 (или
              по иному адресу, сообщенному Стороной другой Стороне в
              соответствии с пунктом 11.4), или в случае уклонения Стороны от
              принятия Уведомления, соответствующее Уведомление будет считаться
              полученным Стороной в дату, определяемую в соответствии с
              настоящим пунктом;
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">11.2.3.</span>
          <div className="list-item__content">
            <p>
              при направлении электронной почтой – на момент отправления
              Уведомления при условии, что отправителем не будет получено
              уведомление о невозможности доставки сообщения, сформированное
              почтовым сервером. При этом если Уведомление вручено лично или
              отправлено электронной почтой после 19:00 в рабочий день или в
              любое время в течение нерабочего дня, такое Уведомление считается
              доставленным в ближайший рабочий день.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">11.3.</span>
          <div className="list-item__content">
            <p>Адреса Сторон для целей направления Уведомлений:</p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">
            <b>Лицензиар:</b>
          </span>
          <div className="list-item__content">
            <p>
              Почтовый адрес: 199106 г. Санкт-Петербург, вн.тер.г. муниципальный
              округ № 7, линия 23-я В.О., д. 26, литера А, помещ. 1-Н, часть
              помещения 84; Уполномоченный адрес электронной почты:{" "}
              <a href="mailto:support@dltc.tech" className="link link_blue">
                support@dltc.tech
              </a>
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">
            <b>Лицензиат:</b>
          </span>
          <div className="list-item__content">
            <p>
              Почтовый адрес: адрес места нахождения или адрес места жительства
              (в зависимости от того, что применимо) Лицензиата, указанный в
              запросе на регистрацию в Программе;
            </p>
            <p>
              Уполномоченный адрес электронной почты: адрес электронной почты,
              указанный Лицензиатом при создании профиля пользователя Программы.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">11.4.</span>
          <div className="list-item__content">
            <p>
              Стороны обязуются незамедлительно (в любом случае не позднее
              следующего рабочего дня) в письменном виде извещать друг друга об
              изменении своих почтовых адресов любым из способов,
              предусмотренных пунктами 11.2.1 – 11.2.3. До момента получения
              Стороной письменного извещения об изменении почтового адреса
              другой Стороны (в том числе если такое извещение направлено в
              пределах срока, предусмотренного настоящим пунктом) все
              Уведомления, направленные на такой почтовый адрес другой Стороны,
              считаются полученными другой Стороной.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">11.5.</span>
          <div className="list-item__content">
            <p>
              Стороны признают сведения, необходимые для доступа к
              уполномоченным адресам электронной почты, ключом простой
              электронной подписи и обязуются сохранять указанные сведения в
              тайне от третьих лиц, самостоятельно определяя порядок сохранения
              конфиденциальности этих сведений. Стороны обязуются
              незамедлительно (в любом случае не позднее следующего рабочего
              дня) в письменном виде извещать друг друга об изменении своих
              уполномоченных адресов электронной почты любым из способов,
              предусмотренных пунктами 11.2.1 – 11.2.3.
            </p>
          </div>
        </div>

        <div className="list-item">
          <span className="list-item__counter">11.6.</span>
          <div className="list-item__content">
            <p>
              До момента получения Стороной письменного извещения об изменении
              уполномоченного адреса электронной почты другой Стороны (в том
              числе если такое извещение направлено в пределах срока,
              предусмотренного пунктом 11.5) все Уведомления, направленные на
              такой уполномоченный адрес электронной почты, или Уведомления,
              направленные с такого уполномоченного адреса электронной почты,
              считаются, соответственно, полученными (с учетом положений пункта
              11.2.3) или направленными другой Стороной.
            </p>
          </div>
        </div>
      </div>

      <div className="text-block">
        <h2 className="h5 text-center">12. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
        <div className="list-item">
          <span className="list-item__counter">12.1.</span>
          <div className="list-item__content">
            <p>
              Во избежание сомнений Лицензиар вправе распоряжаться правами на
              Программу, в том числе предоставлять третьим лицам право
              использования Программы.
            </p>
          </div>
        </div>
        <div className="list-item">
          <span className="list-item__counter">12.2.</span>
          <div className="list-item__content">
            <p>
              В случае если одно из положений Договора будет признано
              недействительным, это не повлечет за собой недействительности
              остальных его положений.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LicensePage;
