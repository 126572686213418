import { useMemo } from "react";
import ProgressLabel from "../../../ui/Label/ProgressLabel";
import RejectInformer from "./RejectInformer";
const RejectOrderInfo = ({ orders }) => {
  const rejectCommentary = useMemo(() => {
    return orders && orders.length > 0 && orders[0]?.rejectCommentary;
  }, [orders]);
  if (!orders) return null;
  return (
    <>
      {rejectCommentary ? (
        <div
          style={{
            marginBottom: 15,
            display: "flex",
            gap: 5,
            alignItems: "center",
          }}
        >
          <ProgressLabel status={"CANCELLED"}>Отменено эмитентом</ProgressLabel>
          <RejectInformer>{rejectCommentary}</RejectInformer>
        </div>
      ) : null}
    </>
  );
};

export default RejectOrderInfo;
