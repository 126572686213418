import Select from "../../../components/ui/Select/Select";
import { useBuildings } from "../../../hooks/fetchers/buildings";
import { Loader } from "../../../components/ui/Loader/Loader";
const SortByBuildingSelect = ({ setChosen }) => {
  const { data: buildings, isLoading } = useBuildings();

  if (isLoading) {
    return <Loader isLarge isBlue />;
  }
  return (
    <div style={{ marginTop: 25 }}>
      <Select
        addClass={"building-filter"}
        withLabel={"Название ЖК"}
        options={[{ name: "Все", value: "all" }, ...buildings].map((it) => ({
          label: it.name,
          value: it.id,
        }))}
        onChangeValue={(value) => setChosen(value)}
        whiteType
      />
    </div>
  );
};

export default SortByBuildingSelect;
