import AdminTable from "../AdminTable/AdminTable";
import SearchInput from "../SearchInput/SearchInput";
// import { buildingsData } from "../../mocData";
import AddBtn from "../AddButton";
import Flexbox from "../../../components/ui/Flexbox/Flexbox";
import useModal from "../../../hooks/useModal";
import Modal from "react-modal";
import AddBuildingModal from "../AddModal/AddBuildingModal";
import { getAllBuildings } from "../../../api";
import { Loader } from "../../../components/ui/Loader/Loader";
import { useQuery } from "react-query";
import { formatId } from "../../../utils";
const Buildings = () => {
  const { modalIsOpen, openModal, closeModal } = useModal();

  const { data: buildings, isLoading } = useQuery({
    queryKey: ["buildings"],
    queryFn: getAllBuildings,
  });

  const getBuildingsData = () => {
    if (!buildings) return null;

    return {
      ЖК: buildings.map((it) => ({
        name: it.name,
        link: `../buildings/${it.id}`,
      })),
      "ID ЖК": buildings.map((it) => formatId(it.id)),
      Застройщик: buildings.map((it) => ({
        name: it.developer?.name,
        link: `../developers/${it.developer?.id}`,
      })),
      "ID Застройщика": buildings.map((it) => formatId(it.developer.id)),

      "Процент готовности": buildings.map((it) => {
        switch (it.status) {
          case "START_BUILDING":
            return `Старт ${it.startBuildingQuarter} квартал ${it.startBuildingYear} года`;
          case "PERCENT_BUILDING":
            return `${it.percentBuilding}% готовности`;
          case "COMMISSIONING":
            return "Введение в эксплуатацию";
          case "FOR_SALE":
            return "Выставление на продажу";
          case "PAYMENTS":
            return "Произведение выплат";
          default:
            return "Не зарегестрированный статус";
        }
      }),
    };
  };

  if (isLoading) {
    return <Loader isLarge isBlue />;
  }

  return (
    <>
      {buildings && buildings.length > 0 && <SearchInput />}
      <Flexbox flexDirection="column" fitContent>
        {buildings && buildings.length > 0 ? (
          <AdminTable data={getBuildingsData()} />
        ) : (
          <p style={{ marginTop: 15 }}>Нет ЖК</p>
        )}

        <AddBtn onClick={openModal}>Добавить ЖК</AddBtn>
      </Flexbox>

      <Modal
        isOpen={modalIsOpen}
        //Пока закомментировал, закрытие модалки при клике вне её области, по просьбе Андрея
        // onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <AddBuildingModal closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default Buildings;
