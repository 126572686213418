import classNames from "classnames";
import "./NotificationCard.scss";
import NotificationDate from "./NotificationDate";
import CollapseToggler from "../Collapse/CollapseToggler";
import Collapse from "../Collapse/Collapse";
import SettingsButtons from "./SettingsButtons";
import ReleaseNewTokens from "./ReleaseNewTokens";
import useGetNewRelease from "../../../hooks/notifications/objectType/release/useGetNewRelease";
import useGetStatusReadinessJk from "../../../hooks/notifications/objectType/building/useGetStatusReadinessJk";
import LivingComplexesCard from "./LivingComplexesCard";
import FinancialTransactions from "./FinancialTransactions";
import useGetFinancialTransactions from "../../../hooks/notifications/objectType/initOrder/useGetFinancialTransactions";
import useUser from "../../../hooks/useUser";
import useReleaseId from "../../../hooks/fetchers/releases/useReleaseId";

const NotificationCard = ({ isMiniCard = false, ...content }) => {
  const { user } = useUser();

  // const [collapseOpened, setCollapseOpened] = useState(null);
  const { financialTransactions } = useGetFinancialTransactions(content, user);
  const { newRelease } = useGetNewRelease(content);
  const { statusReadinessJk } = useGetStatusReadinessJk(content);

  const { data: releaseByUrlId } = useReleaseId(newRelease.objectId);
  const notificationProcessingErrorClass = classNames({
    ["_bg-red"]:
      content?.objectType === "INIT_ORDER" &&
      content?.eventType === "PROCESSING_ERROR" &&
      user?.roles[0] === "ROLE_ADMIN",
    ["_hide-card"]:
      (content?.objectType === "INIT_ORDER" &&
        content?.eventType === "PROCESSING_ERROR" &&
        user?.roles[0] === "ROLE_USER") ||
      (content?.objectType === "RELEASE" &&
        content?.eventType === "NEW_TOKENS" &&
        !releaseByUrlId),
  });

  const isMiniNotificationClass = classNames({
    ["notification-card__mini"]: isMiniCard,
  });

  return (
    <div
      className={`notification-card ${notificationProcessingErrorClass} ${isMiniNotificationClass}`}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {content.dateTime && <NotificationDate date={content.dateTime} />}
        {/*--Блок с кнопками "Cкрыть новости" пока решили не отображать--*/}
        {/*<CollapseToggler*/}
        {/*    addClass={"sign__cert-toggler"}*/}
        {/*    collapseOpened={collapseOpened}*/}
        {/*    setCollapseOpened={setCollapseOpened}*/}
        {/*>*/}
        {/*</CollapseToggler>*/}
      </div>
      {/*<Collapse addClass={"sign__cert-content"} isOpened={collapseOpened}>*/}
      {/*    <HideNewsButtons/>*/}
      {/*</Collapse>*/}
      <div className="notification-card__notification-types">
        {financialTransactions &&
          Object.values(financialTransactions).length > 0 && (
            <FinancialTransactions
              financialTransactions={financialTransactions}
              isMiniCard={isMiniCard}
              user={user}
            />
          )}
        {newRelease && Object.values(newRelease).length > 0 && (
          <ReleaseNewTokens
            newRelease={newRelease}
            releaseByUrlId={releaseByUrlId}
          />
        )}
        {statusReadinessJk && Object.keys(statusReadinessJk).length > 0 && (
          <LivingComplexesCard statusReadinessJk={statusReadinessJk} />
        )}
      </div>
    </div>
  );
};
export default NotificationCard;
