import { useMemo } from "react";
import useReleases from "../../../../hooks/fetchers/releases/useReleases";
import { Loader } from "../../../ui/Loader/Loader";
import CardHorizontalFavorites from "./CardHorizontalFavorites";
import "./index.scss";

const Favorites = () => {
  const { data, isLoading } = useReleases({
    only_favorite: true,
    status: ["WAIT_TOKENIZATION", "TOKENIZATION"],
  });

  const favoriteReleases = useMemo(() => {
    if (data) return data.content;
    return null;
  }, [data]);

  if (isLoading) return <Loader isLarge />;
  if (!favoriteReleases) return null;

  return favoriteReleases.length > 0 ? (
    <section className="my-tokens">
      {favoriteReleases.map((release) => (
        <CardHorizontalFavorites
          release={release}
          key={`farovite-release-${release.id}`}
        />
      ))}
    </section>
  ) : (
    <p style={{ marginTop: 15 }}>У вас нет объектов в избранном</p>
  );
};

export default Favorites;
