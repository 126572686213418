import Container from "../components/layout/Container/Container";
import { config } from "../config";

const ContactsPage = () => {
  return (
    <section className="home">
      <h1 className="title">Контакты</h1>
      <div className="text-block">
        <p>
          Поддержка:{" "}
          <a
            className="link link_blue"
            href={`mailto:${config.contacts.supportEmail}`}
          >
            {config.contacts.supportEmail}
          </a>
        </p>
        <p>
          Для застройщиков:{" "}
          <a
            className="link link_blue"
            href={`mailto:${config.contacts.forDevelopersEmail}`}
          >
            {config.contacts.forDevelopersEmail}
          </a>
        </p>
        <p>
          Предложения по развитию платформы:{" "}
          <a
            className="link link_blue"
            href={`mailto:${config.contacts.forProposalsEmail}`}
          >
            {config.contacts.forProposalsEmail}
          </a>
        </p>
      </div>
    </section>
  );
};

export default ContactsPage;
