import { useState } from "react";
import CopyButton from "../../../ui/CopyButton/CopyButton";

export const CopyOrderIdButton = ({ value }) => {
  const [isFullValue, setIsFullValue] = useState(false);
  return (
    <div className="copy-order-id-button">
      {isFullValue ? (
        <b>Скопировано</b>
      ) : (
        <p>
          id: <b>{value}</b>
        </p>
      )}
      <CopyButton
        onClick={() => {
          setIsFullValue(true);
          setTimeout(() => {
            setIsFullValue(false);
          }, 1200);
        }}
        value={value}
      />
    </div>
  );
};
