import classNames from "classnames";
import Container from "../../Container/Container";
import "./index.scss";
import Logo from "../../../ui/Logo/Logo";
import UserMenu from "../../UserMenu/UserMenu";

import { useUserStore } from "../../../../store";
import BottomNav from "./BottomNav";
import Burger from "../TopBar/Burger";
import useMobile from "../../../../hooks/useMobile";

const BottomBar = ({ setMobileMenuShown }) => {
  const bottombarClass = classNames({
    bottombar: true,
  });
  const setIsLoggedIn = useUserStore((state) => state.login);
  if (localStorage.getItem("accessToken")) {
    setIsLoggedIn();
  }
  const isMobile = useMobile();

  return (
    <section className={bottombarClass}>
      <Container>
        <div className="bottombar__block">
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            {isMobile && <Burger setMobileMenuShown={setMobileMenuShown} />}
            <Logo />
          </div>

          <BottomNav />

          <UserMenu />
        </div>
      </Container>
    </section>
  );
};

export default BottomBar;
