import Container from "../../components/layout/Container/Container";
import CollapseToggler from "../../components/ui/Collapse/CollapseToggler";
import Collapse from "../../components/ui/Collapse/Collapse";
import { useState } from "react";
import { answersOnQuestions } from "./answersOnQuestions";
import Button from "../../components/ui/Button/Button";

const FaqPage = () => {
  const [collapseOpened, setCollapseOpened] = useState([]);

  return (
    <section className="home">
      <h1 className="title">Ответы на вопросы</h1>
      {answersOnQuestions.map((el, i) => {
        return (
          <div style={{ marginBottom: "15px" }} key={i}>
            <CollapseToggler
              addClass={"collapse-theme-faq__question"}
              collapseOpened={collapseOpened === i}
              setCollapseOpened={() =>
                setCollapseOpened(collapseOpened === i ? null : i)
              }
            >
              {el.question}
            </CollapseToggler>
            <Collapse
              addClass={"collapse-theme-faq__answer"}
              isOpened={collapseOpened === i}
            >
              <div className="text-block" style={{ padding: "15px 0 25px" }}>
                {el.answersList.map((item, index) => {
                  return <p key={index}>{item.answer}</p>;
                })}
              </div>
              {/* <Button
                style={{ marginTop: "24px" }}
                type="button"
                buttonType="secondary"
                size={"m"}
                addClass="form__edit-btn"
              >
                Подробнее
              </Button> */}
            </Collapse>
          </div>
        );
      })}
    </section>
  );
};

export default FaqPage;
