import Button from "../Button/Button";

const SettingsButtons = ({
    nameBtnOne = "",
    nameBtnTwo = "",
    sizeBtn = '',
    onClickBtnOne = () => {},
    onClickBtnTwo = () => {},
    ...arg
 }) => {

    return (
        <div className="notification-card__setting-btns" {...arg}>
            {nameBtnOne && <Button onClick={() => onClickBtnOne()} isFull style={{ backgroundColor: '#EBF4FF', color: '#1D1D1D' }} size={sizeBtn}>{nameBtnOne}</Button>}
            {nameBtnTwo && <Button onClick={() => onClickBtnTwo()} isFull style={{ backgroundColor: '#EBF4FF', color: '#1D1D1D' }} size={sizeBtn}>{nameBtnTwo}</Button>}
        </div>
    )
}
export default SettingsButtons;