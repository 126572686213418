import Button from "../Button/Button";
import CoinsIcon from "../../../assets/img/icons/coins.svg";

const NoApiScreen = ({ closeModal }) => {
  return (
    <div className="success-screen success-screen_inner-padding">
      <div className="success-screen__content">
        <div>
          <img src={CoinsIcon} alt="" />
        </div>

        <span className="h7 text-blue">Партнерское API недоступно</span>
        <p className="text-gray" style={{ marginTop: 8 }}>
          Покупка и продажа на платформе недоступны. Информация по начисленным
          ЦФА может быть устаревшей
        </p>
      </div>

      <div className="sign__btns">
        <Button buttonType="secondary" size="m" isFull onClick={closeModal}>
          Закрыть
        </Button>
      </div>
    </div>
  );
};

export default NoApiScreen;
