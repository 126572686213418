import SortByBuildingSelect from "./SortByBuildingSelect";
import AdminTable from "./AdminTable";
import Flexbox from "../../../components/ui/Flexbox/Flexbox";
import AddBtn from "../AddButton";
import useModal from "../../../hooks/useModal";
import Modal from "react-modal";
import { useState, useMemo } from "react";
const AdminTableWithSort = ({
  data,
  formatInfo,
  noDataLabel,
  renderModal,
  addLabel,
}) => {
  const { modalIsOpen, openModal, closeModal } = useModal();
  const [chosen, setChosen] = useState("Все");

  const filteredData = useMemo(() => {
    if (data) {
      if (chosen === "Все") return data;
      return data.filter(
        (it) =>
          it?.building?.id === chosen || it?.release?.building?.id === chosen
      );
    }
  }, [data, chosen]);

  if (!data) return null;

  return (
    <>
      <SortByBuildingSelect setChosen={setChosen} />
      <Flexbox flexDirection="column" fitContent>
        {filteredData && filteredData.length > 0 ? (
          <AdminTable data={formatInfo(filteredData)} />
        ) : (
          <p style={{ marginTop: 15 }}>{noDataLabel}</p>
        )}

        {addLabel && <AddBtn onClick={openModal}>{addLabel}</AddBtn>}
      </Flexbox>
      {renderModal && (
        <Modal
          isOpen={modalIsOpen}
          //Пока закомментировал, закрытие модалки при клике вне её области, по просьбе Андрея
          // onRequestClose={closeModal}
          className="modal-wrapper"
          overlayClassName="modal-overlay"
        >
          {renderModal(closeModal)}
        </Modal>
      )}
    </>
  );
};

export default AdminTableWithSort;
