import { useEffect, useState } from "react";

function useShowButton(financialTransactions) {
  const [button, setButton] = useState({
    nameBtn: "",
    linkBtn: "",
  });

  const parseDescription = financialTransactions?.description?.includes('{' || '}') ? JSON.parse(financialTransactions?.description) : financialTransactions?.description;
  const statusDescription = parseDescription?.status ? parseDescription?.status : financialTransactions?.description;

  useEffect(() => {
    let isMounted = true;

    if (
      financialTransactions &&
      Object.keys(financialTransactions).length > 0
    ) {
      if (
        financialTransactions.objectType === "INIT_ORDER" &&
        financialTransactions.eventType === "STATUS_CHANGED"
      ) {
        if (
            statusDescription === "EXPIRED"
        ) {
          isMounted &&
            setButton({
              nameBtn: "Поддержка",
              linkBtn: "/settings/support",
            });
        } else if (
            statusDescription === "WAITING_FOR_PAYMENT"
        ) {
          isMounted &&
            setButton({
              nameBtn: "Завершить покупку",
              linkBtn: "/user/orders",
            });
        } else if (
            statusDescription === "WAITING_FOR_CONFIRMATION"
        ) {
          isMounted &&
            setButton({
              nameBtn: "Мои ЦФА",
              linkBtn: "/user/cfa",
            });
        }
      } else {
        isMounted &&
          setButton({
            nameBtn: "",
            linkBtn: "",
          });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [financialTransactions]);

  return { button };
}
export default useShowButton;
