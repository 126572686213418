const Advantage = ({ img, text }) => {
  return (
    <article className="welcome__advantage">
      <img className="welcome__advantage-img" src={img} alt="" />
      <p className="welcome__advantage-text">{text}</p>
    </article>
  );
};

export default Advantage;
