import { addEmitter, getUsers } from "../../../../api";
import { useState } from "react";
import Button from "../../../../components/ui/Button/Button";

import { useQuery, useMutation, useQueryClient } from "react-query";

import { useForm, FormProvider } from "react-hook-form";
import useUserId from "../../../../hooks/useUserId";
import SelectSearch from "../../../../components/ui/SelectSearch/SelectSearch";
import Flexbox from "../../../../components/ui/Flexbox/Flexbox";
import Input from "../../../../components/ui/Input/Input";
const AddEmitterForm = ({ closeModal }) => {
  const queryClient = useQueryClient();

  const methods = useForm();
  const userId = useUserId();

  const [showByName, setShowByName] = useState(false);

  const { data: users, isSuccess } = useQuery({
    queryFn: () => getUsers(),
    queryKey: "users",
  });

  const addEmitterMutation = useMutation({
    mutationFn: (body) => {
      return addEmitter(body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["emitters"],
      });
      closeModal();
    },
  });

  const onSubmit = ({ user, name }) => {
    addEmitterMutation.mutate({ ...user.value, name });
  };

  return (
    <FormProvider {...methods}>
      <form className="form feedback" onSubmit={methods.handleSubmit(onSubmit)}>
        {users && isSuccess && users.length > 0 && (
          <>
            <Flexbox style={{ marginBottom: 16 }}>
              <Button
                size="xs"
                onClick={() => setShowByName(true)}
                buttonType={showByName ? "primary" : "secondary"}
              >
                Поиск по имени
              </Button>
              <Button
                size="xs"
                onClick={() => setShowByName(false)}
                buttonType={showByName ? "secondary" : "primary"}
              >
                Поиск по номеру телефона
              </Button>
            </Flexbox>
            <div className="form__row">
              <div className="form__item">
                <SelectSearch
                  name="user"
                  withLabel="Выберите пользователя"
                  options={users.map((user) => ({
                    value: { idOis: user.idOis, userId: user.id },
                    label: showByName
                      ? `${user.lastName} ${user.firstName} ${user.middleName}`
                      : user.phone,
                  }))}
                />
              </div>
            </div>
            <div className="form__row">
              <div className="form__item">
                <Input
                  name="name"
                  addClass="form__input"
                  size="lg"
                  labelSize="small"
                  withLabel="Введите наименование эмитента"
                  placeholder="ООО Эмитент"
                  required
                />
              </div>
            </div>
          </>
        )}

        <Button
          isLoading={addEmitterMutation.isLoading}
          disabled={addEmitterMutation.isLoading}
          addClass="modal__btn"
          isFull
          type="submit"
        >
          Добавить эмитента
        </Button>
      </form>
    </FormProvider>
  );
};

export default AddEmitterForm;
