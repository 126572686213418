import Button from "../Button/Button";
import Icon from "./Icon";
import "./ReleaseLink.scss";
import CloseButton from "../CloseButton/CloseButton";
import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import { formatDateFrom, formatId } from "../../../utils";

const ReleaseLink = ({
  release,
  noIcon = false,
  ellipsis = false,
  children,
}) => {
  const { modalIsOpen, openModal, closeModal } = useModal();
  if (!release) return null;

  const { docReleaseLink, riskNotificationLink, detailedConditionsLink } =
    release;

  return (
    <>
      <Button addClass="release-link" isClean onClick={openModal}>
        <span
          className="link link_blue"
          style={
            ellipsis
              ? {
                  display: "inline-block",
                  width: "90px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }
              : {}
          }
        >
          {children || release.name || formatId(release.id)}
        </span>
        {!noIcon && <Icon />}
      </Button>
      {release && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal-wrapper"
          overlayClassName="modal-overlay"
        >
          <div className="modal">
            <CloseButton addClass="modal__close-btn" onClick={closeModal} />
            <p className="h8" style={{ marginBottom: 10 }}>
              {release.name || formatId(release.id)}
            </p>

            <dl className="dl">
              {release?.plannedStartDate && (
                <div>
                  <dt>Дата начала сбора заявок</dt>
                  <dd>
                    <span>
                      {formatDateFrom(release?.plannedStartDate, true)}
                    </span>
                  </dd>
                </div>
              )}
              {release?.plannedEndDate && (
                <div>
                  <dt>Дата окончания сбора заявок</dt>
                  <dd>
                    <span>{formatDateFrom(release?.plannedEndDate, true)}</span>
                  </dd>
                </div>
              )}

              {release?.burningDate && (
                <div>
                  <dt>Дата погашения ЦФА</dt>

                  <dd>
                    <span>{formatDateFrom(release?.burningDate, true)}</span>
                  </dd>
                </div>
              )}

              {docReleaseLink && (
                <div>
                  <dt>Решение о выпуске</dt>
                  <dd>
                    <a className="link link_blue" href={docReleaseLink}>
                      Ознакомиться
                    </a>
                  </dd>
                </div>
              )}

              {riskNotificationLink && (
                <div>
                  <dt>Уведомление о рисках</dt>
                  <dd>
                    <a className="link link_blue" href={riskNotificationLink}>
                      Ознакомиться
                    </a>
                  </dd>
                </div>
              )}
              {detailedConditionsLink && (
                <div>
                  <dt>Инвестиционный договор между Эмитентом и Застройщиком</dt>
                  <dd>
                    <a className="link link_blue" href={detailedConditionsLink}>
                      Ознакомиться
                    </a>
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReleaseLink;
