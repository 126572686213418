import CollapseToggler from "../Collapse/CollapseToggler";
import Collapse from "../Collapse/Collapse";
import {useEffect, useState} from "react";
import {getCertificate} from "crypto-pro";
import {config} from "../../../config";

const CertInfo = (props) => {

    const {
        currentCertificate
    } = props;

    const [collapseOpened, setCollapseOpened] = useState(false);
    const [certificateDetails, setCertificateDetails] = useState(null);
    const [detailsError, setDetailsError] = useState(true);

    const loadCertificateDetails = async (thumbprint) => {
        try {
            const certificate = await getCertificate(thumbprint);

            setCertificateDetails({
                name: certificate.name,
                issuerName: certificate.issuerName,
                subjectName: certificate.subjectName,
                thumbprint: certificate.thumbprint,
                validFrom: certificate.validFrom,
                validTo: certificate.validTo,
                isValid: await certificate.isValid(),
                version: await certificate.getCadesProp('Version'),
                base64: await certificate.exportBase64(),
                algorithm: await certificate.getAlgorithm(),
                extendedKeyUsage: await certificate.getExtendedKeyUsage(),
                ownerInfo: await certificate.getOwnerInfo(),
                issuerInfo: await certificate.getIssuerInfo(),
                decodedExtendedKeyUsage: await certificate.getDecodedExtendedKeyUsage(),
                '1.3.6.1.4.1.311.80.1': await certificate.hasExtendedKeyUsage('1.3.6.1.4.1.311.80.1'),
                '[\'1.3.6.1.5.5.7.3.2\', \'1.3.6.1.4.1.311.10.3.12\']': await certificate.hasExtendedKeyUsage([
                    '1.3.6.1.5.5.7.3.2',
                    '1.3.6.1.4.1.311.10.3.12'
                ]),
                '1.3.6.1.4.1.311.80.2': await certificate.hasExtendedKeyUsage('1.3.6.1.4.1.311.80.2'),
                '\'1.3.6.1.5.5.7.3.3\', \'1.3.6.1.4.1.311.10.3.12\'': await certificate.hasExtendedKeyUsage([
                    '1.3.6.1.5.5.7.3.3',
                    '1.3.6.1.4.1.311.10.3.12'
                ]),
            });
        } catch (error) {
            setDetailsError(error);
        }
    }

    useEffect(() => {
        let isMounted = true;
        if(currentCertificate && config.certificatesCloudStorage) {
            loadCertificateDetails(currentCertificate);
        } else if(currentCertificate && !config.certificatesCloudStorage) {
            setCertificateDetails({
                subjectName: currentCertificate?.SubjectCommonName,
                validTo: currentCertificate?.NotBefore,
                validFrom: currentCertificate?.NotAfter,
                thumbprint: currentCertificate?.Thumbprint,
            })
        }
        return () => {
            isMounted = false;
        }
    },[currentCertificate])

  return (
    <div className="sign__cert">
      <CollapseToggler
        addClass={"sign__cert-toggler"}
        collapseOpened={collapseOpened}
        setCollapseOpened={setCollapseOpened}
      >
        Информация о вашем сертификате
      </CollapseToggler>
      <Collapse addClass={"sign__cert-content"} isOpened={collapseOpened}>
          {certificateDetails ? (
              <>
                  {certificateDetails?.subjectName}, Выдан: {certificateDetails?.validTo} , Заканчивается: {certificateDetails?.validFrom} , Отпечаток: {certificateDetails?.thumbprint}
              </>
          ) : (
            <>Сертификат не найден, загрузите нужный сертификат!</>
          )}
      </Collapse>
    </div>
  );
};

export default CertInfo;
