import LogoCtrrSvg from "../assets/img/icons/logo-ctrr.svg";
import LogoArrowSvg from "../assets/img/icons/logo-arrow.svg";
import Logo from "../components/ui/Logo/Logo";

const AboutPage = () => {
  return (
    <section className="home">
      <h1 className="title">­О проекте</h1>
      <div className="text-block">
        <p>
          <b>
            <span className="highlighted">Цифровой квадрат</span>
          </b>{" "}
          – маркетплейс токенизированных квадратных метров от застройщиков.
        </p>

        <p>
          Приобретение цифровых квадратных метров, осуществляется путем
          приобретения цифровых финансовых активов (ЦФА). ЦФА – это новый
          финансовый инструмент на базе современных технологий, признаваемый
          российским законодательством и регулируемый Центральным банком.
        </p>

        <p>
          Выпуск ЦФА осуществляется через оператора информационной системы
          «Блокчейн Хаб», который внесен Банком России в реестр ОИС.
        </p>

        <div className="joint-project">
          <Logo />
          <p className="text-title">
            Совместный
            <br />
            проект:
          </p>
          <img src={LogoArrowSvg} alt="" />
          <img src={LogoCtrrSvg} alt="" />
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
