import { useEffect, useState } from "react";

function useGetFinancialTransactions(content, user) {
  const [financialTransactions, setFinancialTransactions] = useState({});

  const templateObjectType = [
    "INIT_ORDER",
    "BUY_ORDER",
    "SELL_ORDER",
    "BURNING",
    "TRANSFER_IN_ORDER",
    "TRANSFER_OUT_ORDER",
    "EXCHANGE_IN_ORDER",
    "EXCHANGE_OUT_ORDER"
  ];

  useEffect(() => {
    let isMounted = true;

    if (
      templateObjectType.includes(content?.objectType) &&
      content?.eventType === "STATUS_CHANGED"
    ) {
      isMounted && setFinancialTransactions(content);
    } else if (
      content?.objectType === "INIT_ORDER" &&
      content?.eventType === "PROCESSING_ERROR" &&
      user?.roles[0] === "ROLE_ADMIN"
    ) {
      isMounted && setFinancialTransactions(content);
    } else {
      isMounted && setFinancialTransactions({});
    }

    return () => {
      isMounted = false;
    };
  }, [content.objectType]);

  return { financialTransactions };
}
export default useGetFinancialTransactions;
