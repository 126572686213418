import Input from "../../ui/Input/Input";
import Select from "../../ui/Select/Select";
import SelectSearch from "../../ui/SelectSearch/SelectSearch";
import { useQuery } from "react-query";
import { getAllEmitters, getPoolByBuildingId } from "../../../api";
import { formatId } from "../../../utils";
import DateInput from "../../ui/DateInput/DateInput";
import { Controller } from "react-hook-form";
import { Loader } from "../../ui/Loader/Loader";
import Button from "../../ui/Button/Button";
import useOisRelease from "../../../hooks/fetchers/releases/useOisRelease";

export const tokenizationReleaseOptions = [
  { label: "Не токенизирован", value: "NOT_TOKENIZED" },
  { label: "Ожидает токенизации", value: "WAIT_TOKENIZATION" },
  { label: "Идет токенизация", value: "TOKENIZATION" },
  { label: "Токенизирован", value: "TOKENIZED" },
  { label: "Не состоялся", value: "CANCELLED" },
];

export const releaseAvailableForOptions = [
  { label: "Физ.лиц", value: "FL" },
  { label: "Юр.лиц", value: "UL" },
  { label: "Всех", value: "ALL" },
];

const ReleaseDescription = ({ methods, chosenBuilding = null, disabled }) => {
  // const poolId = methods.watch("poolId")?.value;

  const { data: emitters, isLoading: isLoadingEmitters } = useQuery(
    "emitters",
    getAllEmitters
  );

  const { data: pools, isLoading: isPoolsLoading } = useQuery({
    queryFn: () => getPoolByBuildingId(chosenBuilding),
    queryKey: ["pools", chosenBuilding],
    enabled: !!chosenBuilding,
  });

  const isLoading = isLoadingEmitters && isPoolsLoading;

  const poolId = methods.watch("poolId");
  const idOis = methods.watch("idOis");
  const status = methods.watch("status");

  const { refetch: getOisRelease } = useOisRelease(idOis, {
    onSuccess: (data) => {
      methods.setValue("price", data.PlacementPrice * 100);
      methods.setValue("plannedStartDate", new Date(data.StartSaleDate));
      methods.setValue("plannedEndDate", new Date(data.IssueDate));
      methods.setValue("burningDate", new Date(data.BurnedDate));
    },
  });

  if (isLoading) return <Loader isBlue isLarge />;
  if (!pools || !emitters) return null;

  return (
    <>
      <div className="form__row">
        <div className="form__item">
          <SelectSearch
            withLabel="Укажите эмитента"
            name="emitterId"
            disabled={!emitters || disabled}
            options={emitters?.map((emitter) => ({
              value: emitter.id,
              label: emitter.name || formatId(emitter.id),
            }))}
            required
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Input
            withLabel="Укажите ID OIS"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="idOis"
            disabled={disabled}
            required={
              status !== "WAIT_TOKENIZATION" && status !== "NOT_TOKENIZED"
            }
          />
        </div>
      </div>
      {idOis && (
        <div style={{ margin: "5px 0 15px" }}>
          <Button size="s" onClick={getOisRelease} disabled={disabled}>
            Подтянуть данные с OIS
          </Button>
        </div>
      )}

      <div className="form__row">
        <div className="form__item">
          <Controller
            name="poolId"
            control={methods.control}
            rules={{ required: true }}
            render={({ field: { ref, onChange, ...field } }) => (
              <Select
                withLabel="Выберите пул"
                onChangeValue={onChange}
                options={pools?.map((pool) => ({
                  label: pool.name || formatId(pool.id),
                  value: pool.id,
                }))}
                defaultOption={methods.getValues("poolId")}
                whiteType
                disabled={!chosenBuilding || disabled}
                required
              />
            )}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <SelectSearch
            withLabel="Выберите квартиры"
            isMulti
            name={"flatsList"}
            disabled={!poolId || disabled}
            options={pools
              ?.find((pool) => pool.id === poolId)
              ?.flatsList?.map((flat) => ({
                value: flat.id,
                label: `кв. ${formatId(flat.number)}`,
              }))}
            required
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Controller
            name="status"
            control={methods.control}
            rules={{ required: true }}
            render={({ field: { ref, onChange, ...field } }) => (
              <Select
                {...field}
                options={tokenizationReleaseOptions}
                withLabel="Статус выпуска"
                whiteType
                onChangeValue={onChange}
                defaultOption={methods.getValues("status")}
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <Controller
            name="availableFor"
            rules={{ required: true }}
            control={methods.control}
            render={({ field: { ref, onChange, ...field } }) => (
              <Select
                {...field}
                options={releaseAvailableForOptions}
                withLabel="Выпуск доступен для"
                whiteType
                onChangeValue={onChange}
                defaultOption={
                  methods.getValues("availableFor") || "Не выбрано"
                }
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Input
            withLabel="Укажите цену за 100 ЦФА"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="price"
            disabled={disabled}
            required
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <DateInput
            addClass="form__input"
            name="plannedStartDate"
            withLabel="Дата начала выпуска"
            disabled={disabled}
            required
          />
        </div>

        <div className="form__item">
          <DateInput
            addClass="form__input"
            name="plannedEndDate"
            withLabel="Дата завершения выпуска"
            disabled={disabled}
            required
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <DateInput
            addClass="form__input"
            name="burningDate"
            withLabel="Дата погашения выпуска"
            disabled={disabled}
            required
          />
        </div>
      </div>
    </>
  );
};

export default ReleaseDescription;
