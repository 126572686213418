export const aboutLinks = [
  {
    id: 0,
    title: "О проекте",
    link: "about",
  },

  {
    id: 1,
    title: "Для застройщиков",
    link: "for-developers",
  },
  {
    id: 2,
    title: "Контакты",
    link: "contacts",
  },

  // {
  //   id: 2,
  //   title: "Публичная оферта",
  // },
  // {
  //   id: 3,
  //   title: "Правовая информация",
  // },
];
