import { useState } from "react";
import Button from "../../../../components/ui/Button/Button";
import "./EditForm.scss";

import { useForm, FormProvider } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { editRelease } from "../../../../api";
import { formatDate, formatId } from "../../../../utils";

import ErrorMessage from "../../../../components/ui/ErrorMessage/ErrorMessage";
import ReleaseDescription from "../../../../components/forms/PolymorhicForm/ReleaseDescription";
import DropzoneFilesDoc from "../../../../components/ui/Dropzone/DropzoneFilesDoc";
import { addFiles } from "../../../../api";

const ReleaseEditForm = ({ currentRelease, id, userId, isAdmin = false }) => {
  const [editMode, setEditMode] = useState(false);

  const queryClient = useQueryClient();

  const methods = useForm({
    defaultValues: {
      emitterId: {
        value: currentRelease?.emitter.id,
        label: formatId(currentRelease?.emitter.id),
      },
      poolId: {
        label: currentRelease.pool.name || formatId(currentRelease?.pool.id),
        value: currentRelease.pool.id,
      },
      flatsList: currentRelease?.flatsList.map((flat) => ({
        value: flat.id,
        label: `кв. ${formatId(flat?.number)}`,
      })),
      status: currentRelease?.status,
      price: currentRelease?.price,
      idOis: currentRelease?.idOis,
      availableFor: currentRelease?.availableFor,
      burningDate: currentRelease?.burningDate,
      plannedStartDate: currentRelease.plannedStartDate,
      plannedEndDate: currentRelease.plannedEndDate,
      docReleaseFileId: currentRelease?.docReleaseFileId,
      riskNotificationFileId: currentRelease?.riskNotificationFileId,
      detailedConditionsFileId: currentRelease?.detailedConditionsFileId,
    },
  });

  const editReleaseMutation = useMutation({
    mutationFn: (body) => {
      return editRelease(body, id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["releases", id],
      });
      queryClient.invalidateQueries({
        queryKey: ["pools", id],
      });
      // afterSubmit();
    },
  });

  const onSubmit = (data) => {
    editReleaseMutation.mutate({
      ...data,
      emitterId: data.emitterId.value,
      poolId: data.poolId.value,
      flatsList: data.flatsList.map((flat) => flat.value),
      plannedStartDate: formatDate(data.plannedStartDate),
      plannedEndDate: formatDate(data.plannedEndDate),
    });

    setEditMode(false);
  };

  if (!currentRelease || !currentRelease.building) return null;

  return (
    <FormProvider {...methods}>
      <form
        className="form edit-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <p className="h8 form__title">Описание</p>
        <ReleaseDescription
          chosenBuilding={currentRelease.building?.id}
          methods={methods}
          disabled={!editMode}
        />
        <div className="form__row">
          <DropzoneFilesDoc
            accept={{
              "application/octet-stream": [".pdf", ".doc", ".docx"],
            }}
            name={"docReleaseFileId"}
            label={"Документ о Решении о выпуске"}
            editmode={editMode ? "true" : "false"}
            onSubmit={methods.handleSubmit(onSubmit)}
            currentreleaselink={currentRelease?.docReleaseLink}
            currentreleaseid={currentRelease?.docReleaseFileId}
            disabled={!editMode}
            uploadfilesrequest={addFiles}
          />
        </div>
        <div className="form__row">
          <DropzoneFilesDoc
            accept={{
              "application/octet-stream": [".pdf", ".doc", ".docx"],
            }}
            name={"riskNotificationFileId"}
            label={"Документ о Уведомлениях о рисках"}
            editmode={editMode ? "true" : "false"}
            onSubmit={methods.handleSubmit(onSubmit)}
            currentreleaselink={currentRelease?.riskNotificationLink}
            currentreleaseid={currentRelease?.riskNotificationFileId}
            disabled={!editMode}
            uploadfilesrequest={addFiles}
          />
        </div>
        <div className="form__row">
          <DropzoneFilesDoc
            accept={{
              "application/octet-stream": [".pdf", ".doc", ".docx"],
            }}
            name={"detailedConditionsFileId"}
            label={"Инвестиционный договор между Застройщиком и Эмитентом"}
            editmode={editMode ? "true" : "false"}
            onSubmit={methods.handleSubmit(onSubmit)}
            currentreleaselink={currentRelease?.detailedConditionsLink}
            currentreleaseid={currentRelease?.detailedConditionsFileId}
            disabled={!editMode}
            uploadfilesrequest={addFiles}
          />
        </div>
        <div className="form__row">
          <div className="form__edit">
            <div className="form__admin-btns">
              {/* <Button
                type="button"
                buttonType="secondary"
                size={"m"}
                addClass="form__edit-btn"
                onClick={() => {}}
              >
                Удалить релиз
              </Button> */}
            </div>
            {!editMode ? (
              <Button
                type="button"
                buttonType="secondary"
                size={"m"}
                addClass="form__edit-btn"
                onClick={(evt) => {
                  evt.preventDefault();
                  setEditMode(true);
                }}
              >
                Редактировать
              </Button>
            ) : (
              <Button
                type="submit"
                isLoading={editReleaseMutation.isLoading}
                buttonType="primary"
                size={"m"}
                addClass="form__edit-btn"
              >
                Сохранить
              </Button>
            )}
          </div>
        </div>

        <ErrorMessage forRequest={editReleaseMutation} isHidden={editMode} />
      </form>
    </FormProvider>
  );
};

export default ReleaseEditForm;
