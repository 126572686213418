import { useQuery } from "react-query";
import {getBankRequisiteUser} from "../../../api";
import useIsLoggedIn from "../../useIsLoggedIn";

const useUserGetBankRequisite = (id) => {
    const isLoggedIn = useIsLoggedIn();

    return useQuery({
        queryKey: ["bankRequisite", id],
        queryFn: () => getBankRequisiteUser(id),
        enabled: isLoggedIn && !!id,
    });
};

export default useUserGetBankRequisite;
